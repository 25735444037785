import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import http from "../http";
import { PATH, TAG, HTTP, URL } from "../constant";
import { LANG } from "../lang/NavigationBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import { ErrorHandler } from "./ErrorHandler";

function Footer({ footerData, homePageData, changeHeaderFilterType }) {
  let [count, setCount] = useState(0);
  const arrayMedia = footerData.social_media;
  const keys = Object.keys(arrayMedia);
  const mailTo = homePageData.footer.email;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [sendBtn, setSendBtn] = useState("Send Message");
  const [isPlayer, setIsPlayer] = useState(false);

  let { location, push } = useHistory();

  const returnHome = () => {
    changeHeaderFilterType(LANG.dropDownList.all);
    push(PATH.home);
  };

  useEffect(() => {
    setIsPlayer(PATH.watch.substr(1) === location.pathname.split("/")[1]);
  }, [location.pathname]);

  const socialMediaList = keys.map((keyMedia) => {
    return (
      <li key={arrayMedia[keyMedia].name} className='ct-footer-links-li col-xl-6'>
        <a href={arrayMedia[keyMedia].url} target='_blank'>
          <img className='social-img' src={arrayMedia[keyMedia].icon_url} alt='social-img'></img>
          <span>{arrayMedia[keyMedia].name}</span>
        </a>
      </li>
    );
  });
  const emailHandler = (e) => {
    e.preventDefault();
    let messageData = {
      name: name,
      email: email,
      message: message,
    };

    setCount((prevCount) => prevCount + 1);
    setSendBtn("Sent");
    http(HTTP.METHOD.POST, URL.contactUs, messageData)
      .then((response) => {
        setCount(0);
        setName("");
        setEmail("");
        setMessage("");
        document.getElementById("ct-email-contact-form").reset();
        setTimeout(() => {
          setSendBtn("Send Mesage");
        }, 3000);
      })
      .catch((err) => {
        setCount(0);
        setSendBtn("Send Mesage");
        ErrorHandler.handle(err);
      });
  };

  return (
    <>
      <div className='container-fluid p-0 ct-footer'>
        {!isPlayer && (
          <div className='ct-ea-premium'>
            <div className='ct-ea-premium-content'>
              <p>Our premium courses are on </p>
              <p>
                <span>
                  <img
                    className='ea-logo'
                    src='https://seeklogo.com/images/U/udemy-logo-C3D3F5AC0C-seeklogo.com.png'
                    alt='udemy-logo'
                  ></img>
                </span>
                Udemy
              </p>
            </div>
            <div className='ct-ea-premium-button'>
              <button
                className='btn btn-light'
                onClick={() => {
                  window.open("https://www.udemy.com/user/karthik-kk/", "_blank");
                }}
              >
                Browse Premium Courses
              </button>
            </div>
          </div>
        )}

        <div className='ct-footer-content'>
          <div className='container ct-container'>
            <img
              className='ct-footer-main-logo'
              src='/images/logo-white.svg'
              alt='footer-logo'
              onClick={() => {
                returnHome();
              }}
            />
            <div className='row ct-footer-links-email-container'>
              <div className='col-xl-7 col-xl-7 ct-footer-links-main-container'>
                <div className='row'>
                  <div className='col-xl-6 col-md-12 ct-footer-content-list'>
                    <ul className='row'>
                      <li
                        className='col-xl-6'
                        onClick={() => {
                          changeHeaderFilterType(LANG.dropDownList.paths);
                          push({
                            pathname: PATH.search,
                            state: {
                              rootFilter: "paths",
                              filter: "Paths",
                            },
                          });
                          //window.location.href = '/#' + TAG.path;
                        }}
                      >
                        <span>Learning Paths</span>
                      </li>
                      <li
                        className='col-xl-6'
                        onClick={() => {
                          window.open("https://www.udemy.com/user/karthik-kk/", "_blank");
                        }}
                      >
                        <span>Premium Courses</span>
                      </li>
                      <li
                        className='col-xl-6'
                        onClick={() => {
                          changeHeaderFilterType(LANG.dropDownList.courses);
                          push({
                            pathname: PATH.search,
                            state: {
                              rootFilter: "courses",
                              filter: "Courses",
                            },
                          });
                          //window.location.href = '/#' + TAG.course;
                        }}
                      >
                        <span>Courses</span>
                      </li>
                      <li
                        className='col-xl-6'
                        onClick={() => {
                          window.open("https://blog.executeautomation.com/", "_blank");
                        }}
                      >
                        <span>Blog</span>
                      </li>
                    </ul>
                  </div>
                  <div className='col-xl-6 col-md-12 ct-footer-links'>
                    <ul className='ct-footer-links-ul row'>{socialMediaList}</ul>
                    <div className='ct-ea-email-mobile'>
                      {/* <img
												className='ct-ea-email'
												src='/images/communications.svg'
												alt='mail-icon'></img> */}
                      <FontAwesomeIcon icon={faEnvelope} className='ct-ea-email' />
                      <a className='ct-ea-email-link' href={`mailto:${mailTo}`}>
                        {homePageData.footer.email}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='ct-start-learning-button-container-tablet'>
                <a href={homePageData.hero.button.url} className='ct-start-learning-button-tablet'>
                  {homePageData.hero.button.text}
                </a>
              </div>
              <div className='col-xl-5 col-xl-5 ct-email-compose-section'>
                <form onSubmit={emailHandler} id='ct-email-contact-form'>
                  <label className='ct-email-title'> Send us a message </label>
                  <div className='form-group'>
                    <input
                      type='text'
                      value={name}
                      placeholder='Name'
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      className='form-control ct-name-email-inputs'
                      required
                    ></input>
                  </div>
                  <div className='form-group'>
                    <input
                      type='email'
                      value={email}
                      placeholder='Email'
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      className='form-control ct-name-email-inputs'
                      required
                    ></input>
                  </div>
                  <div className='form-group'>
                    <textarea
                      value={message}
                      rows='5'
                      placeholder='Message'
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      className='form-control ct-email-message'
                      required
                    ></textarea>
                  </div>
                  <button type='submit' className='btn btn-light ct-btn-email-send'>
                    {count == 0 ? (
                      sendBtn
                    ) : (
                      <div className='lds-ellipsis-message'>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    )}
                  </button>
                </form>
              </div>
            </div>

            <div className='ct-start-learning-section'>
              <div className='ct-start-learning-button-container'>
                <a href={homePageData.hero.button.url} className='ct-start-learning-button'>
                  {homePageData.hero.button.text}
                </a>
              </div>

              <div className='ct-footer-bottom'>
                <p className='ct-ea-company'>© ExecuteAutomation Ltd. All Rights Reserved. </p>
                <p className='ct-ea-dev'>
                  <a href='http://www.creativetribe.co.nz?src=executeautomation.com' target='_blank'>
                    Made with
                    <span>
                      <FontAwesomeIcon icon={faHeart} />
                    </span>
                    by Creative Tribe.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    footerData: state.rootReducer.homePageData.footer,
    homePageData: state.rootReducer.homePageData,
  };
};

export default connect(mapStateToProps)(Footer);
