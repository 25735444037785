import React, { useState, useEffect } from "react";
function Sequence({ color = "#062842", size = "3px", type = "dashed" }) {
    const [childNodes, setChildNodes] = useState([]);
    const [elementTop, setElementTop] = useState(0);
    useEffect(() => {
        function updateState() {
            let nodeList = document.getElementById("ct-path-course-container");
            let sequenceList = nodeList?.childNodes["0"]?.childNodes["0"]?.childNodes;
            if (sequenceList && sequenceList.length > 0) {
                setElementTop(window.innerWidth);
            }
        }
        updateState();
        window.addEventListener("resize", updateState);
    });
    useEffect(() => {
        let nodeList = document.getElementById("ct-path-course-container");
        let sequenceList = nodeList?.childNodes["0"]?.childNodes["0"]?.childNodes;
        if (sequenceList) {
            let __childNodes = [];
            for (let i = 0; i < sequenceList.length; i++) {
                __childNodes.push(sequenceList[i]);
            }
            setChildNodes(__childNodes);
        }
    }, [elementTop]);

    let initialOffSet = null;
    let top;
    let left;
    let width;
    let isChangeRow = false;
    let lastChildTop;
    let lastChildLeft;
    let lastChildRight;
    let lastChildwidth;
    return childNodes.map((node) => {
        let imageNode = node.children["0"].childNodes["0"].childNodes["0"];
        let imageHeight = imageNode.clientHeight;
        let imageWidth = imageNode.clientWidth;
        let nodeChildHeight = node.firstChild.firstChild.offsetHeight;
        if (initialOffSet !== node.offsetTop) {
            if (node.nextSibling === null) return;
            isChangeRow = false;
            if (initialOffSet === null) {
                top = node.offsetTop + (imageWidth * 200) / 354 - 35;
                width = node.offsetWidth - node.firstChild.firstChild.offsetWidth;
                left = node.offsetLeft + node.offsetWidth - width / 2;
            } else {
                let lineGap =
                    imageHeight === 0
                        ? node.offsetHeight + (imageWidth * 200) / 354
                        : node.offsetHeight;
                top = top + lineGap;
                left = node.offsetLeft + node.offsetWidth - width / 2;
            }
            if (initialOffSet !== node.nextSibling.offsetTop) {
                if (window.innerWidth < 768) {
                    isChangeRow = true;
                    lastChildTop = node.offsetTop + 82;
                    lastChildRight = node.clientWidth / 2 + 15;
                    width = 50;
                }
            }
            initialOffSet = node.offsetTop;
        } else {
            let nextSibling = node.nextSibling;
            if (nextSibling !== null) {
                left = node.offsetWidth + left;
                if (initialOffSet !== nextSibling.offsetTop) {
                    isChangeRow = true;
                    let fillGapSize =
                        imageHeight === 0
                            ? nodeChildHeight + 35
                            : nodeChildHeight - (imageWidth * 200) / 354 + 35;
                    lastChildTop = top + fillGapSize;
                    lastChildLeft = nextSibling.offsetLeft + node.offsetWidth / 2;
                    lastChildRight = left - node.firstChild?.offsetWidth / 2;
                    lastChildwidth = node.offsetLeft - nextSibling.offsetLeft;
                } else {
                    isChangeRow = false;
                }
            }
        }

        if (isChangeRow) {
            let elementArray = [];
            let length = window.innerWidth < 768 ? Math.floor(width / 14) : width / 14;
            for (let index = 0; index < length; index++) {
                elementArray.push(
                    <div
                        style={{
                            borderRadius: "50px",
                            height: "8px",
                            width: size,
                            border: `1px solid ${color}`,
                            backgroundColor: color,
                        }}
                    ></div>,
                );
            }
            let _elementArray = [];
            let _length = lastChildwidth / 14;
            for (let index = 0; index < _length; index++) {
                _elementArray.push(
                    <div
                        style={{
                            borderRadius: "50px",
                            width: "8px",
                            height: size,
                            border: `1px solid ${color}`,
                            backgroundColor: color,
                        }}
                    ></div>,
                );
            }
            return (
                <>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            height: width,
                            top: lastChildTop,
                            position: "absolute",
                            left: lastChildRight,
                            margin: 0,
                        }}
                        className="ct-last-sequence-spread"
                    >
                        {elementArray.map((node) => node)}
                    </div>
                    <div
                        style={{
                            width: lastChildwidth,
                            top: lastChildTop + width,
                            position: "absolute",
                            left: lastChildLeft,
                            margin: 0,
                        }}
                        className="ct-sequence-over-tab"
                    >
                        {_elementArray.map((node) => node)}
                    </div>
                    <div
                        style={{
                            flexDirection: "column",
                            height: width,
                            top: lastChildTop + width,
                            position: "absolute",
                            left: lastChildLeft,
                            margin: 0,
                        }}
                        className="ct-sequence-over-tab"
                    >
                        {elementArray.map((node) => node)}
                    </div>
                </>
            );
        } else {
            let elementArray = [];
            let length = width / 14;
            for (let index = 0; index < length; index++) {
                elementArray.push(
                    <div
                        style={{
                            borderRadius: "50px",
                            width: "8px",
                            height: size,
                            border: `1px solid ${color}`,
                            backgroundColor: color,
                        }}
                    ></div>,
                );
            }

            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width,
                        top,
                        position: "absolute",
                        left,
                        margin: 0,
                    }}
                >
                    {elementArray.map((node) => node)}
                </div>
            );
        }
    });
}

export default Sequence;
