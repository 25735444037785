import React, { useState, useRef, useEffect } from "react";
import { LANG } from "../lang/Card";
import { PATH } from "../constant";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import LoadingIndicator from "./LoadingIndicator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faCheck } from "@fortawesome/free-solid-svg-icons";

function CourseTile({ card, courseLevels, isShowSequenceNumber = false, sequenceNumber = "" }) {
    const [cardHeight, setCardHeight] = useState(0);
    const [countProgress, setCount] = useState(0);
    const cardWidthRef = useRef();
    let history = useHistory();

    const imagePath = card.course_image_path;
    const count = card.lessons_count;
    let amount = count + LANG.LESSONS;
    amount = count === 1 ? amount.substr(0, amount.length - 1) : amount;
    const progressStatus =
        card.completed_percentage !== undefined
            ? card.is_completed
                ? LANG.LABEL.COMPLETED
                : card.last_viewed_lesson_slug !== null
                ? LANG.LABEL.IN_PROGRESS
                : sequenceNumber
            : sequenceNumber;

    const cardType = card.level.title;
    const levelTypeColor = courseLevels.find((level) => level.slug === card.level.slug).color;
    card.formatDurationForCardLabel = (card) => {
        const totSeconds = card.duration_in_seconds;
        const formatValue = new Date(totSeconds * 1000).toISOString();
        return totSeconds < 3600
            ? formatValue.substr(14, 2) + "m"
            : formatValue.substr(11, 2) + "h " + formatValue.substr(14, 2) + "m";
    };

    useEffect(() => {
        function handleResize() {
            if (!cardWidthRef.current) return;
            let width = cardWidthRef.current.getBoundingClientRect().width;
            let widthToHeightRatio = 200 / 354;
            setCardHeight(window.innerWidth < 768 ? 82 : width * widthToHeightRatio);
        }
        handleResize();
        window.addEventListener("resize", handleResize);
    });

    return (
        <div className="col-xl-4 col-md-6 col-12 ct-column" key={card.slug} id={card.slug}>
            <LoadingIndicator loaderCount={countProgress} />
            <a href={PATH.course + card.slug} onClick={(e) => e.preventDefault()}>
                <div
                    id={`course-${card.slug}`}
                    className="ct-course-tile"
                    ref={cardWidthRef}
                    onClick={() => {
                        let splitUrl = history.location.pathname.split("/");
                        let urlType = splitUrl[1];
                        history.push({
                            pathname: PATH.course + card.slug,
                            state: {
                                isAnimate: false,
                                slug: card.slug,
                                pathSlug:
                                    "/" + urlType + "/" === PATH.learningPath ? splitUrl[2] : null,
                            },
                        });
                    }}
                >
                    <div
                        className="ct-img"
                        style={{ backgroundImage: `url(${imagePath})`, height: cardHeight }}
                    >
                        <div
                            className={`${
                                progressStatus === LANG.LABEL.IN_PROGRESS
                                    ? "ct-label-blue"
                                    : progressStatus === LANG.LABEL.COMPLETED
                                    ? "ct-label-green"
                                    : progressStatus !== ""
                                    ? "ct-label-dark-blue"
                                    : ""
                            }`}
                        >
                            <h3>
                                {" "}
                                {progressStatus === LANG.LABEL.IN_PROGRESS ? (
                                    ""
                                ) : progressStatus === LANG.LABEL.COMPLETED ? (
                                    <FontAwesomeIcon icon={faCheck} />
                                ) : (
                                    progressStatus
                                )}
                            </h3>
                        </div>
                    </div>
                    <div className="ct-body">
                        <div className="ct-text-content">
                            <h2>{card.title}</h2>
                        </div>

                        <div className="ct-info-group">
                            <div className="ct-info">
                                <h3>{amount}</h3>
                            </div>
                            <div className="ct-info">
                                <h3>{card.formatDurationForCardLabel(card)}</h3>
                            </div>
                            <div className="ct-info">
                                <FontAwesomeIcon
                                    icon={faStar}
                                    className="ct-type-star"
                                    style={{ color: levelTypeColor ? levelTypeColor : "#ff0000" }}
                                />
                                <h3>{cardType}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="ct-mobile-desc">
                        <h3>{card.title}</h3>
                        <div>
                            <i className="fa fa-clock-o clock-icon"></i>
                            <h4>{card.formatDurationForCardLabel(card)}</h4>
                            <FontAwesomeIcon
                                icon={faStar}
                                className="ct-type-star"
                                style={{ color: levelTypeColor ? levelTypeColor : "#ff0000" }}
                            />
                            <h4>{cardType}</h4>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        courseLevels: state.rootReducer.courseLevels,
    };
};
export default connect(mapStateToProps)(CourseTile);
