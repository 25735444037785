import React from 'react';
import ContentLoader from 'react-content-loader';

function LessonList() {
	return (
		<ContentLoader
			speed={2}
			backgroundColor={'#f6f6f8'}
			foregroundColor={'#ededed'}
			viewBox='0 -50 380 425'>
			<rect x='25' y='0' rx='5' ry='5' width='96' height='53' />
			<rect x='131' y='10' rx='4' ry='4' width='240' height='13' />
			<rect x='131' y='33' rx='3' ry='3' width='190' height='10' />

			<rect x='25' y='93' rx='5' ry='5' width='96' height='53' />
			<rect x='131' y='103' rx='4' ry='4' width='240' height='13' />
			<rect x='131' y='126' rx='3' ry='3' width='190' height='10' />

			<rect x='25' y='176' rx='5' ry='5' width='96' height='53' />
			<rect x='131' y='186' rx='4' ry='4' width='240' height='13' />
			<rect x='131' y='219' rx='3' ry='3' width='190' height='10' />

			<rect x='25' y='269' rx='5' ry='5' width='96' height='53' />
			<rect x='131' y='279' rx='4' ry='4' width='240' height='13' />
			<rect x='131' y='312' rx='3' ry='3' width='190' height='10' />

			<rect x='25' y='362' rx='5' ry='5' width='96' height='53' />
			<rect x='131' y='372' rx='4' ry='4' width='240' height='13' />
			<rect x='131' y='405' rx='3' ry='3' width='190' height='10' />
		</ContentLoader>
	);
}

export default LessonList;
