import Loader from 'react-loader-spinner';
import React, { useState, useEffect } from 'react';
import { LANG } from '../lang/Common';

function LoadingIndicator({ loaderCount }) {
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(loaderCount !== 0);
	}, [loaderCount]);

	return isLoading ? (
		<div className='ct-load-indicator'>
			{/* <Loader type={LANG.loader.type} color={LANG.loader.color} height={100} width={100} /> */}
			<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
		</div>
	) : null;
}

export default LoadingIndicator;
