import axios from 'axios';

export default function setAuthorizationToken(token) {
	if (token) {
		axios.defaults.headers.common['Content-Type'] = 'application/json';
		axios.defaults.headers.common['X-Token-Auth'] = `Bearer ${token}`;
	} else {
		delete axios.defaults.headers.common['X-Token-Auth'];
	}
}
