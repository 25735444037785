import React, { useState, useEffect } from 'react';
import { PATH, HTTP, URL } from '../constant';
import http from '../http';
import setAuthorizationToken from '../setAuthorizationToken';
import { reducerTypes } from '../reducerTypes';
import { LANG as CommonLang } from '../lang/Common';
import { ErrorHandler } from './ErrorHandler';
import { connect } from 'react-redux';
import LoadingIndicator from './LoadingIndicator';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';

function SignIn({ handleLogIn }) {
	const [count, setCount] = useState(0);
	const [formIsValid, setFormIsValid] = useState(false);

	let history = useHistory();

	const handleLinkClick = (e) => {
		e.preventDefault();
		let url = e.target.getAttribute('href');

		history.push({
			pathname: url,
		});
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		let formData = new FormData(document.forms.forgotForm);
		let data = {
			email: formData.get('email'),
		};

		setCount((prevCount) => prevCount + 1);
		setFormIsValid(false);
		http(HTTP.METHOD.POST, URL.auth.forgotPassword, data).then(
			(response) => {
				try {
					setCount(0);
					ErrorHandler.showSuccess(
						'Check your email',
						'We have just sent an email to you with a link to reset the password!',
					);
				} catch (error) {
					setCount(0);
					ErrorHandler.showError(CommonLang.error.general.title, error);
				}
				setFormIsValid(true);
			},
			(err) => {
				setCount(0);
				let customHandlers = {
					[HTTP.RESPONSE.UNPROCESSABLE_ENTITY.CODE]: (error) => {
						ErrorHandler.showError('Please wait', error.response.data.message);
					},
				};
				ErrorHandler.handle(err, customHandlers);
				setFormIsValid(true);
			},
		);
	};

	const validateEmail = (e) => {
		let value = e.target.value;
		let emailIsValid = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value));
		setFormIsValid(emailIsValid);
	}

	return (
		<>
			<div className='container-fluid ct-sign-in'>
				<LoadingIndicator loaderCount={count} />
				<div className='ct-sign-in-container'>
					<div className='container'>
						<div
							id='login-row'
							className='row justify-content-center align-items-center'>
							<div className='col-12 col-md-5'>
								<form
									onSubmit={(e, d) => handleFormSubmit(e)}
									id='forgotForm'
									className='form'
									action=''
									method='post'>
									<h3 className='text-center'>Reset Password</h3>
									<div className='form-group'>
										{/* <p className="email-sent-instructions">An email with the reset instructions has been sent to the provided email address if a matching account was found.</p> */}
									</div>
									<div className='form-group'>
										<input
											autoFocus={true}
											onChange={validateEmail}
											type='text'
											name='email'
											id='email'
											placeholder='Email Address'
											className='form-control'
										/>
									</div>
									<div className='form-group text-center'>
										<button
											type='submit'
											className='ct-btn-blue'
											disabled={!formIsValid}
										>
											Reset Password
										</button>
										<div className='profile-links'>
											<a
												className='special-action-link'
												onClick={(e) => handleLinkClick(e)}
												href={PATH.login}>
												Sign In
											</a>
											<a
												className='special-action-link'
												onClick={(e) => handleLinkClick(e)}
												href={PATH.createAccount}>
												Sign Up
											</a>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleLogIn: (data) => {
			dispatch({
				type: reducerTypes.USER_LOGIN,
				param: { data },
			});
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
