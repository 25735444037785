import React from 'react';

function ValidationError(params) {
    if (params.Error) {
        return (
            <div className={'validation-msg visible' + (params.Class ? params.Class : '')}>{params.Error}</div>
        );
    } else {
        return (
            <div className={'validation-msg hidden' + (params.Class ? params.Class : '')}>&nbsp;</div>
        );
    }
}

export default ValidationError;