import React, { useEffect, useState } from 'react';
import { useCountUp } from 'react-countup';

function Counter({ statistic, isScroll }) {
	const { countUp, start } = useCountUp({
		start: 0,
		end: statistic.value,
		duration: 0.5,
		startOnMount: false,
		useEasing: false,
	});

	useEffect(() => {
		let i = 0;
		const startCounter = () => {
			const counter = document.getElementsByClassName(statistic.label);
			const duration = 1500;
			const target = +counter[0].getAttribute('data-target');
			const count = +counter[0].innerText;
			const inc = statistic.label === 'Students' ? 1000 : 10;
			const interval = duration / (target / inc);

			if (count + inc < target) {
				counter[0].innerText = count + inc;
				setTimeout(startCounter, interval);
			} else {
				counter[0].innerText = target;
			}
		};
		isScroll && start();
	}, [isScroll]);

	return (
		<div className='ct-counter' key={statistic.label}>
			<h6 className={statistic.label} data-target={statistic.value}>
				{countUp}
			</h6>
			<h4>{statistic.label}</h4>
		</div>
	);
}

export default Counter;
