export const reducerTypes = {
	USER_LOGIN: 'USER_LOGIN',
	HOME_DATA: 'HOME_DATA',
	SESSION_HANDLE: 'SESSION_HANDLE',
	PAGE_REFRESH_HANDLE: 'PAGE_REFRESH_HANDLE',
	HANDLE_MISSING_DATA: 'HANDLE_MISSING_DATA',
	ENROLLED_PATH: 'ENROLLED_PATH',
	START_TO_WATCH: 'START_TO_WATCH',
	MARK_AS_COMPLETED: 'MARK_AS_COMPLETED',
	USER_UPDATED: 'USER_UPDATED',
	ENROLLED_COURSE: 'ENROLLED_COURSE',
};
