import axios from 'axios';
import { HTTP } from './constant';

const http = (method, url, params) => {
	//update and put methods are both same
	if (method == HTTP.METHOD.PUT) {
		method = HTTP.METHOD.UPDATE;
	}

	switch (method) {
		case HTTP.METHOD.POST:
			return axios
				.post(url, params)
				.then((response) => {
					return response;
				})
				.catch((err) => {
					throw err;
				});

		case HTTP.METHOD.UPDATE:
			return axios
				.put(url, params)
				.then((response) => {
					return response;
				})
				.catch((err) => {
					throw err;
				});

		case HTTP.METHOD.DELETE:
			return axios
				.delete(url)
				.then((response) => {
					return response;
				})
				.catch((err) => {
					throw err;
				});

		default:
			return axios
				.get(url, params)
				.then((response) => {
					return response;
				})
				.catch((err) => {
					throw err;
				});
	}
};

export default http;
