import React from 'react';
import Input from './Input';
import ValidationError from './ValidationError';

function FormInputDouble(params) {
    const Class = params.Class ? params.Class : 'form-group';
    const Label = params.Label ? params.Label : '';
    const ID = params.ID ? params.ID : null;
    const ID2 = params.ID2 ? params.ID2 : null;
    const Type = params.Type ? params.Type : 'text';
    const Placeholder = params.Placeholder ? params.Placeholder : null;
    const Placeholder2 = params.Placeholder2 ? params.Placeholder2 : null;

    const InputClass = params.InputClass ? params.InputClass : null;

    const Description = params.Description ? params.Description : null;
    const DescriptorID = Description ? ID + "Help" : null;
    const Descriptor = Description ?
        <small id={DescriptorID} className="form-text text-muted">{Description}</small> :
        null;

    let Error1 = params.Error1 ? params.Error1 : null;
    let Error2 = params.Error2 ? params.Error2 : null;

    return (
        <div className={Class}>
            <label htmlFor={ID}>{Label}</label>
            <div className="row margin-right-0">
                <div className="col-12 col-md-6">
                    <Input
                        Class={InputClass}
                        Type={Type}
                        ID={ID}
                        Placeholder={Placeholder}
                        Value={params.Value}
                        Setter={params.Setter}
                        AriaDescribedBy={DescriptorID}
                    />
                    <ValidationError Error={Error1} />
                </div>
                <div className="col-12 col-md-6">
                    <Input
                        Class={InputClass}
                        Type={Type}
                        ID={ID2}
                        Placeholder={Placeholder2}
                        Value={params.Value2}
                        Setter={params.Setter2}
                        AriaDescribedBy={DescriptorID}
                    />
                    <ValidationError Error={Error2} />
                </div>
            </div>
            {Descriptor}
        </div>
    )
}

export default FormInputDouble;