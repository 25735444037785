import React, { useEffect, useState } from 'react';

export function getImages() {
	let imageLocation = '/images/avatar/';
	let images = [];

	for (let index = 1; index < 17; index++) {
		images.push({
			id: index,
			src: imageLocation + index + '.svg',
		});
	}

	return images;
}

export function getImage(imageID) {
	if (imageID) {
		let images = getImages();
		return images[parseInt(imageID) - 1];
	} else {
		return null;
	}
}

function AvatarSelector({
	ShowSelector = false,
	SelectedEventHandler,
	VisibilityController,
	CurrentPath,
}) {
	let images = getImages();

	const backDrop = () => {
		if (ShowSelector) {
			return (
				<div
					onClick={() => {
						VisibilityController(false);
					}}
					className='avatar-selector-backdrop'></div>
			);
		}
	};

	return (
		<div className={'avatar-container ' + (ShowSelector ? '' : 'hidden')}>
			<div className={'avatar-selector'}>
				{images.map((img) => {
					return (
						<img
							key={img.id}
							onClick={() => SelectedEventHandler(img)}
							className={'avatar ' + (CurrentPath == img.src ? ' selected' : '')}
							src={img.src}></img>
					);
				})}
			</div>
			{backDrop()}
		</div>
	);
}

export default AvatarSelector;
