import React from 'react';
import ContentLoader from 'react-content-loader';

function Cards() {
	const width = window.innerWidth;
	return width < 768 ? (
		<ContentLoader
			speed={2}
			backgroundColor={'#f6f6f8'}
			foregroundColor={'#ededed'}
			viewBox={`0 0 363 1293`}>
			<rect x='0' y='0' rx='5' ry='5' width='100%' height='416' />
			<rect x='0' y='431' rx='5' ry='5' width='100%' height='416' />
			<rect x='0' y='862' rx='5' ry='5' width='100%' height='416' />
		</ContentLoader>
	) : width < 1200 ? (
		<ContentLoader
			speed={2}
			backgroundColor={'#f6f6f8'}
			foregroundColor={'#ededed'}
			viewBox={`0 0 768 960`}>
			<rect x='20' y='0' rx='5' ry='5' width='350' height='460' />
			<rect x='390' y='0' rx='5' ry='5' width='350' height='460' />
			<rect x='180' y='480' rx='5' ry='5' width='350' height='460' />
		</ContentLoader>
	) : (
		<ContentLoader
			speed={2}
			backgroundColor={'#f6f6f8'}
			foregroundColor={'#ededed'}
			viewBox={`0 0 1200 500`}>
			<rect x='25' y='0' rx='5' ry='5' width='350' height='460' />
			<rect x='425' y='0' rx='5' ry='5' width='350' height='460' />
			<rect x='825' y='0' rx='5' ry='5' width='350' height='460' />
		</ContentLoader>
	);
}

export default Cards;
