import React, { useEffect, useState, useRef } from "react";
import RoundedButton from "./RoundedButton";
import PathTile from "./PathTile";
import { LANG } from "../lang/LandingPage";
import { connect } from "react-redux";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Cards from "./placeholders/Cards";

function PathLayout({
    learningPaths,
    learningPathCategories,
    frameworks,
    filterPathList,
    maxCardNum = "UNLIMIT",
}) {
    const [pathFilters, setPathFilters] = useState([]);
    const [selectedFilterPaths, setSelectedFilterPaths] = useState("");
    const [learningPathsList, setLearningPathsList] = useState([]);
    const [pathValidList, setPathValidList] = useState([]);
    const [frameworksList, setFrameworks] = useState([]);
    const [framework, setFramework] = useState({ lable: "", value: "" });
    const [isShow, setIsShow] = useState(false);
    const [minHeight, setMinHeight] = useState(0);

    let rowRef = useRef();

    useEffect(() => {
        setIsShow(false);
        if (maxCardNum !== "UNLIMIT") {
            let limitedPaths = [];
            if (filterPathList === undefined) {
                limitedPaths = learningPaths.filter((path, index) => index < maxCardNum);
            } else {
                limitedPaths = filterPathList.filter((path, index) => index < maxCardNum);
            }
            setLearningPathsList(limitedPaths);
        } else {
            setLearningPathsList(filterPathList === undefined ? learningPaths : filterPathList);
        }

        setPathValidList(filterPathList === undefined ? learningPaths : filterPathList);
        setPathFilters([...LANG.pathFilters, ...learningPathCategories]);
        let frameworkOpt = [{ value: "", label: LANG.placeholder.dropdown }];
        let pFrameworks = frameworks.map((obg) => {
            return { value: obg.slug, label: obg.title };
        });
        frameworkOpt = pFrameworks.length > 0 && [...frameworkOpt, ...pFrameworks];
        setFrameworks(frameworkOpt);
    }, [learningPaths]);

    const setFilterValue = (value) => {
        let filterList = pathValidList.filter((path) => {
            return value === "" ? path.category.slug.includes(value) : path.category.slug === value;
        });
        if (framework.value !== "") {
            filterList = filterList.filter((path) =>
                path.frameworks.some((lang) => lang.slug === framework.value),
            );
        }
        setSelectedFilterPaths(value);
        setMinHeight(rowRef.current.getBoundingClientRect().height);
        setLearningPathsList([]);
        setTimeout(() => {
            if (maxCardNum !== "UNLIMIT") {
                let limitedPaths = filterList.filter((path, index) => index < maxCardNum);

                setLearningPathsList(limitedPaths);
            } else {
                setLearningPathsList(filterList);
            }
            setMinHeight(0);
            setIsShow(filterList.length < 1);
        }, 100);
    };
    const changeFramework = (opt) => {
        let filterList = pathValidList.filter((path) =>
            path.frameworks.some((obj) =>
                opt.value === "" ? obj.slug.includes(opt.value) : obj.slug === opt.value,
            ),
        );

        if (selectedFilterPaths !== "") {
            filterList = filterList.filter((path) => {
                return path.category.slug === selectedFilterPaths;
            });
        }
        setMinHeight(rowRef.current.getBoundingClientRect().height);
        setLearningPathsList([]);
        setTimeout(() => {
            if (maxCardNum !== "UNLIMIT") {
                let limitedPaths = filterList.filter((path, index) => index < maxCardNum);

                setLearningPathsList(limitedPaths);
            } else {
                setLearningPathsList(filterList);
            }

            setMinHeight(0);
            setIsShow(filterList.length < 1);
        }, 100);

        setFramework(opt);
    };

    return (
        <div className="ct-learning-path" id="ct-path-main">
            <h1 className="ct-card-title">{LANG.learningPaths}</h1>
            <div className="ct-filter-container">
                {pathFilters.length > 1
                    ? pathFilters.map((filter) => {
                          return (
                              <RoundedButton
                                  key={filter.slug}
                                  filter={filter}
                                  setFilterValue={setFilterValue}
                                  isCourse={false}
                                  selectedFilterPaths={selectedFilterPaths}
                              />
                          );
                      })
                    : [1, 2, 3, 4].map((filter, index) => {
                          return (
                              <RoundedButton
                                  key={index}
                                  filter={filter}
                                  setFilterValue={setFilterValue}
                                  isCourse={false}
                                  selectedFilterPaths={selectedFilterPaths}
                              />
                          );
                      })}
                <Dropdown
                    options={frameworksList}
                    placeholder={LANG.placeholder.dropdown}
                    className="ct-dropdown-language"
                    value={framework.value}
                    onChange={(opt) => changeFramework(opt)}
                ></Dropdown>
            </div>
            <div className="row ct-row" ref={rowRef} style={{ minHeight: minHeight }}>
                {frameworks.length === 0 ? (
                    <Cards />
                ) : (
                    <>
                        {learningPathsList.map((path) => {
                            return <PathTile key={path.slug} card={path} />;
                        })}
                        <div className={isShow ? "ct-empty" : ""}>
                            {isShow && "No results found"}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        learningPaths: state.rootReducer.learningPaths,
        learningPathCategories: state.rootReducer.learningPathCategories,
        frameworks: state.rootReducer.frameworks,
    };
};
export default connect(mapStateToProps)(PathLayout);
