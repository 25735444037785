import swal from 'sweetalert';

export const signInConfirmDialog = (title, text, button = 'Sign Up') =>
	swal({
		title: title,
		text: text,
		icon: 'warning',
		buttons: {
			close: {
				text: 'Close ',
				value: 'close',
			},
			confirm: {
				text: button,
				value: 'confirm',
			},
		},
		dangerMode: true,
	});
export const showDialog = (title, text) =>
	swal({
		title: title,
		text: text,
		icon: 'warning',
		buttons: {
			close: {
				text: 'Close',
				value: 'close',
			},
		},
		dangerMode: true,
	});
