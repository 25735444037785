import React from "react";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { YOUTUBE_LESSON_THUMBNAIL } from "../constant";
import { useHistory } from "react-router-dom";

function VideoList({ lesson, userInfo, pushDataToPlayer }) {
  const thumbnail = YOUTUBE_LESSON_THUMBNAIL.replace(new RegExp("<insert-youtube-video-id-here>"), lesson.content);
  const duration = lesson.duration_in_seconds;
  const durationInMinutes = Math.floor(duration / 60);
  let { push } = useHistory();

  return (
    <div className='ct-playlist'>
      <div
        className='ct-video-thumbnail'
        style={{
          backgroundImage: `url(${thumbnail})`,
        }}
        onClick={() => {
          pushDataToPlayer(lesson);
        }}
      >
        <a href='#' className='round-button'>
          <FontAwesomeIcon icon={faPlay} className='icon' />
        </a>
        {userInfo.lessons !== undefined
          ? userInfo.lessons.in_progress.includes(lesson.id) && <span className='video-progress-blue'>In Progress</span>
          : null}
        {userInfo.lessons !== undefined
          ? userInfo.lessons.completed.includes(lesson.id) && <span className='video-progress-green'>Completed</span>
          : null}
      </div>
      <div className='ct-video-description'>
        <h3>{lesson.title}</h3>
        <h4>{durationInMinutes}m</h4>
      </div>
    </div>
  );
}

export default VideoList;
