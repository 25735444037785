import { PATH, TAG } from "../constant";
const types = {
    path: "path",
    course: "course",
    learning: "learning",
    signIn: "sign-in",
};
export const LANG = {
    searchPlaceholderAll: "Search learning paths and courses",
    searchPlaceholderPaths: "Search learning paths",
    searchPlaceholderCourses: "Search courses",
    dropDownTitle: { myProfile: "My Profile", signOut: "Sign Out" },
    linkTypes: types,
    links: [
        {
            title: "Learning Paths",
            actionLink: {
                pathname: PATH.search,
                state: { rootFilter: "paths", filter: "Paths" },
                hash: "ct-path-area",
            },
            id: types.path,
        },
        {
            title: "Courses",
            actionLink: {
                pathname: PATH.search,
                state: { rootFilter: "courses", filter: "Courses" },
                hash: "ct-course-area",
            },
            id: types.course,
        },
        { title: "Sign In", actionLink: "/sign-in", id: types.signIn },
        { title: "Start Learning", actionLink: `${PATH.createAccount}`, id: types.learning },
    ],
    dropDownList: { all: "All", paths: "Paths", courses: "Courses" },
};
