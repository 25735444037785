import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from '../constant';

function ActivityProgress({ progressData, isCourse, setRowHeight }) {
	const heightRef = useRef();
	const { push } = useHistory();

	useEffect(() => {
		if (!heightRef.current) return;
		const height = heightRef.current.getBoundingClientRect().height;
		setRowHeight(height);
	});
	return (
		<div
			className='ct-progress-row'
			ref={heightRef}
			onClick={() =>
				isCourse
					? push({
							pathname: PATH.course + progressData.slug,
					  })
					: push({
							pathname: PATH.learningPath + progressData.slug,
							state: { isAnimate: false, slug: progressData.slug },
					  })
			}>
			{!isCourse && progressData.is_completed && <div className='ct-badge'></div>}
			<div
				className={`ct-background ${
					!isCourse && progressData.is_completed && 'ct-background-alt'
				}`}
				style={{
					backgroundImage: `url(${
						isCourse ? progressData.course_image_path : progressData.path_image
					})`,
				}}></div>
			<h3 className='ct-category-title'>{progressData.title}</h3>
		</div>
	);
}

export default ActivityProgress;
