import React from 'react';
import { connect } from 'react-redux';
import ReactCountryFlag from 'react-country-flag';
import { isWindows } from 'react-device-detect';

function CountryFlag({ alpa3, isoMap }) {

	if (alpa3) {
		const getCountryFlag = () => isoMap[alpa3].toLocaleLowerCase();

		return isWindows ? (
			<ReactCountryFlag className='emojiFlag country-flag' countryCode={getCountryFlag()} svg />
		) : (
				<ReactCountryFlag className='emojiFlag country-flag' countryCode={getCountryFlag()} />
			);
	}
	else {
		return (<></>);
	}
}
const mapStateToProps = (state) => {
	return { isoMap: state.rootReducer.countryISOMapping };
};
export default connect(mapStateToProps)(CountryFlag);
