import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import LoadingIndicator from './LoadingIndicator';
import FormInput from './blocks/FormInput';
import FormInputDouble from './blocks/FormInputDouble';
import FormInputCustom from './blocks/FormInputCustom';
import AvatarSelector from './blocks/AvatarSelector';
import swal from 'sweetalert';
import { URL, HTTP, PATH } from '../constant';
import http from '../http';
import { useHistory } from 'react-router-dom';
import { ErrorHandler } from './ErrorHandler';
import setAuthorizationToken from '../setAuthorizationToken';
import { LANG as CommonLang } from '../lang/Common';
import { reducerTypes } from '../reducerTypes';
import { ReCaptcha } from 'react-recaptcha-v3';
import Select from 'react-select';

function CreateAccount({ countryInfo, handleLogIn, isLogin }) {
	let history = useHistory();

	let [count, setCount] = useState(0);

	let [email, setEmail] = useState('');
	let [emailError, setEmailError] = useState('');

	let [password, setPassword] = useState('');
	let [passwordError, setPasswordError] = useState('');

	let [passwordConfirmation, setPasswordConfirmation] = useState('');
	let [passwordConfirmationError, setPasswordConfirmationError] = useState('');

	let [firstName, setFirstName] = useState('');
	let [firstNameError, setFirstNameError] = useState(null);

	let [lastName, setLastName] = useState('');
	let [lastNameError, setLastNameError] = useState(null);

	let [showSelector, setShowSelector] = useState(false);
	const defaultAvatarPath = '/images/add-user.png';
	let [avatarPath, setAvatarPath] = useState(defaultAvatarPath);

	let [designation, setDesignation] = useState('');
	let [designationError, setDesignationError] = useState('');

	let [country, setCountry] = useState(null);
	let [countryError, setCountryError] = useState('');

	let [captchaToken, setCaptchaToken] = useState('');

	let [avatar, setAvatar] = useState({
		src: null,
		id: 0,
	});
	let [formValidatedOnce, setFormValidatedOnce] = useState(false);

	useEffect(() => {
		if (isLogin) {
			history.push({
				pathname: PATH.profile,
			});
		}
	}, [isLogin]);

	const imageSelected = (img) => {
		setAvatar(img);
		setAvatarPath(img.src);
		setShowSelector(false);
	};

	const toggleAvatarSelector = () => {
		setShowSelector(!showSelector);
	};

	const defaultColumWidth = 'col-md-6 col-sm-12';

	let options = countryInfo.map((e) => {
		return {
			label: e.name,
			value: e['alpha-3'],
		};
	});

	const colourStyles = {
		control: (styles, state) => ({
			...styles,
			border: '1px solid #ededed',
			cursor: 'text',
			backgroundColor: '#ededed',
			'&:hover': {
				backgroundColor: 'white',
				border: '1px solid #a2a6a6',
			},
		}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				fontWeight: '400',
				color: '#313B3D',
				backgroundColor: isSelected?'#ededed':isFocused?'#ededed':'white',
				boxShadow: '0 1 2 rgba(0,0,0,0.16)',
				transition: 'all 0.15s ease',
				'&:hover': {
					backgroundColor: '#ededed',
				},
			};
		},
		input: (styles, { data, isDisabled, isFocused, isSelected }) => ({
			...styles,
			height: '37px',
			alignSelf: 'center',
			cursor: 'text',
			width: '100% !important',
		}),
		placeholder: (styles) => ({ ...styles, color: '#a2a6a6' }),
	};
	const rule = {
		required: function (val) {
			if (val) {
				return val.trim().length > 0;
			} else {
				return false;
			}
		},
		email: function (val) {
			return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val);
		},
	};

	const scrollToElement = (element, block = 'center') => {
		if (element) {
			element.scrollIntoView({
				behavior: 'smooth',
				block: block,
				inline: 'nearest',
			});
		}
	};

	const validateForm = () => {
		let formIsValid = true;
		setFormValidatedOnce(true);
		//avatar
		//if not between 1 and 16
		if (avatar.id < 1 || avatar.id > 16) {

			setShowSelector(true);
			let profileImage = document.getElementsByClassName('profile-image')[0];
			scrollToElement(profileImage, 'center');

			formIsValid &= false;
			return false;
		}

		//first name
		if (rule.required(firstName) == false) {
			formIsValid &= false;
			setFirstNameError('Please enter your first name');
		} else {
			setFirstNameError(null);
		}

		//last name
		if (rule.required(lastName) == false) {
			formIsValid &= false;
			setLastNameError('Please enter your last name');
		} else {
			setLastNameError(null);
		}

		//country
		if (country === null || rule.required(country.value) == false) {
			formIsValid &= false;
			setCountryError('Please select your country');
		} else {
			setCountryError(null);
		}

		//country
		if (rule.required(email) == false) {
			formIsValid &= false;
			setEmailError('Please enter a valid email address');
		} else if (rule.email(email) == false) {
			formIsValid &= false;
			setEmailError('Invalid email address');
		} else {
			setEmailError(null);
		}

		//password
		if (rule.required(password) == false) {
			formIsValid &= false;
			setPasswordError('Please enter a password');
		} else if (password.trim().length < 7) {
			setPasswordError('Password should be minimum 8 characters');
		} else {
			setPasswordError(null);

			//password confirmation
			if (password != passwordConfirmation) {
				setPasswordConfirmationError(
					'Password confirmation does not match with the password',
				);
			} else {
				setPasswordConfirmationError(null);
			}
		}

		return formIsValid;
	};


	const saveData = (e) => {
		e.preventDefault();
		let formIsValid = validateForm();
		if (formIsValid == false) {
			let thisIsAMobileView = window.innerWidth < 768;
			if (thisIsAMobileView) {
				setTimeout(() => {
					let firstErrorLabel = document.querySelector('.validation-msg.visible');
					scrollToElement(firstErrorLabel);
				}, 100);
			}
			return;
		}

		window.PendingFormSubmission = true;

		recaptcha.execute();

	};

	const saveDataToAPI = (reCaptchaToken) => {
		//validate the form
		//e.preventDefault();

		let data = {
			email: email,
			password: password,
			password_confirmation: passwordConfirmation,
			profile_image_url: avatar.id,
			first_name: firstName,
			last_name: lastName,
			country_code: country.value,
			job_title: designation,
			_recaptcha: reCaptchaToken,
		};

		setCount((prevCount) => prevCount + 1);
		http(HTTP.METHOD.POST, URL.auth.getRegistered, data)
			.then((response) => {
				//Sign in the user
				let signInForm = {
					email: data.email,
					password: data.password,
				};
				http(HTTP.METHOD.POST, URL.auth.logIn, signInForm).then(
					(response) => {
						try {
							const token = response.data.access_token;
							localStorage.setItem('jwtToken', token);
							setAuthorizationToken(token);

							http(HTTP.METHOD.GET, URL.auth.me).then(
								(responseMe) => {
									try {
										handleLogIn(responseMe.data);
										localStorage.setItem(
											CommonLang.saveStorage.key,
											responseMe.data.id ? true : false,
										);
										setCount((prevCount) => prevCount > 0 && prevCount - 1);

										// send to the profile page

										setCount(0);
										history.push({
											pathname: PATH.profile,
										});
									} catch (error) {
										setCount(0);
										ErrorHandler.showError(
											CommonLang.error.general.title,
											error,
										);
									}
								},
								(err) => {
									setCount(0);
									ErrorHandler.handle(err);
								},
							);
						} catch (error) {
							setCount(0);
							ErrorHandler.showError(CommonLang.error.general.title, error);
						}
					},
					(err) => {
						setCount(0);
						//default Unauthorized response code title isn't much meaningful in this situation
						//so we are using a custom handler for that

						let customHandlers = {
							[HTTP.RESPONSE.UNAUTHORIZED.CODE]: (error) => {
								ErrorHandler.showError(
									CommonLang.logInFailed,
									CommonLang.credentialsMismatched,
								).then((value) => {
									if (value == true) {
										let emailField = document.getElementsByName('email')[0];
										emailField.focus();
									}
								});
							},
						};
						ErrorHandler.handle(err, customHandlers);
					},
				);
			})
			.catch((err) => {
				setCount((prevCount) => prevCount - 1);
				ErrorHandler.handle(err);
			});
	};

	let verifyCallback = (recaptchaToken) => {
		setCaptchaToken(recaptchaToken);

		if (window.PendingFormSubmission) {
			window.PendingFormSubmission = false;
			saveDataToAPI(recaptchaToken);
		}

	};

	let recaptcha = {};

	return (
		<>
			<LoadingIndicator loaderCount={count} />
			<div className='container-fluid ct-user-container'>
				<div className='container ct-create-user'>
					<form onSubmit={(e) => saveData(e)}>
						<div className='row form'>
							<div className={defaultColumWidth + ' row first-field-set'}>
								<div className='profile-image-container'>
									<img
										onClick={toggleAvatarSelector}
										className={
											'profile-image ' +
											(showSelector
												? 'dialog-open-mobile'
												: 'dialog-closed-mobile') +
											(formValidatedOnce && avatarPath == defaultAvatarPath
												? ' error'
												: '')
										}
										src={avatarPath}
										alt='User profile'></img>
									<AvatarSelector
										CurrentPath={avatarPath}
										ShowSelector={showSelector}
										SelectedEventHandler={imageSelected}
										VisibilityController={setShowSelector}
									/>
								</div>

								<FormInputDouble
									Error1={firstNameError}
									Error2={lastNameError}
									Label='I’m'
									ID='first_name'
									ID2='last_name'
									Type='text'
									Placeholder='First Name'
									Placeholder2='Last Name'
									Value={firstName}
									Value2={lastName}
									Setter={setFirstName}
									Setter2={setLastName}
								/>

								<FormInputCustom
									Label='I hail from'
									Class='padding-right-15 form-group country-selector-group'
									ID='selecteCountry'
									Error={countryError}
									Component={
										<Select
											id='selecteCountry'
											value={country}
											options={options}
											isSearchable={true}
											placeholder='Country'
											onChange={(obj) => {
												setCountry(obj);
											}}
											styles={colourStyles}
										/>
									}
								/>
							</div>
							<div className={defaultColumWidth + ' row'}>
								<FormInput
									Error={emailError}
									Label='My email is'
									ID='email'
									Type='email'
									Placeholder='Email Address'
									Value={email}
									Setter={setEmail}
								/>
								<FormInput
									Error={passwordError}
									Label='I want my password to be'
									ID='password'
									Type='password'
									Placeholder='Password'
									Value={password}
									Setter={setPassword}
								/>
								<FormInput
									Error={passwordConfirmationError}
									Label='Just in case'
									ID='password_confirmation'
									Type='password'
									Placeholder='Enter Password Again'
									Value={passwordConfirmation}
									Setter={setPasswordConfirmation}
								/>
								<div className='form-group designation-input-group'>
									<label htmlFor='designation'>I’m a</label>
									<input
										value={designation}
										onChange={(e) => {
											setDesignation(e.target.value);
										}}
										className='profession_input'
										type='text'
										id='designation'
										placeholder='Profession'></input>
									<label className='profession_label' htmlFor='designation'>
										by day
									</label>
									<label htmlFor='designation'>
										and an Automation Ninja by night!
									</label>
								</div>
								<div className='col-12'>
									<ReCaptcha
										ref={(ref) => (recaptcha = ref)}
										sitekey='6LdbaasZAAAAALHvD_Yi4wjjqtuG0_NehIFpPmKw'
										action='create_account'
										verifyCallback={verifyCallback}
									/>
									<button
										type='submit'
										className='btn  btn-lg ct-btn-blue ct-btn-submit'>
										Create Account
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		countryInfo: state.rootReducer.countryInfo,
		isLogin: state.rootReducer.isLogin,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		handleLogIn: (data) => {
			dispatch({
				type: reducerTypes.USER_LOGIN,
				param: { data },
			});
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
