export const LANG = {
	error: {
		unauthorized: {
			title: 'You are not Authorized to perform this task',
			description:
				'You are trying to perform an action beyond your authorization level. Please contact us if you needs to use this functionality',
		},
		general: {
			title: 'Something Went Wrong!',
		},
		internalServerError: {
			title: 'Internal Server Error',
			description:
				'Sorry, an error occurred while processing your request. You can try reloading the page. \nPlease try again in a few minutes if the error persists. ',
		},
	},
	loader: { type: 'ThreeDots', color: '#4a7afb' },
	saveStorage: { key: 'userlog' },
	logInFailed: 'Failed to Sign In',
	credentialsMismatched: 'Your Email and/or Password do not match.',
	defaultProfileImage:
		'https://creativeedtech.weebly.com/uploads/4/1/6/3/41634549/published/avatar.png?1487742111',
	failedToChange: 'Failed to change the password',
	passwordReset: 'Password Reset',
};
