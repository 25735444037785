import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { LANG as COMMONLANG } from "../lang/Common";
import { LANG } from "../lang/LandingPage";
import { LANG as CARD_LANG } from "../lang/Card";
import LoadingIndicator from "./LoadingIndicator";
import ActivityProgress from "./ActivityProgress";
import { TablePagination } from "@trendmicro/react-paginations";
import "@trendmicro/react-paginations/dist/react-paginations.css";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PathLayout from "./PathLayout";
import CourseLayout from "./CourseLayout";
import { getImage } from "./blocks/AvatarSelector";
import { LANG as COMMON_LANG } from "../lang/Common";
import { MAX_LIMIT, PATH } from "../constant";
import { useHistory } from "react-router-dom";
import { LANG as NAV_LANG } from "../lang/NavigationBar";

function Activity({ userData, changeHeaderFilterType }) {
    const [user, setUser] = useState(userData);
    const [count, setCount] = useState(0);
    const [completedPaths, setCompletedPaths] = useState([]);
    const [inProgressPaths, setInProgressPaths] = useState([]);
    const [completedCourses, setCompletedCourses] = useState([]);
    const [inProgressCourses, setInProgressCourses] = useState([]);
    const [pageLength] = useState(5);

    const [completedPathsPagination, setCompletedPathsPagination] = useState([]);
    const [inProgressPathsPagination, setInProgressPathsPagination] = useState([]);
    const [completedCoursesPagination, setCompletedCoursesPagination] = useState([]);
    const [inProgressCoursesPagination, setInProgressCoursesPagination] = useState([]);

    const [pagePathCompleted, setPagePathCompleted] = useState(1);
    const [totalPathCompleted, setTotalPathCompleted] = useState(0);
    const [pagePathInprogress, setPagePathInprogress] = useState(1);
    const [totalPathInprogress, setTotalPathInprogress] = useState(0);

    const [pageCourseCompleted, setPageCourseCompleted] = useState(1);
    const [totalCourseCompleted, setTotalCourseCompleted] = useState(0);
    const [pageCourseInprogress, setPageCourseInprogress] = useState(1);
    const [totalCourseInprogress, setTotalCourseInprogress] = useState(0);
    //const [profileImageURL, setProfileImageURL] = useState(COMMON_LANG.defaultProfileImage);

    const [rowHeight, setRowHeight] = useState(0);
    const [isEmpty, setIsEmpty] = useState(true);
    const { replace, location } = useHistory();

    useEffect(() => {
        if (userData.country_code) {
            setCount(0);
        } else {
            setCount((prevProp) => prevProp + 1);
        }
        setUser(userData);

        /* let img = getImage(userData.profile_image_url);
		setProfileImageURL(img ? img.src : COMMON_LANG.defaultProfileImage); */

        if (userData.learning_paths) {
            const _completePaths = userData.learning_paths.filter((data) => data.is_completed);
            const _inprogressPaths = userData.learning_paths.filter((data) => !data.is_completed);
            let _completeCourses = userData.courses.filter((data) => data.is_completed);
            //&& !data.have_user_enrolled_learning_paths,
            let _inprogressCourses = userData.courses.filter(
                (data) => !data.is_completed && data.last_viewed_lesson_slug !== null,
            );
            function compare(a, b) {
                const titleA = a.title.toUpperCase();
                const titleB = b.title.toUpperCase();

                let comparison = 0;
                if (titleA > titleB) {
                    comparison = 1;
                } else if (titleA < titleB) {
                    comparison = -1;
                }
                return comparison;
            }

            setCompletedPaths(_completePaths.sort(compare));
            setInProgressPaths(_inprogressPaths.sort(compare));
            setCompletedCourses(_completeCourses.sort(compare));
            setInProgressCourses(_inprogressCourses.sort(compare));
            setTotalPathCompleted(_completePaths.length);
            setTotalPathInprogress(_inprogressPaths.length);
            setTotalCourseCompleted(_completeCourses.length);
            setTotalCourseInprogress(_inprogressCourses.length);

            let indexOfLastRow = 1 * pageLength;
            let indexOfFirstRow = indexOfLastRow - pageLength;
            setInProgressCoursesPagination(
                _inprogressCourses.slice(indexOfFirstRow, indexOfLastRow),
            );

            indexOfLastRow = 1 * pageLength;
            indexOfFirstRow = indexOfLastRow - pageLength;
            setCompletedCoursesPagination(_completeCourses.slice(indexOfFirstRow, indexOfLastRow));

            indexOfLastRow = 1 * pageLength;
            indexOfFirstRow = indexOfLastRow - pageLength;
            setInProgressPathsPagination(_inprogressPaths.slice(indexOfFirstRow, indexOfLastRow));

            indexOfLastRow = 1 * pageLength;
            indexOfFirstRow = indexOfLastRow - pageLength;
            setCompletedPathsPagination(_completePaths.slice(indexOfFirstRow, indexOfLastRow));

            setIsEmpty(userData.learning_paths.length < 1 && userData.courses.length < 1);
        }
    }, [userData]);

    /* 	user.getCountry = () => {
		if (user.country_code) {
			let country = countryInfo.filter((country) => {
				return country['alpha-3'] === user.country_code;
			});
			return country[0].name;
		} else {
			return '';
		}
	}; */
    /* user.getFlag = () => {
		if (user.country_code) {
			const flagUrl = `/images/flags/${user.country_code.toLowerCase()}.svg`;
			return flagUrl;
		} else {
			return '#';
		}
	}; */
    return (
        <div className="ct-activity-page">
            <LoadingIndicator loaderCount={count} />
            <div className="container-fluid ct-container-fluid">
                {/* <div className='ct-profile-content'>
					<div
						className='ct-profile-image'
						style={{
							backgroundImage: `url(
								${profileImageURL}
								)`,
						}}></div>
					<div className='ct-text-content'>
						<div className='ct-profile-name'>
							<h1>{`Hello ${user.first_name ? user.first_name : ''} ${
								user.last_name ? user.last_name : ''
							}`}</h1>
						</div>
						<div className='ct-inline-content'>
							<img className='ct-flag' src={user.getFlag()}></img>
							<h3>{user.getCountry()}</h3>
						</div>
					</div>
				</div> */}
                <div className="container ct-container">
                    {!isEmpty ? (
                        <div className="row ct-progress-container">
                            <div className="col-md-6 ct-column" id="ct-complete-path-title">
                                <div className="ct-progress-title">
                                    <h3>{LANG.learningPaths}</h3>
                                    <div className="ct-label ct-label-green">
                                        <h3>{CARD_LANG.LABEL.COMPLETED}</h3>
                                    </div>
                                </div>
                                <div className="ct-pagination-container">
                                    <div>
                                        {completedPathsPagination.map((data) => (
                                            <ActivityProgress
                                                progressData={data}
                                                isCourse={false}
                                                key={data.slug}
                                                setRowHeight={setRowHeight}
                                            />
                                        ))}
                                        {completedPathsPagination.length < 1 && (
                                            <h3 className="ct-empty-message">
                                                You have not completed any learning paths
                                            </h3>
                                        )}
                                    </div>
                                    <div className="ct-pagination-text">
                                        {Math.ceil(totalPathCompleted / pageLength) > 1 && (
                                            <>
                                                <TablePagination
                                                    className="ct-pagination"
                                                    type="minor"
                                                    page={pagePathCompleted}
                                                    pageLength={pageLength}
                                                    totalRecords={totalPathCompleted}
                                                    onPageChange={({ page, pageLength }) => {
                                                        location.href = "#ct-complete-path-title";
                                                        window.location.href =
                                                            "#ct-complete-path-title";
                                                        setPagePathCompleted(page);
                                                        let indexOfLastRow = page * pageLength;
                                                        let indexOfFirstRow =
                                                            indexOfLastRow - pageLength;
                                                        setCompletedPathsPagination(
                                                            completedPaths.slice(
                                                                indexOfFirstRow,
                                                                indexOfLastRow,
                                                            ),
                                                        );
                                                    }}
                                                    prevPageRenderer={() => (
                                                        <FontAwesomeIcon
                                                            icon={faAngleLeft}
                                                            className="icon"
                                                        />
                                                    )}
                                                    nextPageRenderer={() => (
                                                        <FontAwesomeIcon
                                                            icon={faAngleRight}
                                                            className="icon"
                                                        />
                                                    )}
                                                />
                                                <h3>
                                                    {`${pagePathCompleted} of ${Math.ceil(
                                                        totalPathCompleted / pageLength,
                                                    )}`}
                                                </h3>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 ct-column" id="ct-inprogress-path-title">
                                <div className="ct-progress-title">
                                    <h3>{LANG.learningPaths}</h3>
                                    <div className="ct-label ct-label-blue">
                                        <h3>{CARD_LANG.LABEL.IN_PROGRESS}</h3>
                                    </div>
                                </div>
                                <div className="ct-pagination-container">
                                    <div>
                                        {inProgressPathsPagination.map((data) => (
                                            <ActivityProgress
                                                progressData={data}
                                                isCourse={false}
                                                key={data.slug}
                                                setRowHeight={setRowHeight}
                                            />
                                        ))}
                                        {inProgressPathsPagination.length < 1 && (
                                            <h3 className="ct-empty-message">
                                                You are not following any learning paths{" "}
                                            </h3>
                                        )}
                                    </div>
                                    <div className="ct-pagination-text">
                                        {Math.ceil(totalPathInprogress / pageLength) > 1 && (
                                            <>
                                                <TablePagination
                                                    className="ct-pagination"
                                                    type="minor"
                                                    page={pagePathInprogress}
                                                    pageLength={pageLength}
                                                    totalRecords={totalPathInprogress}
                                                    onPageChange={({ page, pageLength }) => {
                                                        location.href = "#ct-inprogress-path-title";
                                                        window.location.href =
                                                            "#ct-inprogress-path-title";
                                                        setPagePathInprogress(page);
                                                        let indexOfLastRow = page * pageLength;
                                                        let indexOfFirstRow =
                                                            indexOfLastRow - pageLength;
                                                        setInProgressPathsPagination(
                                                            inProgressPaths.slice(
                                                                indexOfFirstRow,
                                                                indexOfLastRow,
                                                            ),
                                                        );
                                                    }}
                                                    prevPageRenderer={() => (
                                                        <FontAwesomeIcon
                                                            icon={faAngleLeft}
                                                            className="icon"
                                                        />
                                                    )}
                                                    nextPageRenderer={() => (
                                                        <FontAwesomeIcon
                                                            icon={faAngleRight}
                                                            className="icon"
                                                        />
                                                    )}
                                                />

                                                <h3>
                                                    {`${pagePathInprogress} of ${Math.ceil(
                                                        totalPathInprogress / pageLength,
                                                    )}`}
                                                </h3>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 ct-column" id="ct-complete-course-title">
                                <div className="ct-progress-title">
                                    <h3>{LANG.courses}</h3>
                                    <div className="ct-label ct-label-green">
                                        <h3>{CARD_LANG.LABEL.COMPLETED}</h3>
                                    </div>
                                </div>
                                <div className="ct-pagination-container">
                                    <div>
                                        {completedCoursesPagination.map((data) => (
                                            <ActivityProgress
                                                progressData={data}
                                                isCourse={true}
                                                key={data.slug}
                                                setRowHeight={setRowHeight}
                                            />
                                        ))}
                                        {completedCoursesPagination.length < 1 && (
                                            <h3 className="ct-empty-message">
                                                You have not completed any courses
                                            </h3>
                                        )}
                                    </div>
                                    <div className="ct-pagination-text">
                                        {Math.ceil(totalCourseCompleted / pageLength) > 1 && (
                                            <>
                                                <TablePagination
                                                    className="ct-pagination"
                                                    type="minor"
                                                    page={pageCourseCompleted}
                                                    pageLength={pageLength}
                                                    totalRecords={totalCourseCompleted}
                                                    onPageChange={({ page, pageLength }) => {
                                                        location.href = "#ct-complete-course-title";
                                                        window.location.href =
                                                            "#ct-complete-course-title";
                                                        setPageCourseCompleted(page);
                                                        let indexOfLastRow = page * pageLength;
                                                        let indexOfFirstRow =
                                                            indexOfLastRow - pageLength;
                                                        setCompletedCoursesPagination(
                                                            completedCourses.slice(
                                                                indexOfFirstRow,
                                                                indexOfLastRow,
                                                            ),
                                                        );
                                                    }}
                                                    prevPageRenderer={() => (
                                                        <FontAwesomeIcon
                                                            icon={faAngleLeft}
                                                            className="icon"
                                                        />
                                                    )}
                                                    nextPageRenderer={() => (
                                                        <FontAwesomeIcon
                                                            icon={faAngleRight}
                                                            className="icon"
                                                        />
                                                    )}
                                                />

                                                <h3>
                                                    {`${pageCourseCompleted} of ${Math.ceil(
                                                        totalCourseCompleted / pageLength,
                                                    )}`}
                                                </h3>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 ct-column" id="ct-inprogress-course-title">
                                <div className="ct-progress-title">
                                    <h3>{LANG.courses}</h3>
                                    <div className="ct-label ct-label-blue">
                                        <h3>{CARD_LANG.LABEL.IN_PROGRESS}</h3>
                                    </div>
                                </div>
                                <div className="ct-pagination-container">
                                    <div>
                                        {inProgressCoursesPagination.map((data) => (
                                            <ActivityProgress
                                                progressData={data}
                                                isCourse={true}
                                                key={data.slug}
                                                setRowHeight={setRowHeight}
                                            />
                                        ))}
                                        {inProgressCoursesPagination.length < 1 && (
                                            <h3 className="ct-empty-message">
                                                You are not following any courses
                                            </h3>
                                        )}
                                    </div>
                                    <div className="ct-pagination-text">
                                        {Math.ceil(totalCourseInprogress / pageLength) > 1 && (
                                            <>
                                                <TablePagination
                                                    className="ct-pagination"
                                                    type="minor"
                                                    page={pageCourseInprogress}
                                                    pageLength={pageLength}
                                                    totalRecords={totalCourseInprogress}
                                                    onPageChange={({ page, pageLength }) => {
                                                        location.href =
                                                            "#ct-inprogress-course-title";
                                                        window.location.href =
                                                            "#ct-inprogress-course-title";
                                                        setPageCourseInprogress(page);
                                                        let indexOfLastRow = page * pageLength;
                                                        let indexOfFirstRow =
                                                            indexOfLastRow - pageLength;
                                                        setInProgressCoursesPagination(
                                                            inProgressCourses.slice(
                                                                indexOfFirstRow,
                                                                indexOfLastRow,
                                                            ),
                                                        );
                                                    }}
                                                    prevPageRenderer={() => (
                                                        <FontAwesomeIcon
                                                            icon={faAngleLeft}
                                                            className="icon"
                                                        />
                                                    )}
                                                    nextPageRenderer={() => (
                                                        <FontAwesomeIcon
                                                            icon={faAngleRight}
                                                            className="icon"
                                                        />
                                                    )}
                                                />

                                                <h3>
                                                    {`${pageCourseInprogress} of ${Math.ceil(
                                                        totalCourseInprogress / pageLength,
                                                    )}`}
                                                </h3>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="ct-all-list">
                            <h3 className="ct-empty-message">{LANG.activityEmptyMessage}</h3>
                            <PathLayout maxCardNum={MAX_LIMIT} />
                            <div className="ct-page-align">
                                <a
                                    onClick={() => {
                                        changeHeaderFilterType(NAV_LANG.dropDownList.paths);
                                        replace({
                                            pathname: PATH.search,
                                            state: { rootFilter: "paths" },
                                        });
                                    }}
                                    className="ct-btn-blue ct-btn-view-all"
                                >
                                    <h3>View All Learning Paths</h3>
                                </a>
                            </div>
                            <CourseLayout maxCardNum={MAX_LIMIT} />
                            <div className="ct-page-align">
                                <a
                                    onClick={() => {
                                        changeHeaderFilterType(NAV_LANG.dropDownList.courses);
                                        replace({
                                            pathname: PATH.search,
                                            state: { rootFilter: "courses" },
                                        });
                                    }}
                                    className="ct-btn-blue ct-btn-view-all ct-page-align"
                                >
                                    <h3>View All Courses</h3>
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        userData: state.rootReducer.userData,
    };
};
export default connect(mapStateToProps)(Activity);
