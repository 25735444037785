import { HTTP, SWAL_TYPE, PATH } from "../constant";
import swal from "sweetalert";
import { LANG as CommonLang } from "../lang/Common";

export const ErrorHandler = {
    showError: (title, description, errorCode = null) => {
        return swal({
            title: title,
            text: description,
            icon: SWAL_TYPE.error,
            dangerMode: true,
        }).then((swalStatus) => {
            if (errorCode !== null) {
                window.location = PATH.login;
            }
        });
    },
    showSuccess: (title, description) => {
        return swal(title, description, SWAL_TYPE.success);
    },
    defaultErrorHandlers: {
        [HTTP.RESPONSE.UNAUTHORIZED.CODE]: (error) => {
            ErrorHandler.showError(
                CommonLang.error.unauthorized.title,
                CommonLang.error.unauthorized.description,
                HTTP.RESPONSE.UNAUTHORIZED.CODE,
            );
        },
        [HTTP.RESPONSE.INTERNAL_SERVER_ERROR.CODE]: (error) => {
            ErrorHandler.showError(
                CommonLang.error.internalServerError.title,
                CommonLang.error.internalServerError.description,
            );
        },
        [HTTP.RESPONSE.UNPROCESSABLE_ENTITY.CODE]: (error) => {
            if (error.response.data.errors) {
                let errorList = Object.values(error.response.data.errors);

                if (errorList.length == 1) {
                    ErrorHandler.showError(errorList[0][0], "");
                    return;
                }

                let msg = "";
                errorList.forEach((errorItem) => {
                    msg += errorItem.join("\n") + "\n";
                });

                msg = msg.trim();

                ErrorHandler.showError(
                    "Please fix the following validation errors and try again",
                    msg,
                );
            } else {
                ErrorHandler.showError(error.response.statusText, error.response.data.message);
            }
        },
        default: (error) => {
            ErrorHandler.showError(error.response.statusText, error.response.data.message);
        },
    },
    handle: (error, customHandlers = []) => {
        if (error == undefined) {
            ErrorHandler.showError("Unknown Error", "An unknown error occurred.");
            return;
        } else if (typeof error == "string") {
            ErrorHandler.showError("Unknown Error", error);
            return;
        }

        let handlers = ErrorHandler.defaultErrorHandlers;

        //Override the default handlers with custom error handlers if there's any
        for (const [key, value] of Object.entries(customHandlers)) {
            handlers[key] = value;
        }

        if (error.response) {
        } else {
            ErrorHandler.showError(
                "Network Error",
                "We can not connect to the internet. Please make sure your internet connection is working and try again. ",
            );
            return;
        }

        let errorCode = error.response.status;
        let handler = handlers.hasOwnProperty(errorCode) ? handlers[errorCode] : handlers.default;
        handler(error);
    },
};
