import React from 'react';
import ContentLoader from 'react-content-loader';

function LandingPage({ x, y, width, height }) {
	const pageWidth = window.innerWidth;
	const pageHeight = window.innerHeight;
	return pageWidth < 1200 ? (
		<ContentLoader
			speed={2}
			backgroundColor={'#f6f6f8'}
			foregroundColor={'#ededed'}
			viewBox={`0 -20 ${pageWidth} ${pageHeight - 86}`}>
			<rect x='0' y='0' rx='5' ry='5' width='80%' height='40' />
			<rect x='0' y='60' rx='5' ry='5' width='60%' height='40' />

			<rect x='0' y={pageHeight - 86 - 65 - 25} rx='8' ry='8' width='100%' height='65' />
		</ContentLoader>
	) : (
		<ContentLoader
			speed={2}
			backgroundColor={'#f6f6f8'}
			foregroundColor={'#ededed'}
			viewBox={`0 -250 ${pageWidth} ${pageHeight}`}>
			<rect x='0' y='0' rx='5' ry='5' width='40%' height='40' />
			<rect x='0' y='60' rx='5' ry='5' width='40%' height='40' />
			<rect x='0' y='120' rx='5' ry='5' width='30%' height='40' />
			<rect x='0' y='230' rx='8' ry='8' width='300' height='65' />
		</ContentLoader>
	);
}

export default LandingPage;
