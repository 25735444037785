import React from 'react';
import { useHistory } from 'react-router-dom';

function PageNotFound() {

    let history = useHistory();


	return (
		<div className='ct-page-notfound'>
			<div className='container-fluid'>
				<div className='container'>
					<div className='notfound-404'>
						<h1>404</h1>
						<h2>Oops! This Page Could Not Be Found</h2>
						<h4>
							Sorry but the page you are looking for does not exist, have been
							removed. name changed or is temporarily unavailable
						</h4>
						<a
							className='btn btn-lg btn-info'
							href='/'
							onClick={(e) => {
								e.preventDefault();
								let url = e.target.getAttribute("href");

								history.push({
									pathname: url
								});
							}}
						>
							Go To Homepage
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PageNotFound;
