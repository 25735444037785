import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import SVGAnimation from "./SVGAnimation";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import CourseTile from "./CourseTile";
import { PATH_LANG } from "../lang/LearningPath";
import { LANG as CARD_LANG } from "../lang/Card";
/* import PathLayout from './PathLayout'; */
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { motion } from "framer-motion";
import { PATH_COMPLETE_TEXT, URL, HTTP, PATH } from "../constant";
import http from "../http";
import swal from "sweetalert";
import { ErrorHandler } from "./ErrorHandler";
import { reducerTypes } from "../reducerTypes";
import LoadingIndicator from "./LoadingIndicator";
import PathPage from "./placeholders/PathPage";
import { List } from "react-content-loader";
import Sequence from "./utils/Sequence";

function LearningPath({ paths, courses, enrolledPath, isLogin, userCourses }) {
    const [path, setPath] = useState({});
    const [pathCourses, setPathCourses] = useState([]);
    const [area, setArea] = useState(100);
    const [status, setStatus] = useState(CARD_LANG.STATUS.ENROL);
    const [countProgress, setCount] = useState(0);
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    let cardArea = useRef();
    let { location, replace, push } = useHistory();

    const variants = {
        visible: {
            opacity: 1,
        },
        hidden: {
            opacity: 0.3,
            transitionEnd: {
                display: "none",
            },
        },
    };

    useEffect(() => {
        if (paths.length > 0) {
            setCount(0);
        } else {
            setCount(1);
        }
        const slugMain =
            location.state && location.state.slug
                ? location.state.slug
                : location.pathname.split("/").pop();
        let currentPath = paths.filter((path) => path.slug === slugMain);
        setPath(currentPath[0]);
        currentPath[0] &&
            setStatus(
                currentPath[0].completed_percentage !== undefined
                    ? currentPath[0].is_completed
                        ? CARD_LANG.STATUS.VIEW
                        : CARD_LANG.STATUS.RESUME
                    : CARD_LANG.STATUS.ENROL,
            );
        /* let currentCourses = courses.filter((course) => {
            let isMatch = false;
            course.learning_paths.map((path) => {
                if (path.slug === slugMain) {
                    isMatch = true;
                }
            });
            return isMatch;
        }); */
        try {
            let currentCourses = [];
            currentPath[0].courses.map((cPathCourse) => {
                let findedCourse = courses.find((course) => course.slug === cPathCourse.slug);
                findedCourse !== undefined && currentCourses.push(findedCourse);
            });

            setPathCourses(currentCourses);
        } catch (error) {
            setPathCourses([]);
        }
    }, [location.pathname, paths]);

    useEffect(() => {
        if (!cardArea.current) return;
        const width = cardArea.current.getBoundingClientRect().width;
        let adjestWidth = width >= 1200 ? width - 460 : width >= 992 ? width - 380 : width - 327;
        setArea(adjestWidth);
    });

    useEffect(() => {
        if (location.hash) window.location.href = location.hash;
    }, []);

    const getThumbnailWithAnimation = (isAnim) => {
        return isAnim ? (
            <motion.div
                initial={{ x: `${area}px`, y: 0, scale: 1 }}
                animate={{ x: 0, y: 0, scale: 1 }}
                transition={{ ease: "linear", duration: 1, delay: 2 }}
                className="ct-path-spec-thumbnail"
                style={{
                    backgroundImage: `url(${path.path_image})`,
                }}
            ></motion.div>
        ) : (
            <motion.div
                initial={{ x: 0, y: 0, scale: 1 }}
                className="ct-path-spec-thumbnail"
                style={{
                    backgroundImage: `url(${path.path_image})`,
                }}
            ></motion.div>
        );
    };
    const getThumbnailWithoutAnimation = () => {
        return (
            <div
                className="ct-path-spec-thumbnail"
                style={{
                    backgroundImage: `url(${path.path_image})`,
                }}
            ></div>
        );
    };
    const pathAction = () => {
        if (!isLogin) {
            swal({
                title: "Please Sign Up",
                text: `Only registered students are allowed to enrol in a learning path. Please create an account if you are not already registered. `,
                icon: "warning",
                buttons: {
                    close: {
                        text: "Close",
                        value: "close",
                    },
                    confirm: {
                        text: "Sign Up",
                        value: "confirm",
                    },
                },
                dangerMode: true,
            }).then((swalStatus) => {
                if (swalStatus === "confirm") {
                    push({
                        pathname: PATH.createAccount,
                        state: { url: `${PATH.learningPath}${path.slug}` },
                    });
                }
            });
        } else {
            switch (status) {
                case CARD_LANG.STATUS.ENROL:
                    let url = URL.getEnrolled.replace(new RegExp(CARD_LANG.PATH_SLUG), path.slug);
                    setCount((prevCount) => prevCount + 1);
                    const promise = http(HTTP.METHOD.POST, url);
                    promise.then(
                        (response) => {
                            const obj = {
                                slug: path.slug,
                                completed_percentage: response.data.completed_percentage,
                                is_completed: response.data.completed_percentage === 100,
                                last_viewed_lesson_slug: null,
                                last_viewed_course_slug: null,
                            };
                            enrolledPath(obj, pathCourses);
                            setCount((prevCount) => prevCount > 0 && prevCount - 1);
                            swal({
                                title: `Enrolled`,
                                text: `You have successfully enrolled in ${path.title}`,
                                icon: "success",
                                buttons: {
                                    confirm: {
                                        text: "OK",
                                        value: "confirm",
                                    },
                                },
                                dangerMode: true,
                            }).then((swalStatus) => {
                                setCount(0);
                                //push({ pathname: PATH.course + pathCourses[0].slug });
                                window.location.href = "#ct-course-included";
                            });
                        },
                        (err) => {
                            setCount(0);
                            ErrorHandler.handle(err);
                        },
                    );
                    break;
                case CARD_LANG.STATUS.RESUME:
                    const viewedCourse = userCourses.find(
                        (course) => course.slug === path.last_viewed_course_slug,
                    );
                    if (path.last_viewed_course_slug === null || viewedCourse.is_completed) {
                        /* replace(location.pathname + '/#ct-course-included', {
							...location.state,
						}); */
                        //push({ pathname: PATH.course + pathCourses[0].slug });
                        window.location.href = "#ct-course-included";
                    } else {
                        let pathUrl = `${PATH.watch}/${path.slug}/${path.last_viewed_course_slug}`;
                        push({
                            pathname: pathUrl,
                            state: {
                                pathSlug: path.slug,
                                courseSlug: path.last_viewed_course_slug,
                                lessonSlug: path.last_viewed_lesson_slug,
                            },
                        });
                    }
                    break;
            }
        }
    };

    const dateFormating = (n) => {
        return n > 0 ? ["th", "st", "nd", "rd"][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : "";
    };
    const getTime = () => {
        let totalHours = Math.floor(path.duration_in_minutes / 60);
        let totalMinutes = path.duration_in_minutes % 60;
        let hour = totalHours !== 0 ? `${totalHours}h ` : "";
        let minutes = totalMinutes > 9 ? `${totalMinutes}m` : `0${totalMinutes}m`;
        return hour + minutes;
    };

    return (
        <div className="ct-path">
            <LoadingIndicator loaderCount={countProgress} />
            {path ? (
                <div className="container-fluid ct-container-fluid">
                    <div className="ct-path-placeholder">
                        <div className="container ct-container ct-champ-icon" ref={cardArea}>
                            <div className="ct-path-spec-details">
                                <div>
                                    <div className="ct-heading">
                                        <h1>{path.title}</h1>
                                        {status === CARD_LANG.STATUS.VIEW && (
                                            <img src="/images/award.svg" />
                                        )}
                                        {status === CARD_LANG.STATUS.RESUME && (
                                            <CircularProgressbar
                                                value={
                                                    path.completed_percentage !== undefined
                                                        ? parseInt(path.completed_percentage)
                                                        : 0
                                                }
                                                text={`${
                                                    path.completed_percentage !== undefined
                                                        ? parseInt(path.completed_percentage)
                                                        : 0
                                                }%`}
                                                strokeWidth={12}
                                            />
                                        )}
                                    </div>

                                    {status === CARD_LANG.STATUS.VIEW &&
                                        getThumbnailWithAnimation(
                                            location.state ? location.state.isAnimate : false,
                                        )}
                                    <h3 className="ct-excerpt">{path.short_description}</h3>
                                    <div className="ct-mob-view-thumb">
                                        {getThumbnailWithoutAnimation()}
                                    </div>
                                </div>
                                <div>
                                    <h3 className="ct-duration">
                                        <i className="fa fa-clock-o ct-clock-icon"></i>
                                        {getTime()}
                                    </h3>
                                    {status !== CARD_LANG.STATUS.VIEW && (
                                        <button
                                            onClick={pathAction}
                                            className={`ct-btn-blue ct-progress-status`}
                                        >
                                            {status}
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="ct-date-container">
                                {status === CARD_LANG.STATUS.VIEW && (
                                    <SVGAnimation
                                        isAnim={location.state ? location.state.isAnimate : false}
                                    />
                                )}
                                {status === CARD_LANG.STATUS.VIEW &&
                                    (location.state ? location.state.isAnimate : false) && (
                                        <motion.div
                                            initial="visible"
                                            animate="hidden"
                                            variants={variants}
                                            transition={{
                                                easeOut: "linear",
                                                duration: 0.6,
                                                delay: 2.3,
                                            }}
                                            className="ct-black-background"
                                        >
                                            <motion.h1
                                                initial="visible"
                                                animate="hidden"
                                                variants={variants}
                                                transition={{
                                                    easeOut: "linear",
                                                    duration: 0.001,
                                                    delay: 2.3,
                                                }}
                                            >
                                                {PATH_COMPLETE_TEXT}
                                            </motion.h1>
                                            <motion.img
                                                initial={{ opacity: 1 }}
                                                animate={{ opacity: 0 }}
                                                transition={{
                                                    easeOut: "linear",
                                                    duration: 0.001,
                                                    delay: 2.3,
                                                }}
                                                src={PATH_LANG.tropyBase64}
                                                alt=""
                                            />
                                        </motion.div>
                                    )}
                                {status !== CARD_LANG.STATUS.VIEW && getThumbnailWithoutAnimation()}
                                {status === CARD_LANG.STATUS.VIEW && (
                                    <h4 className="ct-earned-date">
                                        {PATH_LANG.quilifyDatePhrase +
                                            " " +
                                            path.completed_on.split("-")[2]}
                                        <sup>
                                            {dateFormating(path.completed_on.split("-")[2]) + " "}
                                        </sup>

                                        {monthNames[parseInt(path.completed_on.split("-")[1]) - 1] +
                                            " "}
                                        {path.completed_on.split("-")[0]}
                                    </h4>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="container ct-container ">
                        <div
                            className="ct-full-desc"
                            id="ct-course-included"
                            dangerouslySetInnerHTML={{ __html: path.full_description }}
                        ></div>
                    </div>
                    <div className="container-fluid ct-path-background">
                        {/*  <h1 className="ct-title">{PATH_LANG.courseTitle}</h1> */}
                        <div id="ct-path-course-container">
                            <div
                                className="container ct-container"
                                style={{ position: "relative" }}
                            >
                                <div className="row ct-row">
                                    {pathCourses.map((course, index) => {
                                        return (
                                            <CourseTile
                                                key={course.slug}
                                                card={course}
                                                isShowSequenceNumber={true}
                                                sequenceNumber={index + 1}
                                            />
                                        );
                                    })}
                                </div>
                                <Sequence />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container-fluid ct-container-fluid">
                    <div className="ct-path-placeholder" style={{ backgroundColor: "#fff" }}>
                        <div className="container ct-container ct-champ-icon">
                            <PathPage />
                        </div>
                        <div className="container ct-container ct-champ-icon">
                            <List
                                style={{
                                    marginBottom: window.innerWidth < 768 ? 50 : 120,
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
// ${
// 	path.last_viewed_course_slug === null
// 		? 'ct-btn-default ct-text-dark'
// 		: 'ct-btn-blue'
// }
const mapStateToProps = (state) => {
    return {
        paths: state.rootReducer.learningPaths,
        courses: state.rootReducer.courses,
        isLogin: state.rootReducer.isLogin,
        userCourses: state.rootReducer.userData.courses,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        enrolledPath: (obj, includeCourses) => {
            dispatch({ type: reducerTypes.ENROLLED_PATH, param: obj, includeCourses });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LearningPath);
