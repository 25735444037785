import React, { useLayoutEffect, useEffect, useState } from "react";
import "./styles/css/App.css";
import "./styles/css/Responsive.css";
import NavigationBar from "./components/NavigationBar";
import LandingPage from "./components/LandingPage";
import PageNotFound from "./components/PageNotFound";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { PATH } from "./constant";
import Footer from "./components/Footer";
import SearchResult from "./components/SearchResult";
import CreateAccount from "./components/CreateAccount";
import SignIn from "./components/SignIn";
import Activity from "./components/Activity";
import { connect } from "react-redux";
import ProtectedRoute from "./components/ProtectedRoute";
import ScrollToTop from "./components/ScrollToTop";
import LearningPath from "./components/LearningPath";
import PlayerLayout from "./components/PlayerLayout";
import Courses from "./components/Courses";
import Profile from "./components/Profile";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import ChangePassword from "./components/ChangePassword";
import { loadReCaptcha } from "react-recaptcha-v3";

function App({ isLogin }) {
    const [filterType, setChangeFilterType] = useState("All");
    useLayoutEffect(() => {
        //window.scrollTo(0, 0);
    });

    useEffect(() => {
        loadReCaptcha("6LdbaasZAAAAALHvD_Yi4wjjqtuG0_NehIFpPmKw", function () {});
    }, []);
    const changeHeaderFilterType = (type) => {
        setChangeFilterType(type);
    };
    return (
        <BrowserRouter>
            <div className="App">
                <NavigationBar
                    filterType={filterType}
                    changeHeaderFilterType={changeHeaderFilterType}
                />
                <Switch>
                    <ProtectedRoute
                        exact
                        path={PATH.changePassword}
                        component={() => <ChangePassword isLogin={isLogin} />}
                    />
                    <Route
                        exact
                        path={PATH.resetPassword}
                        component={() => <ResetPassword isLogin={isLogin} />}
                    />
                    <Route
                        exact
                        path={PATH.forgotPassword}
                        component={() => <ForgotPassword isLogin={isLogin} />}
                    />
                    <ProtectedRoute
                        path={PATH.profile}
                        component={() => (
                            <Profile
                                isLogin={isLogin}
                                changeHeaderFilterType={changeHeaderFilterType}
                            />
                        )}
                    />
                    <Route path={PATH.createAccount} component={() => <CreateAccount />} />
                    <Route exact path={PATH.login} component={() => <SignIn isLogin={isLogin} />} />
                    <Route
                        exact
                        path={PATH.home}
                        component={() => (
                            <LandingPage changeHeaderFilterType={changeHeaderFilterType} />
                        )}
                    />
                    <Route
                        exact
                        path={PATH.search}
                        component={() => (
                            <SearchResult changeHeaderFilterType={changeHeaderFilterType} />
                        )}
                    />
                    <ProtectedRoute
                        exact
                        path={PATH.activity + "/:name"}
                        isLogin={isLogin}
                        component={() => <Activity />}
                    />
                    <Route
                        exact
                        path={PATH.watch + "/:courseSlug"}
                        component={() => <PlayerLayout isPath={false} />}
                    />
                    <Route path="/course/:slug" component={Courses} />
                    <Route
                        exact
                        path={PATH.watch + "/:pathSlug" + "/:courseSlug"}
                        component={() => <PlayerLayout isPath={true} />}
                    />
                    <Route path={PATH.learningPath + ":slug"} component={() => <LearningPath />} />
                    <Route path="*" component={PageNotFound} />
                </Switch>
                <Footer changeHeaderFilterType={changeHeaderFilterType} />
                <ScrollToTop />
            </div>
        </BrowserRouter>
    );
}
const mapStateToProps = (state) => {
    return {
        isLogin: state.rootReducer.isLogin,
    };
};
export default connect(mapStateToProps)(App);
