import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { faSearch, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LANG } from "../lang/NavigationBar";
import { LANG as COMMON_LANG } from "../lang/Common";
import { PATH, HTTP, URL } from "../constant";
import { connect } from "react-redux";
import http from "../http";
import { reducerTypes } from "../reducerTypes";
import setAuthorizationToken from "../setAuthorizationToken";
import LoadingIndicator from "./LoadingIndicator";
import { ErrorHandler } from "./ErrorHandler";
import { getImage } from "./blocks/AvatarSelector";

function NavigationBar({ isLogin, userData, sessionHandle, filterType, changeHeaderFilterType }) {
    const [searchKey, setSearchKey] = useState("");
    const [loginStatus, setLoginStatus] = useState(false);
    const [count, setCount] = useState(0);
    const [profileImageURL, setProfileImageURL] = useState(COMMON_LANG.defaultProfileImage);
    const [action, setAction] = useState("");
    const [searchFiltervalue, setSearchFilterValue] = useState(LANG.dropDownList.all);
    const [toggleIcon, setToggleIcon] = useState("");
    const history = useHistory();

    useEffect(() => {
        setLoginStatus(isLogin);
        if (!isLogin) {
            setTimeout(() => setCount(0), 1000);
        }
        let img = getImage(userData.profile_image_url);
        setProfileImageURL(img ? img.src : COMMON_LANG.defaultProfileImage);
    }, [isLogin, userData]);

    useEffect(() => {
        let pathName = history.location.pathname.split("/")[1];
        if (pathName === PATH.activity.substr(1)) {
            setAction("activity");
        } else if (pathName === "") {
            setAction("");
        } else {
            setAction("");
        }
    }, [history.location.pathname]);

    useEffect(() => {
        setSearchFilterValue(filterType);
        if (history.location.pathname === PATH.search) {
            history.replace({
                pathname: PATH.search,
                state: {
                    ...history.location.state,
                    searchKey,
                    filterValue: filterType,
                    rootFilter: null,
                },
            });
        }
    }, [filterType]);

    const goHome = () => {
        if (history.location.pathname !== PATH.home) {
            changeHeaderFilterType(LANG.dropDownList.all);
            history.push(PATH.home);
            setSearchKey("");
        }
        setToggleIcon("");
    };
    const searchBy = (event) => {
        if (searchKey === null || searchKey.trim() === "") return;
        if (event === "CLICKED") {
            toggleNavBar();
            history.push({
                pathname: PATH.search,
                state: { searchKey: searchKey.trim(), filterValue: searchFiltervalue },
            });

            return;
        }
        if ((event.which || event.keyCode) === 13) {
            toggleNavBar();
            history.push({
                pathname: PATH.search,
                state: { searchKey: searchKey.trim(), filterValue: searchFiltervalue },
            });
        }
    };

    const goTo = (URL) => {
        toggleNavBar();
        history.push({
            pathname: URL,
        });
    };

    const changeFilterValue = (value) => {
        changeHeaderFilterType(value);
        setSearchFilterValue(value);
        if (history.location.pathname === PATH.search) {
            history.replace({
                pathname: PATH.search,
                state: {
                    ...history.location.state,
                    searchKey,
                    filterValue: value,
                    rootFilter: null,
                },
            });
        }
    };
    const toggleNavBar = () => {
        if (toggleIcon === "") {
            setToggleIcon("change");
        } else {
            setToggleIcon("");
        }
    };
    return (
        <div className="ct-header-container">
            <LoadingIndicator loaderCount={count} />
            <div className="container-fluid ct-container-fluid">
                <div className="container ct-container">
                    <div className="ct-header">
                        <div className="ct-logo-container">
                            <div className="ct-icon" onClick={toggleNavBar}>
                                <div className={`ct-close ${toggleIcon}`}></div>
                                <div className={`ct-close ${toggleIcon}`}></div>
                                <div className={`ct-close ${toggleIcon}`}></div>
                            </div>
                            <div
                                className="ct-logo-header"
                                onClick={() => {
                                    goHome();
                                }}
                            ></div>
                        </div>
                        <div className="ct-navigation-drawer">
                            <div className="ct-header-content">
                                <div className="ct-search-container">
                                    <div className="input-group">
                                        <div className="input-group-prepend ct-input-group-prepend">
                                            <button
                                                className="btn btn-outline-secondary dropdown-toggle"
                                                type="button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                {searchFiltervalue}
                                            </button>
                                            <div className="dropdown-menu">
                                                <Link
                                                    className={`dropdown-item ${
                                                        LANG.dropDownList.all === searchFiltervalue
                                                            ? "ct-drop-filter-active"
                                                            : ""
                                                    }`}
                                                    onClick={() => {
                                                        changeFilterValue(LANG.dropDownList.all);
                                                    }}
                                                >
                                                    {LANG.dropDownList.all}
                                                </Link>
                                                <div
                                                    role="separator"
                                                    className="dropdown-divider"
                                                ></div>
                                                <Link
                                                    className={`dropdown-item ${
                                                        LANG.dropDownList.paths ===
                                                        searchFiltervalue
                                                            ? "ct-drop-filter-active"
                                                            : ""
                                                    }`}
                                                    onClick={() => {
                                                        changeFilterValue(LANG.dropDownList.paths);
                                                    }}
                                                >
                                                    {LANG.dropDownList.paths}
                                                </Link>
                                                <div
                                                    role="separator"
                                                    className="dropdown-divider"
                                                ></div>
                                                <Link
                                                    className={`dropdown-item ${
                                                        LANG.dropDownList.courses ===
                                                        searchFiltervalue
                                                            ? "ct-drop-filter-active"
                                                            : ""
                                                    }`}
                                                    onClick={() => {
                                                        changeFilterValue(
                                                            LANG.dropDownList.courses,
                                                        );
                                                    }}
                                                >
                                                    {LANG.dropDownList.courses}
                                                </Link>
                                            </div>
                                        </div>
                                        <input
                                            type="text"
                                            placeholder={
                                                LANG.dropDownList.all === searchFiltervalue
                                                    ? LANG.searchPlaceholderAll
                                                    : LANG.dropDownList.paths === searchFiltervalue
                                                    ? LANG.searchPlaceholderPaths
                                                    : LANG.searchPlaceholderCourses
                                            }
                                            value={searchKey}
                                            className="form-control ct-input"
                                            onChange={(e) => setSearchKey(e.target.value)}
                                            onKeyUp={(e) => searchBy(e)}
                                        />
                                        <div
                                            className="input-group-append ct-input-group-append"
                                            onClick={() => searchBy("CLICKED")}
                                        >
                                            <span className="input-group-text">
                                                <FontAwesomeIcon icon={faSearch} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <ul className="ct-ul">
                                    {LANG.links.map((link) =>
                                        link.id === LANG.linkTypes.signIn ? (
                                            !loginStatus && (
                                                <li
                                                    key={link.id}
                                                    className="ct-link"
                                                    onClick={() => {
                                                        setSearchKey("");
                                                        history.push(link.actionLink);
                                                    }}
                                                >
                                                    <Link>{link.title}</Link>
                                                </li>
                                            )
                                        ) : link.id === LANG.linkTypes.learning ? (
                                            !loginStatus && (
                                                <li
                                                    key={link.id}
                                                    className={`ct-link ct-button ${
                                                        action === link.title ? "ct-active" : ""
                                                    }`}
                                                    onClick={() => {
                                                        setSearchKey("");

                                                        setAction(link.title);
                                                        history.push(link.actionLink);
                                                    }}
                                                >
                                                    <Link>{link.title}</Link>
                                                </li>
                                            )
                                        ) : (
                                            <li
                                                key={link.id}
                                                className={`ct-link ${
                                                    action === link.title ? "ct-active" : ""
                                                }`}
                                                onClick={() => {
                                                    setSearchKey("");

                                                    setAction(link.title);
                                                    changeHeaderFilterType(
                                                        link.actionLink.state.filter,
                                                    );
                                                    history.push(link.actionLink);
                                                }}
                                            >
                                                <Link>{link.title}</Link>
                                            </li>
                                        ),
                                    )}
                                    <li
                                        onClick={() => {
                                            setSearchKey("");
                                        }}
                                    >
                                        {loginStatus && (
                                            <div>
                                                <div className="dropdown">
                                                    <div
                                                        className="ct-profile"
                                                        data-toggle="dropdown"
                                                    >
                                                        <h3
                                                            className={`ct-user-name ${
                                                                action === "activity"
                                                                    ? "ct-active"
                                                                    : ""
                                                            }`}
                                                        >
                                                            {userData.first_name}
                                                        </h3>
                                                        <div className="ct-toggle-area">
                                                            <div className="ct-profile-container">
                                                                <img
                                                                    src={profileImageURL}
                                                                    alt="User avatar image"
                                                                />
                                                            </div>
                                                            <button className="btn btn-default ct-btn-setting">
                                                                <FontAwesomeIcon
                                                                    icon={faCaretDown}
                                                                />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="dropdown-menu ct-dropdown-menu"
                                                        aria-labelledby="dropdown-menu"
                                                    >
                                                        <Link
                                                            className="dropdown-item ct-dropdown-item"
                                                            type="button"
                                                            to={`${PATH.profile}`}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                goTo(PATH.profile);
                                                            }}
                                                        >
                                                            <h3>{LANG.dropDownTitle.myProfile}</h3>
                                                        </Link>
                                                        <button
                                                            className="dropdown-item ct-dropdown-item"
                                                            type="button"
                                                            onClick={() => {
                                                                setCount(
                                                                    (prevCount) => prevCount + 1,
                                                                );
                                                                sessionHandle();
                                                                history.replace({
                                                                    pathname: PATH.home,
                                                                });
                                                            }}
                                                        >
                                                            <h3>{LANG.dropDownTitle.signOut}</h3>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className={`ct-mobile-navigation ${
                                toggleIcon === "change" ? "ct-ful-width" : ""
                            }`}
                        >
                            <div className="ct-search-container">
                                <div className="input-group">
                                    <div className="input-group-prepend ct-input-group-prepend">
                                        <button
                                            className="btn btn-outline-secondary dropdown-toggle"
                                            type="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            {searchFiltervalue}
                                        </button>
                                        <div className="dropdown-menu">
                                            <Link
                                                className={`dropdown-item ${
                                                    LANG.dropDownList.all === searchFiltervalue
                                                        ? "ct-drop-filter-active"
                                                        : ""
                                                }`}
                                                onClick={() => {
                                                    changeFilterValue(LANG.dropDownList.all);
                                                }}
                                            >
                                                {LANG.dropDownList.all}
                                            </Link>
                                            <div
                                                role="separator"
                                                className="dropdown-divider"
                                            ></div>
                                            <Link
                                                className={`dropdown-item ${
                                                    LANG.dropDownList.paths === searchFiltervalue
                                                        ? "ct-drop-filter-active"
                                                        : ""
                                                }`}
                                                onClick={() => {
                                                    changeFilterValue(LANG.dropDownList.paths);
                                                }}
                                            >
                                                {LANG.dropDownList.paths}
                                            </Link>
                                            <div
                                                role="separator"
                                                className="dropdown-divider"
                                            ></div>
                                            <Link
                                                className={`dropdown-item ${
                                                    LANG.dropDownList.courses === searchFiltervalue
                                                        ? "ct-drop-filter-active"
                                                        : ""
                                                }`}
                                                onClick={() => {
                                                    changeFilterValue(LANG.dropDownList.courses);
                                                }}
                                            >
                                                {LANG.dropDownList.courses}
                                            </Link>
                                        </div>
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="I am looking for..."
                                        value={searchKey}
                                        className="form-control ct-input"
                                        onChange={(e) => setSearchKey(e.target.value)}
                                        onKeyUp={(e) => searchBy(e)}
                                    />
                                    <div
                                        className="input-group-append ct-input-group-append"
                                        onClick={() => searchBy("CLICKED")}
                                    >
                                        <span className="input-group-text">
                                            <FontAwesomeIcon icon={faSearch} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="ct-mob-route-content">
                                <ul className="ct-ul">
                                    <li
                                        className="ct-link"
                                        onClick={() => {
                                            setSearchKey("");
                                            changeHeaderFilterType(
                                                LANG.links[0].actionLink.state.filter,
                                            );
                                            toggleNavBar();
                                            history.push(LANG.links[0].actionLink);
                                        }}
                                    >
                                        <Link>{LANG.links[0].title}</Link>
                                    </li>
                                    <li
                                        className="ct-link"
                                        onClick={() => {
                                            setSearchKey("");
                                            changeHeaderFilterType(
                                                LANG.links[1].actionLink.state.filter,
                                            );
                                            toggleNavBar();
                                            history.push(LANG.links[1].actionLink);
                                        }}
                                    >
                                        <Link>{LANG.links[1].title}</Link>
                                    </li>
                                    {!loginStatus && (
                                        <li
                                            className="ct-link"
                                            onClick={() => {
                                                setSearchKey("");
                                                toggleNavBar();
                                                history.push(LANG.links[2].actionLink);
                                            }}
                                        >
                                            <Link>{LANG.links[2].title}</Link>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <div className="ct-mob-bottom-content">
                                <ul className="ct-ul">
                                    {loginStatus && (
                                        <li
                                            onClick={() => {
                                                setSearchKey("");
                                                goTo(PATH.profile);
                                            }}
                                        >
                                            <Link to={`${PATH.profile}`}>
                                                <div>
                                                    <div className="ct-profile">
                                                        <div className="ct-toggle-area">
                                                            <div className="ct-profile-container">
                                                                <img
                                                                    src={profileImageURL}
                                                                    alt="User avatar image"
                                                                />
                                                            </div>
                                                        </div>
                                                        <h3 className="ct-user-name">
                                                            {userData.first_name + "'s Profile"}
                                                        </h3>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    )}
                                    {loginStatus && (
                                        <li
                                            onClick={() => {
                                                setCount((prevCount) => prevCount + 1);
                                                sessionHandle();
                                                toggleNavBar();
                                                history.replace({
                                                    pathname: PATH.home,
                                                });
                                            }}
                                        >
                                            <Link className="ct-sign-out">
                                                {LANG.dropDownTitle.signOut}
                                            </Link>
                                        </li>
                                    )}
                                    {loginStatus && <li></li>}
                                    {!loginStatus && (
                                        <li
                                            className="ct-link ct-button"
                                            onClick={() => {
                                                setSearchKey("");
                                                toggleNavBar();
                                                history.push(LANG.links[3].actionLink);
                                            }}
                                        >
                                            <Link>{LANG.links[3].title}</Link>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        isLogin: state.rootReducer.isLogin,
        userData: state.rootReducer.userData,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        sessionHandle: () => {
            const promiseLogout = http(HTTP.METHOD.POST, URL.auth.logout);
            promiseLogout.then(
                (response) => {
                    try {
                        localStorage.clear();
                        setAuthorizationToken(null);
                        dispatch({
                            type: reducerTypes.SESSION_HANDLE,
                            param: false,
                        });
                        const promise = http(HTTP.METHOD.GET, URL.home);
                        promise.then(
                            (response) => {
                                try {
                                    dispatch({
                                        type: reducerTypes.HOME_DATA,
                                        homeData: response.data,
                                    });
                                } catch (error) {
                                    ErrorHandler.showError(
                                        COMMON_LANG.error.general.title,
                                        error.toString(),
                                    );
                                }
                            },
                            (err) => {
                                ErrorHandler.handle(err);
                            },
                        );
                    } catch (error) {
                        ErrorHandler.showError(COMMON_LANG.error.general.title, error);
                    }
                },
                (err) => {
                    ErrorHandler.handle(err);
                },
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
