import React, { useEffect, useState } from 'react';
import Input from './Input';
import ValidationError from './ValidationError';

function FormInputCustom(params) {
    const Class = params.Class ? params.Class : 'padding-right-15 form-group';
    const Label = params.Label ? params.Label : '';
    const ID = params.ID ? params.ID : null;

    const Description = params.Description ? params.Description : null;
    const DescriptorID = Description ? ID + "Help" : null;
    const Descriptor = Description ?
        <small id={DescriptorID} className="form-text text-muted">{Description}</small> :
        null;

    let Error = params.Error ? params.Error : null;

    return (
        <div className={Class}>
            <label htmlFor={ID}>{Label}</label>
            {params.Component}
            <ValidationError Error={Error} />
            {Descriptor}
        </div>
    )
}

export default FormInputCustom;