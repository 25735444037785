import React from 'react';
import Input from './Input';
import ValidationError from './ValidationError';

function FormInput(params) {
    const Class = params.Class ? params.Class : 'form-group';
    const Label = params.Label ? params.Label : '';
    const ID = params.ID ? params.ID : null;
    const Type = params.Type ? params.Type : 'text';
    const Placeholder = params.Placeholder ? params.Placeholder : null;

    const InputClass = params.InputClass ? params.InputClass : null;

    const Description = params.Description ? params.Description : null;
    const DescriptorID = Description ? ID + "Help" : null;
    const Descriptor = Description ?
        <small id={ID} className="form-text text-muted">{Description}</small> :
        null;

    let Error = params.Error ? params.Error : null;

    return (
        <div className={Class}>
            <label htmlFor={ID}>{Label}</label>
            <Input
                Class={InputClass}
                Type={Type}
                ID={ID}
                Placeholder={Placeholder}
                Value={params.Value}
                Setter={params.Setter}
                AriaDescribedBy={DescriptorID}
            />
            <ValidationError Error={Error} />
            {Descriptor}
        </div>
    )
}

export default FormInput;