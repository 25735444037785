import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import LoadingIndicator from "./LoadingIndicator";
import { LANG } from "../lang/SearchResult";
import { LANG as LAND_LANG } from "../lang/LandingPage";
import { LANG as NAV_LANG } from "../lang/NavigationBar";
import { LANG as COMMON_LANG } from "../lang/Common";
import PathTile from "./PathTile";
import CourseTile from "./CourseTile";
import { connect } from "react-redux";
import http from "../http";
import { HTTP, URL, PATH, TAG } from "../constant";
import { ErrorHandler } from "./ErrorHandler";
import { reducerTypes } from "../reducerTypes";
import PlaceHolder from "./PlaceHolder";
import PathLayout from "./PathLayout";
import CourseLayout from "./CourseLayout";

function SearchResult({ learningPaths, courses, addHomeData, changeHeaderFilterType }) {
    const [searchkey, setSearchkey] = useState("");
    const [count, setCount] = useState(0);
    const [searchPathList, setSearchPathList] = useState([]);
    const [searchCourseList, setSearchCourseList] = useState([]);
    const [isEmpty, setIsEmpty] = useState(false);
    const [filterValue, setFilterValue] = useState(NAV_LANG.dropDownList.all);
    const [rootFilter, setRootFilter] = useState(null);
    const location = useLocation();
    const { replace } = useHistory();

    useEffect(() => {
        setIsEmpty(false);
        setSearchkey(location.state && location.state.searchKey ? location.state.searchKey : "");
        let findFilter =
            location.state && location.state.filterValue
                ? location.state.filterValue
                : NAV_LANG.dropDownList.all;
        setFilterValue(findFilter);
        //if (rootFilter !== null) return;
        setCount((prevProp) => prevProp + 1);
        const promise = http(
            HTTP.METHOD.GET,
            URL.search +
                (location.state && location.state.searchKey ? location.state.searchKey : "") +
                "&type=" +
                findFilter,
        );
        promise.then(
            (response) => {
                try {
                    let courseList = [];
                    let pathList = [];
                    let missedCourseSlugs = [];
                    let missedPathSlugs = [];
                    if (courses.length > 0) {
                        missedCourseSlugs = response.data.courses.filter((slug) => {
                            let isMatch = false;
                            courses.map((course) => {
                                if (slug.slug === course.slug) {
                                    isMatch = true;
                                    courseList = [...courseList, course];
                                }
                            });
                            return !isMatch;
                        });
                    }
                    missedCourseSlugs = missedCourseSlugs.map((slug) => slug.slug);
                    if (learningPaths.length > 0) {
                        missedPathSlugs = response.data.learning_paths.filter((slug) => {
                            let isMatch = false;
                            learningPaths.map((path) => {
                                if (slug.slug === path.slug) {
                                    isMatch = true;
                                    pathList = [...pathList, path];
                                }
                            });
                            return !isMatch;
                        });
                    }
                    missedPathSlugs = missedPathSlugs.map((slug) => slug.slug);
                    if (missedCourseSlugs.length > 0) {
                        const promiseSlugs = http(
                            HTTP.METHOD.GET,
                            URL.getMultipleCourses + missedCourseSlugs.join(),
                        );
                        promiseSlugs.then(
                            (responseSlugs) => {
                                addHomeData({ data: responseSlugs.data, isCourseData: true });
                            },
                            (err) => {
                                setCount(0);
                                ErrorHandler.handle(err);
                            },
                        );
                    }
                    if (missedPathSlugs.length > 0) {
                        const promiseSlugs = http(
                            HTTP.METHOD.GET,
                            URL.getMultiplePaths + missedPathSlugs.join(),
                        );
                        promiseSlugs.then(
                            (responseSlugs) => {
                                addHomeData({ data: responseSlugs.data, isCourseData: false });
                            },
                            (err) => {
                                setCount(0);
                                ErrorHandler.handle(err);
                            },
                        );
                    }
                    setSearchCourseList(courseList);
                    setSearchPathList(pathList);
                    setIsEmpty(courseList.length < 1 && pathList.length < 1);
                    setCount((prevProp) => {
                        return prevProp > 0 && prevProp - 1;
                    });
                } catch (error) {
                    setCount(0);
                    ErrorHandler.showError(COMMON_LANG.error.general.title, error.toString());
                }
            },
            (err) => {
                setCount(0);
                ErrorHandler.handle(err);
            },
        );
    }, [location, courses.length]);

    useEffect(() => {
        setRootFilter(
            location.state && location.state.rootFilter ? location.state.rootFilter : null,
        );
    });
    useEffect(() => {
        if (document.getElementById(location.hash)) {
            document.getElementById(location.hash !== "").scrollIntoView();
        }
    }, []);
    return (
        <div className="ct-search-page">
            <LoadingIndicator loaderCount={count} />
            <div className="container-fluid ct-container-fluid">
                <PlaceHolder
                    title={
                        rootFilter === null && searchkey !== ""
                            ? filterValue === NAV_LANG.dropDownList.all
                                ? LANG.heroTitleAll.replace(new RegExp("{replace-text}"), searchkey)
                                : filterValue === NAV_LANG.dropDownList.paths
                                ? LANG.heroTitlePaths.replace(
                                      new RegExp("{replace-text}"),
                                      searchkey,
                                  )
                                : LANG.heroTitleCourses.replace(
                                      new RegExp("{replace-text}"),
                                      searchkey,
                                  )
                            : rootFilter === "paths"
                            ? LANG.heroTitleAllPaths
                            : rootFilter === "courses"
                            ? LANG.heroTitleAllCourses
                            : filterValue === NAV_LANG.dropDownList.all
                            ? LANG.heroTitleAll.replace(new RegExp("{replace-text}"), searchkey)
                            : filterValue === NAV_LANG.dropDownList.paths
                            ? LANG.heroTitleAllPaths
                            : LANG.heroTitleAllCourses
                    }
                    btnText=""
                    btnUrl="#"
                    isHome={false}
                    placeHolder="/images/hugo-searching.png"
                />
                <div className="container ct-container text-center">
                    {rootFilter === null ? (
                        <>
                            {(filterValue === NAV_LANG.dropDownList.all ||
                                filterValue === NAV_LANG.dropDownList.paths) && (
                                <div className="ct-learning-path" id="ct-path-area">
                                    <h1 className="ct-card-title">{LAND_LANG.learningPaths}</h1>
                                    <div className="row ct-row">
                                        {searchPathList.map((path) => {
                                            return <PathTile card={path} isCourse={false} />;
                                        })}
                                    </div>
                                    {searchPathList.length < 1 && (
                                        <div className="ct-empty">
                                            <h1>No results found</h1>
                                        </div>
                                    )}
                                    <a
                                        onClick={() => {
                                            changeHeaderFilterType(NAV_LANG.dropDownList.paths);
                                            replace({
                                                pathname: PATH.search,
                                                state: { rootFilter: "paths" },
                                            });
                                        }}
                                        className="ct-btn-blue ct-btn-view-all"
                                    >
                                        <h3>View All Learning Paths</h3>
                                    </a>
                                </div>
                            )}
                            {(filterValue === NAV_LANG.dropDownList.all ||
                                filterValue === NAV_LANG.dropDownList.courses) && (
                                <div className="ct-courses" id="ct-course-area">
                                    <h1 className="ct-card-title">{LAND_LANG.courses}</h1>

                                    <div className="row ct-row">
                                        {searchCourseList.map((course) => {
                                            return <CourseTile card={course} />;
                                        })}
                                    </div>
                                    {searchCourseList.length < 1 && (
                                        <div className="ct-empty">
                                            <h1>No results found</h1>
                                        </div>
                                    )}
                                    <a
                                        onClick={() => {
                                            changeHeaderFilterType(NAV_LANG.dropDownList.courses);
                                            replace({
                                                pathname: PATH.search,
                                                state: { rootFilter: "courses" },
                                            });
                                        }}
                                        className="ct-btn-blue ct-btn-view-all"
                                    >
                                        <h3>View All Courses</h3>
                                    </a>
                                </div>
                            )}
                        </>
                    ) : rootFilter === "paths" ? (
                        <PathLayout />
                    ) : (
                        <CourseLayout />
                    )}
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        learningPaths: state.rootReducer.learningPaths,
        courses: state.rootReducer.courses,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addHomeData: (param) => {
            dispatch({ type: reducerTypes.HANDLE_MISSING_DATA, homeData: param });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
