import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

function ScrollToTop() {
    const { location } = useHistory();

    useEffect(() => {
        if (location.hash !== "") {
            try {
                document.getElementById(location.hash.substr(1)).scrollIntoView();
            } catch (error) {}
        } else {
            window.scrollTo(0, 0);
        }
    }, [location.pathname]);

    return null;
}

export default ScrollToTop;
