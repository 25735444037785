import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import Counter from "./Counter";
import useWindowScrollPosition from "@rehooks/window-scroll-position";
import { LANG } from "../lang/LandingPage";
import { LANG as NAV_LANG } from "../lang/NavigationBar";
import "react-dropdown/style.css";
import LoadingIndicator from "./LoadingIndicator";
import CourseLayout from "./CourseLayout";
import PathLayout from "./PathLayout";
import PlaceHolder from "./PlaceHolder";
import { PATH, MAX_LIMIT } from "../constant";

function LandingPage({ dataCollection, changeHeaderFilterType }) {
    const [hero, setHero] = useState(dataCollection.homePageData.hero);
    const [statistics, setStatistics] = useState(dataCollection.homePageData.statistics);
    const [counterEnable, setCounterEnable] = useState(false);
    const [count, setCount] = useState(0);
    const { location, replace } = useHistory();

    let position = useWindowScrollPosition();

    useEffect(() => {
        !counterEnable && position.y > LANG.scrollY && setCounterEnable(position.y > LANG.scrollY);
    }, [position]);

    useEffect(() => {
        if (dataCollection.homePageData.hero.title === "") {
            setCount((prevProp) => prevProp + 1);
        } else {
            setCount(0);
        }
        setHero(dataCollection.homePageData.hero);
        setStatistics(dataCollection.homePageData.statistics);
    }, [dataCollection]);

    useEffect(() => {
        if (location.hash) window.location.href = location.hash;
    }, []);

    return (
        <div className="ct-landing-page">
            <LoadingIndicator loaderCount={count} />
            <div className="container-fluid ct-container-fluid">
                <PlaceHolder
                    title={hero.title}
                    btnText={hero.button.text}
                    btnUrl={hero.button.url}
                    isHome={true}
                    placeHolder="/images/hugo-uploading.png"
                />
                {/*<div className='container ct-container'>
					 <div className='ct-placeholder-content'>
						<div className='ct-title-content'>
							<div className='ct-title'>
								<h1>{hero.title}</h1>
							</div>
							<a href={hero.button.url} className='ct-btn-blue ct-btn-placeholder'>
								<h3>{hero.button.text}</h3>
							</a>
						</div>
						<div className='ct-image-content'></div>
					</div> 
				</div>*/}
                <div className="ct-counter-container">
                    <h1 className="ct-counter-title">{statistics.title}</h1>
                    <div className="ct-counter-content">
                        {statistics.data.map((statistic) => {
                            return (
                                <Counter
                                    key={statistic.label}
                                    statistic={statistic}
                                    isScroll={counterEnable}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="container ct-container">
                    <PathLayout maxCardNum={MAX_LIMIT} />
                    <div className="ct-page-align">
                        <a
                            onClick={() => {
                                replace({
                                    pathname: PATH.search,
                                    state: { rootFilter: "paths" },
                                });
                                changeHeaderFilterType(NAV_LANG.dropDownList.paths);
                            }}
                            className="ct-btn-blue ct-btn-view-all"
                        >
                            <h3>View All Paths</h3>
                        </a>
                    </div>
                    <CourseLayout maxCardNum={MAX_LIMIT} />
                    <div className="ct-page-align">
                        <a
                            onClick={() => {
                                changeHeaderFilterType(NAV_LANG.dropDownList.courses);
                                replace({
                                    pathname: PATH.search,
                                    state: { rootFilter: "courses" },
                                });
                            }}
                            className="ct-btn-blue ct-btn-view-all ct-page-align"
                        >
                            <h3>View All Courses</h3>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        dataCollection: {
            homePageData: state.rootReducer.homePageData,
        },
    };
};
export default connect(mapStateToProps)(LandingPage);
