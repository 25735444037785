import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import YouTube from "react-youtube";
import LoadingIndicator from "./LoadingIndicator";
import LessonLayout from "./LessonLayout";
import { connect } from "react-redux";
import { reducerTypes } from "../reducerTypes";
import { LESSON } from "../lang/YouTubePlayer";
import { LANG } from "../lang/Card";
import { LANG as COMMON_LANG } from "../lang/Common";
import { URL, HTTP, PATH } from "../constant";
import http from "../http";
import { signInConfirmDialog, showDialog } from "./LoginRedirect";
import swal from "sweetalert";
import { ErrorHandler } from "./ErrorHandler";
import LessonList from "./placeholders/LessonList";

function PlayerLayout({
    isPath,
    courses,
    userData,
    isLogin,
    updateAsCompleted,
    updateAsStartToWatch,
    enrolledPath,
}) {
    const [count, setCount] = useState(0);
    const [lesson, setLesson] = useState({});
    const [course, setCourse] = useState({});
    const [lessonList, setLessonList] = useState([]);
    const [isCallBack, setCallBack] = useState(false);
    const [playEvent, setPlayEvent] = useState(null);
    const [isLessonChanged, setLessonChanged] = useState(false);
    const [isEnrolled, setEnrolled] = useState(false);
    let isUserActivated =
        userData.activated_on === null || userData.activated_on === undefined ? false : true;

    let { location, replace, push } = useHistory();

    useEffect(() => {
        if (isCallBack) {
            setCallBack(false);
            return;
        }
        if (courses.length < 1) {
            setCount(1);
        } else {
            let courseSlug = null;
            let lessonSlug = null;
            if (location.state !== undefined) {
                courseSlug = location.state.courseSlug
                    ? location.state.courseSlug
                    : location.pathname.split("/").pop();
                lessonSlug = location.state.lessonSlug;
            } else {
                courseSlug = location.pathname.split("/").pop();
            }
            let courseObj = courses.find((obj) => obj.slug === courseSlug);
            if (courseObj === undefined) {
                showDialog(
                    "Cannot find the course",
                    'We cannot find a course with given course ID "' + courseSlug + '"',
                );
            } else {
                setCourse(courseObj);
                //setEnrolled(courseObj.is_completed !== undefined);
                setLessonList(courseObj.lessons);
                if (lessonSlug === "_3") {
                    setLesson(courseObj.lessons[2]);
                } else {
                    setLesson(
                        lessonSlug !== null
                            ? courseObj.lessons.find((obj) => obj.slug === lessonSlug)
                            : courseObj.lessons[0],
                    );
                }
            }
            if (lessonSlug !== null) {
                setTimeout(() => {
                    let tempDoc = document.querySelector("#" + lessonSlug);
                    let tempParent = document.querySelector(".ct-play-lesson-content");
                    tempParent.scroll(0, tempDoc.offsetTop - tempParent.offsetTop);
                }, 1000);
            }

            setCount(0);
        }
    }, [courses]);

    useEffect(() => {
        return () => {
            document
                .getElementsByClassName("ct-header-container")[0]
                .classList.remove("ct-show-header");
            document
                .getElementsByClassName("ct-progress-player-container")[0]
                .classList.remove("ct-remove-margin");
        };
    }, []);

    const getEnrolled = (nextLesson, event = null) => {
        let url = URL.getEnrolledCourse.replace(new RegExp(LANG.COURSE_SLUG), course.slug);
        //setCount((prevCount) => prevCount + 1);
        const promise = http(HTTP.METHOD.POST, url);
        promise.then(
            (response) => {
                const obj = {
                    slug: course.slug,
                    completed_percentage: 0,
                    is_completed: false,
                    last_viewed_lesson_slug: lesson.slug,
                };
                setCallBack(true);
                enrolledPath(obj);
                //setCount((prevCount) => prevCount > 0 && prevCount - 1);
                setEnrolled(true);
                if (event !== null) {
                    try {
                        event.target.pauseVideo();
                        event.target.playVideo();
                    } catch (e) {}
                }
                setLesson(nextLesson);
                setCount(0);
            },
            (err) => {
                setCount(0);
                if (err.response.status === HTTP.RESPONSE.UNAUTHORIZED) {
                    ErrorHandler.showError(
                        COMMON_LANG.error.unauthorized.title,
                        COMMON_LANG.error.unauthorized.description,
                    );
                } else if (err.response.status === HTTP.RESPONSE.INTERNAL_SERVER_ERROR) {
                    ErrorHandler.showError(
                        COMMON_LANG.error.internalServerError.title,
                        COMMON_LANG.error.internalServerError.description,
                    );
                } else {
                    ErrorHandler.showError(
                        COMMON_LANG.error.general.title,
                        err.response.data.message,
                    );
                }
            },
        );
    };
    const playSelectedLesson = async (nextLesson) => {
        if (nextLesson.position > 0 && !isLogin) {
            let pathSlug = null;
            if (isPath) {
                pathSlug = location.pathname.split("/")[location.pathname.split("/").length - 2];
            }
            signInConfirmDialog(
                "Please Sign Up",
                "Only registered students are allowed to watch the complete course. Please create an account if you are not already registered.",
            ).then((swalStatus) => {
                if (swalStatus === "confirm") {
                    push({
                        pathname: PATH.createAccount,
                        state: {
                            from: "player",
                            url: `${PATH.watch}/${pathSlug === null ? "" : pathSlug + "/"}${
                                course.slug
                            }`,
                            lessonSlug: nextLesson.slug,
                        },
                    });
                }
            });
            return;
        }
        if (isLogin && !isUserActivated && nextLesson.position > 0) {
            if (playEvent !== null) {
                playEvent.target.stopVideo();
            }
            showDialog(
                "Please Verify Your Account",
                "Only verified students are allowed to watch this video. Please click on the link that has been sent to your email address to verify your account.",
            );
            return;
        }
        if (nextLesson.id === lesson.id) return;
        setLessonChanged(true);
        if (playEvent !== null) {
            playEvent.target.pauseVideo();
        }
        await markCompleted();
        setLesson(nextLesson);
    };
    const markCompleted = (currentEvent = playEvent) => {
        //if (!isEnrolled) return;
        if (userData.lessons === undefined) return;
        if (currentEvent === null || currentEvent === undefined) return;
        if (currentEvent.target.getDuration() < 1) return;
        let isCompleted = userData.lessons.completed.includes(lesson.id);
        let isPassed =
            currentEvent.target.getCurrentTime() >=
            currentEvent.target.getDuration() - LESSON.CompletedTimeInterval;
        if (!isCompleted && isPassed) {
            const url = URL.lessonCompletd.replace(new RegExp("{lesson-id}"), lesson.id);
            const promise = http(HTTP.METHOD.POST, url);
            promise
                .then((response) => {
                    setCallBack(true);
                    updateAsCompleted(lesson.id, response.data, course.slug);
                    let willMatchPath = [];
                    willMatchPath = response.data.learningPathProgress.filter(
                        (obj) => obj.completedPercentage === 100,
                    );
                    if (willMatchPath.length > 0) {
                        push({
                            pathname: PATH.learningPath + willMatchPath[0].slug,
                            state: { isAnimate: true, slug: willMatchPath[0].slug },
                        });
                    }
                    if (
                        willMatchPath.length === 0 &&
                        response.data.courseCompletedPercentage === 100
                    ) {
                        swal({
                            title: `Congratulations`,
                            text: `You have completed the course '${course.title}' and we have now added this course in your completed list`,
                            icon: "success",
                            buttons: {
                                confirm: {
                                    text: "OK",
                                    value: "confirm",
                                },
                            },
                            dangerMode: true,
                        }).then((swalStatus) => {
                            if (swalStatus === "confirm") {
                                if (isPath) {
                                    let routeSlug = location.pathname.split("/")[2];
                                    push({
                                        pathname: PATH.learningPath + routeSlug,
                                        hash: "#ct-course-included",
                                    });
                                } else {
                                    push({
                                        pathname: PATH.home,
                                        hash: "#ct-course-main",
                                    });
                                }
                            }
                        });
                    }
                })
                .catch((err) => {
                    setLessonChanged(false);
                });
        }
    };

    const markView = (event) => {
        //if (!isEnrolled) return;
        if (userData.lessons === undefined) return;
        let isCompleted = userData.lessons.completed.includes(lesson.id);
        let isPassed =
            event.target.getCurrentTime() <
            event.target.getDuration() - LESSON.CompletedTimeInterval;
        if (!isCompleted && isPassed) {
            const url = URL.markLessonStartToView.replace(new RegExp("{lesson-id}"), lesson.id);
            const promise = http(HTTP.METHOD.POST, url);
            promise
                .then((response) => {
                    setCallBack(true);
                    let courseSlug = location.pathname.split("/").pop();
                    let pathSlug = null;
                    if (isPath) {
                        pathSlug = location.pathname.split("/")[
                            location.pathname.split("/").length - 2
                        ];
                    }
                    updateAsStartToWatch(
                        lesson.id,
                        lesson.slug,
                        courseSlug,
                        pathSlug,
                        response.data,
                    );
                    replace(location.pathname, {
                        ...location.state,
                        lessonSlug: lesson.slug,
                    });
                })
                .catch((err) => {
                    ErrorHandler.handle(err);
                });
        }
    };

    return (
        <div className="ct-progress-player-container">
            <LoadingIndicator loaderCount={count} />
            <div className="ct-player-container">
                {lesson.content !== undefined && (
                    <YouTube
                        videoId={lesson.content}
                        className="ct-player"
                        containerClassName="ct-player-margin"
                        opts={{
                            playerVars: {
                                autoplay: 1,
                                rel: 0,
                                showinfo: 0,
                            },
                        }}
                        onPlay={async (event) => {
                            if (isLogin && !isUserActivated && lesson.position > 0) {
                                event.target.stopVideo();
                                showDialog(
                                    "Please Verify Your Account",
                                    "Only verified students are allowed to watch this video. Please click on the link that has been sent to your email address to verify your account.",
                                );
                                return;
                            }

                            document
                                .getElementsByClassName("ct-header-container")[0]
                                .classList.add("ct-show-header");
                            document
                                .getElementsByClassName("ct-progress-player-container")[0]
                                .classList.add("ct-remove-margin");
                            let isPlayNext = true;
                            if (!isLogin) {
                                if (lesson.position > 0) {
                                    isPlayNext = false;
                                    event.target.stopVideo();
                                    let pathSlug = null;
                                    if (isPath) {
                                        pathSlug = location.pathname.split("/")[
                                            location.pathname.split("/").length - 2
                                        ];
                                    }
                                    signInConfirmDialog(
                                        "Please Sign Up",
                                        "Only registered students are allowed to watch the complete course. Please create an account if you are not already registered.",
                                    ).then((swalStatus) => {
                                        if (swalStatus === "confirm") {
                                            push({
                                                pathname: PATH.createAccount,
                                                state: {
                                                    from: "player",
                                                    url: `${PATH.watch}/${
                                                        pathSlug === null ? "" : pathSlug + "/"
                                                    }${course.slug}`,
                                                    lessonSlug: lesson.slug,
                                                },
                                            });
                                        }
                                    });

                                    return;
                                }
                            }
                            if (isLogin && !isEnrolled) {
                                //event.target.stopVideo();
                                // await getEnrolled(lesson, event);
                            }
                            if (isPlayNext) {
                            } else {
                                event.target.stopVideo();
                                return;
                            }
                            setPlayEvent(event);
                            setLessonChanged(false);
                            await markView(event);
                            markCompleted(event);
                        }}
                        onPause={(event) => {
                            document
                                .getElementsByClassName("ct-header-container")[0]
                                .classList.remove("ct-show-header");
                            document
                                .getElementsByClassName("ct-progress-player-container")[0]
                                .classList.remove("ct-remove-margin");
                            if (isLessonChanged) return;
                            markCompleted();
                        }}
                        onEnd={async (event) => {
                            document
                                .getElementsByClassName("ct-header-container")[0]
                                .classList.remove("ct-show-header");
                            document
                                .getElementsByClassName("ct-progress-player-container")[0]
                                .classList.remove("ct-remove-margin");
                            let isPlayNext = true;
                            if (!isLogin) {
                                if (lesson.position === 0) {
                                    let pathSlug = null;
                                    isPlayNext = false;
                                    event.target.stopVideo();
                                    if (isPath) {
                                        pathSlug = location.pathname.split("/")[
                                            location.pathname.split("/").length - 2
                                        ];
                                    }
                                    signInConfirmDialog(
                                        "Please Sign Up",
                                        "Only registered students are allowed to watch the complete course. Please create an account if you are not already registered.",
                                    ).then((swalStatus) => {
                                        if (swalStatus === "confirm") {
                                            push({
                                                pathname: PATH.createAccount,
                                                state: {
                                                    from: "player",
                                                    url: `${PATH.watch}/${
                                                        pathSlug === null ? "" : pathSlug + "/"
                                                    }${course.slug}`,
                                                    lessonSlug: lesson.slug,
                                                },
                                            });
                                        }
                                    });

                                    return;
                                }
                            }
                            await markCompleted();
                            if (lesson.position < lessonList.length - 1 && isPlayNext) {
                                setLesson(lessonList[lesson.position + 1]);
                            } else {
                                event.target.stopVideo();
                            }
                        }}
                        onError={(err) => {
                            setCount(0);
                        }}
                    />
                )}
            </div>
            <div className="ct-play-list">
                <h3 className="ct-lesson-title">{course.title}</h3>
                <div className="ct-play-lesson-content">
                    {courses.length > 0 ? (
                        lessonList.map((obj, index) => (
                            <LessonLayout
                                lesson={obj}
                                playSelectedLesson={playSelectedLesson}
                                lessons={userData.lessons}
                                key={obj.id}
                                playId={lesson.content}
                                index={index}
                                isLogin={isLogin}
                                isEnrolled={isEnrolled}
                                isUserActivated={isUserActivated}
                            />
                        ))
                    ) : (
                        <LessonList />
                    )}
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        courses: state.rootReducer.courses,
        userData: state.rootReducer.userData,
        isLogin: state.rootReducer.isLogin,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateAsCompleted: (id, obj, courseSlug) => {
            dispatch({ type: reducerTypes.MARK_AS_COMPLETED, id, detail: obj, courseSlug });
        },
        updateAsStartToWatch: (id, slug, courseSlug, pathSlug, data) => {
            dispatch({ type: reducerTypes.START_TO_WATCH, id, slug, courseSlug, pathSlug, data });
        },
        enrolledPath: (obj) => {
            dispatch({ type: reducerTypes.ENROLLED_COURSE, param: obj });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PlayerLayout);
