import React, { useState } from 'react';
import { PATH, HTTP, URL } from '../constant';
import http from '../http';
import setAuthorizationToken from '../setAuthorizationToken';
import { reducerTypes } from '../reducerTypes';
import { LANG as CommonLang } from '../lang/Common';
import { ErrorHandler } from './ErrorHandler';
import { connect } from 'react-redux';
import LoadingIndicator from './LoadingIndicator';
import { useHistory } from 'react-router-dom';

function SignIn({ handleLogIn }) {
	const [count, setCount] = useState(0);

	let { push, location, replace } = useHistory();

	let logIn = (e) => {
		e.preventDefault();
		let formData = new FormData(document.forms.loginForm);
		let data = {
			email: formData.get('email'),
			password: formData.get('password'),
		};

		setCount((prevCount) => prevCount + 1);

		http(HTTP.METHOD.POST, URL.auth.logIn, data).then(
			(response) => {
				try {
					const token = response.data.access_token;
					localStorage.setItem('jwtToken', token);
					setAuthorizationToken(token);

					http(HTTP.METHOD.GET, URL.auth.me).then(
						(responseMe) => {
							try {
								handleLogIn(responseMe.data);
								localStorage.setItem(
									CommonLang.saveStorage.key,
									responseMe.data.id ? true : false,
								);
								setCount((prevCount) => prevCount > 0 && prevCount - 1);

								// Handle the redirect logic
								let url =
									location.state && location.state.url
										? location.state.url
										: PATH.profile;
								let lessonSlug =
									location.state && location.state.lessonSlug
										? location.state.lessonSlug
										: null;

								replace(
									{
										pathname: url,
										state: {
											lessonSlug: lessonSlug,
											from: location.state && location.state.from
										},
									}
								);
							} catch (error) {
								setCount(0);
								ErrorHandler.showError(CommonLang.error.general.title, error);
							}
						},
						(err) => {
							setCount(0);
							ErrorHandler.handle(err);
						},
					);
				} catch (error) {
					setCount(0);
					ErrorHandler.showError(CommonLang.error.general.title, error);
				}
			},
			(err) => {
				setCount(0);
				//default Unauthorized response code title isn't much meaningful in this situation
				//so we are using a custom handler for that

				let customHandlers = {
					[HTTP.RESPONSE.UNAUTHORIZED.CODE]: (error) => {
						ErrorHandler.showError(
							CommonLang.logInFailed,
							CommonLang.credentialsMismatched,
						).then((value) => {
							if (value == true) {
								let emailField = document.getElementsByName('email')[0];
								emailField.focus();
							}
						});
					},
				};
				ErrorHandler.handle(err, customHandlers);
			},
		);
	};

	const handleLinkClick = (e) => {
		e.preventDefault();
		let url = e.target.getAttribute('href');

		push({
			pathname: url,
		});
	};

	return (
		<>
			<LoadingIndicator loaderCount={count} />
			<div className='container-fluid ct-sign-in'>
				<div className='ct-sign-in-container'>
					<div className='container'>
						<div
							id='login-row'
							className='row justify-content-center align-items-center'>
							<div className='col-12 col-md-5'>
								<form
									onSubmit={(e) => logIn(e)}
									id='loginForm'
									className='form'
									action=''
									method='post'>
									<h3 className='text-center'>Sign In</h3>
									<div className='form-group'>
										<input
											autoFocus={true}
											type='text'
											name='email'
											id='email'
											placeholder='Email Address'
											className='form-control'
										/>
									</div>
									<div className='form-group'>
										<input
											type='password'
											name='password'
											id='password'
											placeholder='Password'
											className='form-control'
										/>
									</div>
									<div className='form-group text-center'>
										<button type='submit' className='ct-btn-blue'>
											Sign In
										</button>
										<div className='profile-links'>
											<a
												className='special-action-link'
												onClick={(e) => handleLinkClick(e)}
												href={PATH.createAccount}>
												Sign Up
											</a>
											<a
												className='special-action-link'
												onClick={(e) => handleLinkClick(e)}
												href={PATH.forgotPassword}>
												Forgot Password?
											</a>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleLogIn: (data) => {
			dispatch({
				type: reducerTypes.USER_LOGIN,
				param: { data },
			});
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
