import React from 'react';
import { useHistory } from 'react-router-dom';
import LandingPage from './placeholders/LandingPage';

function PlaceHolder({ title, btnText, btnUrl, isHome, placeHolder }) {
	const { push } = useHistory();
	return (
		<div
			className='ct-placeholder-component'
			style={{ backgroundImage: `url(${placeHolder})` }}>
			<div className='container ct-container ct-holder-content'>
				<div className='ct-holder-detail-container'>
					{title ? (
						<>
							<h1
								className='ct-hero-title'
								dangerouslySetInnerHTML={{ __html: title }}></h1>
							{isHome && (
								<button
									className='ct-btn-blue ct-hero-btn'
									onClick={() => push({ pathname: btnUrl })}>
									{btnText}
								</button>
							)}
						</>
					) : (
						<LandingPage />
					)}
				</div>
			</div>
		</div>
	);
}

export default PlaceHolder;
