import React, { useEffect, useState } from 'react';
import AvatarSelector, { getImage } from './blocks/AvatarSelector';
import { connect } from 'react-redux';
import { ErrorHandler } from './ErrorHandler';
import { URL, HTTP, PATH } from '../constant';
import LoadingIndicator from './LoadingIndicator';
import http from '../http';
import swal from 'sweetalert';
import { reducerTypes } from '../reducerTypes';
import { useHistory } from 'react-router-dom';
import Activity from './Activity';
import CountryFlag from './blocks/CountryFlag';
import InlineLoader from './blocks/InlineLoader';
import Select from 'react-select';

function Profile({ isLogin, userData, updateUser, countryInfo, match, changeHeaderFilterType }) {
	const { replace, location, push } = useHistory();

	let [count, setCount] = useState(0);
	let [activatingStatus, setActivatingStatus] = useState(0);

	let [showSelector, setShowSelector] = useState(false);
	const defaultAvatarPath = '/images/add-user.png';
	let [avatarPath, setAvatarPath] = useState(defaultAvatarPath);

	let [firstName, setFirstName] = useState('');
	let [lastName, setLastName] = useState('');
	let [country, setCountry] = useState({});
	let [designation, setDesignation] = useState('');

	useEffect(() => {
		if (userData.activated_on === null && count == 0) {
			const params = new URLSearchParams(
				window.location.search
					? window.location.search
					: location.state && location.state.from
					? location.state.from.search
					: '',
			);

			if (params.has('activation-key')) {
				let token = params.get('activation-key');

				setCount((prevCount) => prevCount + 1);
				http(HTTP.METHOD.POST, URL.auth.activate, { token })
					.then((response) => {
						setCount((prevCount) => prevCount - 1);

						swal('Verified', 'Your account was verified successfully. ', 'success');

						userData.activated_on = true;

						updateUser(userData);
					})
					.catch((err) => {
						setCount((prevCount) => prevCount - 1);
						let customHandlers = {
							[HTTP.RESPONSE.UNPROCESSABLE_ENTITY.CODE]: (error) => {
								ErrorHandler.showError(
									'Verification Failed',
									'We failed to verify your account. Please click on the “Resend Email” button below to generate a new verification email.',
								);
							},
						};
						ErrorHandler.handle(err, customHandlers);
					});
			}
		}

		setFirstName(userData.first_name ? userData.first_name : '');
		setLastName(userData.last_name ? userData.last_name : '');
		let countryObj = countryInfo.find(
			(country) => country['alpha-3'] === userData.country_code,
		);
		setCountry(
			userData.country_code ? { label: countryObj.name, value: userData.country_code } : '',
		);
		setDesignation(userData.job_title ? userData.job_title : '');

		let selectedImage = getImage(userData.profile_image_url);
		imageSelected(selectedImage);
	}, [userData]);

	const options = countryInfo.map((e) => {
		return {
			label: e.name,
			value: e['alpha-3'],
		};
	});
	const colourStyles = {
		control: (styles, state) => ({
			...styles,
			border: '1px solid #ededed',
			cursor: 'text',
			backgroundColor: '#ededed',
			'&:hover': {
				backgroundColor: 'white',
				border: '1px solid #a2a6a6',
			},
			fontWeight: '500',
		}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				fontWeight: '400',
				color: '#313B3D',
				backgroundColor: isSelected ? '#ededed' : isFocused ? '#ededed' : 'white',
				boxShadow: '0 1 2 rgba(0,0,0,0.16)',
				transition: 'all 0.15s ease',
				'&:hover': {
					backgroundColor: '#ededed',
				},
			};
		},
		input: (styles, { data, isDisabled, isFocused, isSelected }) => ({
			...styles,
			height: '37px',
			alignSelf: 'center',
			cursor: 'text',
			width: '100% !important',
			fontWeight: '500',
		}),
		placeholder: (styles) => ({ ...styles, color: '#a2a6a6' }),
	};
	const toggleAvatarSelector = () => {
		setShowSelector(!showSelector);
	};

	const imageSelected = (img) => {
		if (img) {
			setAvatar(img);
			setAvatarPath(img.src);
			setShowSelector(false);
		}
	};

	let [avatar, setAvatar] = useState({
		src: null,
		id: 0,
	});

	let focusElement = (id) => {
		let element = document.getElementById(id);

		element.focus({ preventScroll: true });
		element.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
			inline: 'nearest',
		});
	};

	const getTextFromDiv = (id) => {
		let element = document.getElementById(id);

		return element ? element.innerText : null;
	};

	let submitForm = (e) => {
		e.preventDefault();

		let tmpFirstName = getTextFromDiv('input-first-name');
		setFirstName(tmpFirstName);

		let tmpLastName = getTextFromDiv('input-last-name');
		setLastName(tmpLastName);

		let tmpDesignation = getTextFromDiv('input-designation');
		setDesignation(tmpDesignation);

		//validation
		if (avatar && avatar.id > 0 && avatar.id < 17) {
		} else {
			ErrorHandler.showError(
				'Please select an avatar first',
				'You need to select an avatar for your profile',
			);
			setShowSelector(true);
			return;
		}

		//first name
		if (tmpFirstName.length == 0) {
			ErrorHandler.showError('First name is required', '').then(() => {
				focusElement('first_name');
			});
			return;
		}

		//last name
		if (tmpLastName.length == 0) {
			ErrorHandler.showError('Last name is required', '').then(() => {
				focusElement('last_name');
			});
			return;
		}

		//Country
		if (country.value.length !== 3) {
			ErrorHandler.showError('Please Select your country', '').then(() => {
				focusElement('countrySelect');
			});
			return;
		}

		let formData = {
			profile_image_url: avatar.id,
			first_name: tmpFirstName,
			last_name: tmpLastName,
			country_code: country.value,
			job_title: tmpDesignation,
		};

		setCount((prevCount) => prevCount + 1);
		http(HTTP.METHOD.PUT, URL.auth.update, formData)
			.then((response) => {
				setCount((prevCount) => prevCount - 1);
				swal('Updated', 'Your profile was updated successfully.', 'success');

				userData.profile_image_url = avatar.id;
				userData.first_name = tmpFirstName;
				userData.last_name = tmpLastName;
				userData.country_code = country.value;
				userData.job_title = tmpDesignation;

				updateUser(userData);
			})
			.catch((err) => {
				setCount((prevCount) => prevCount - 1);
				ErrorHandler.handle(err);
			});
	};

	let resendActivationNotification = () => {
		if (activatingStatus != 0) {
			return;
		}

		setActivatingStatus(1);
		http(HTTP.METHOD.POST, URL.auth.reSendEmailVerification, [])
			.then((response) => {
				setActivatingStatus(2);
				setTimeout(() => {
					setActivatingStatus(0);
				}, 3000);
			})
			.catch((err) => {
				setActivatingStatus(0);
				ErrorHandler.handle(err);
			});
	};

	return (
		<>
			<LoadingIndicator loaderCount={count} />
			<div className='container-fluid light-grayish-blue-bg'>
				<div className='container'>
					<div className='row ct-profile-editor'>
						<div className='col-12 col-md-6'>
							<div className='profile-image-container'>
								<img
									onClick={toggleAvatarSelector}
									className={
										'profile-image ' +
										(showSelector ? 'dialog-open' : 'dialog-closed')
									}
									src={avatarPath}
									alt='User profile'></img>
								<AvatarSelector
									CurrentPath={avatarPath}
									ShowSelector={showSelector}
									SelectedEventHandler={imageSelected}
									VisibilityController={setShowSelector}
								/>
							</div>
						</div>
						<div className='col-12 col-md-6'>
							<form
								onSubmit={(e) => submitForm(e)}
								id='profileData'
								className='profile-form'>
								<div className='form-group'>
									<label htmlFor='first_name'>I’m</label>
									<div id='name-container'>
										<div
											id='input-first-name'
											dangerouslySetInnerHTML={{ __html: firstName }}
											contentEditable={true}></div>
										<div
											id='input-last-name'
											dangerouslySetInnerHTML={{ __html: lastName }}
											contentEditable={true}></div>
									</div>
								</div>
								<div className='form-group'>
									<label htmlFor='countrySelect'>I hail from</label>
									<CountryFlag alpa3={country.value} />
									<Select
										id='countrySelect'
										value={country}
										options={options}
										isSearchable={true}
										placeholder='Country'
										onChange={(obj) => {
											setCountry(obj);
										}}
										styles={colourStyles}
									/>
									{/* <SelectSearch
										inputId='countrySelect'
										search={true}
										value={country}
										onChange={(val) => {
											setCountry(val);
										}}
										options={options}
										placeholder='Country'
										autoComplete='on'
										name='language'
									/> */}
								</div>
								<div className='form-group'>
									<label htmlFor='designation'>I’m a</label>
									<div id='designation-container' className='d-flex'>
										<div
											id='input-designation'
											dangerouslySetInnerHTML={{ __html: designation }}
											contentEditable={true}></div>
										<label
											className='profession_label d-none d-sm-block'
											htmlFor='designation'>
											by day
										</label>
									</div>
									<label htmlFor='designation'>
										<span className='d-sm-none'>by day </span>and an Automation
										Ninja by night!
									</label>
								</div>
								<div className='form-group'>
									<button className='ct-submit-button ct-btn-blue'>
										Update Profile
									</button>
									<a
										className='special-action-link'
										onClick={(e) => {
											e.preventDefault();
											let url = e.target.getAttribute('href');

											push({
												pathname: url,
											});
										}}
										href={PATH.changePassword}>
										Change Password
									</a>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			{userData.activated_on == null && (
				<div className='user-activation-message'>
					<p className='message'>
						Please verify your account within 7 days using the link that has been sent
						to your email address.
						<p>Check your junk/spam folder if you do not see this email in the box.</p>
					</p>
					<br />
					<button
						onClick={resendActivationNotification}
						className='ct-btn ct-btn-resend-verification-mail'
						type='button'>
						{activatingStatus == 0 ? (
							'Resend Email'
						) : activatingStatus == 1 ? (
							<InlineLoader />
						) : (
							'✔ Sent'
						)}
					</button>
				</div>
			)}
			<Activity changeHeaderFilterType={changeHeaderFilterType} />
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		isLogin: state.rootReducer.isLogin,
		userData: state.rootReducer.userData,
		countryInfo: state.rootReducer.countryInfo,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		updateUser: (userData) => {
			dispatch({ type: reducerTypes.USER_UPDATED, userData });
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
