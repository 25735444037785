export const BASE_URL = 'https://api.executeautomation.com/api/';
//export const BASE_URL = 'https://uat-api.executeautomation.com/api/';
//export const BASE_URL = 'http://dev-ea-api.creativetribe.co.nz/api/';
//export const BASE_URL = 'http://executeautomation.test/api/';

export const URL = {
	auth: {
		forgotPassword: BASE_URL + 'auth/forgot-password',
		logIn: BASE_URL + 'auth/login',
		logout: BASE_URL + 'auth/logout',
		me: BASE_URL + 'auth/me',
		getRegistered: BASE_URL + 'users/get-registered',
		update: BASE_URL + 'auth/update',
		resetPassword: BASE_URL + 'auth/reset-password',
		changePassword: BASE_URL + 'auth/change-password',
		reSendEmailVerification: BASE_URL + 'users/re-send-email-verification',
		activate: BASE_URL + 'users/verify',
	},
	home: BASE_URL + 'data-bundle/home-page',
	search: BASE_URL + 'data-bundle/search?query=',
	getMultipleCourses: BASE_URL + 'courses/get-multiple/',
	getMultiplePaths: BASE_URL + 'learning-paths/get-multiple/',
	getEnrolled: BASE_URL + 'learning-paths/{learning_path}/get-enrolled',
	getEnrolledCourse: BASE_URL + 'courses/{course}/get-enrolled',
	lessonCompletd: BASE_URL + 'lessons/{lesson-id}/completed',
	getCompletedLessons: BASE_URL + 'courses/{course-slug}/lessons',
	markLessonStartToView: BASE_URL + 'lessons/{lesson-id}/started-watching',
	contactUs: BASE_URL + 'contact-us',
};
export const PATH = {
	newPassword: '/new-password',
	resetPassword: '/reset-password',
	resetPasswordEmailCheck: '/reset-password-email-check',
	pageNotFound: '/page-not-found',
	home: '/',
	search: '/search',
	login: '/sign-in',
	activity: '/activity',
	watch: '/watch',
	createAccount: '/sign-up',
	profile: '/profile',
	learningPath: '/learning-path/',
	course: '/course/',
	forgotPassword: '/forgot-my-password',
	changePassword: '/change-my-password',
};
export const HTTP = {
	METHOD: {
		POST: 'post',
		GET: 'get',
		UPDATE: 'update',
		DELETE: 'delete',
		PUT: 'put',
	},
	RESPONSE: {
		UNAUTHORIZED: {
			CODE: 401,
		},
		INTERNAL_SERVER_ERROR: {
			CODE: 500,
		},
		UNPROCESSABLE_ENTITY: {
			CODE: 422,
		},
	},
};

export const SWAL_TYPE = {
	error: 'error',
	success: 'success',
};
export const YOUTUBE_API_KEY = 'AIzaSyA2Z4pG6o_pSEBn6ndn_jsosONV9BzDRb0';
export const storage = { STORAGE_TOKEN_KEY: 'jwtToken' };
export const YOUTUBE_LESSON_THUMBNAIL =
	'https://img.youtube.com/vi/<insert-youtube-video-id-here>/sddefault.jpg';
export const TAG = { course: 'ct-course-main', path: 'ct-path-main' };
export const PATH_COMPLETE_TEXT = 'Yay! You earned a new qualification!';
export const MAX_LIMIT = 8;
