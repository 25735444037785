import React, { useState, useRef, useEffect } from "react";
import { LANG } from "../lang/Card";
import { PATH, HTTP, URL } from "../constant";
import { reducerTypes } from "../reducerTypes";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import http from "../http";
import LoadingIndicator from "./LoadingIndicator";
import { ErrorHandler } from "./ErrorHandler";
import swal from "sweetalert";
import { signInConfirmDialog } from "./LoginRedirect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function PathTile({ card, isLogin, enrolledPath, courses, userCourses }) {
    const [cardHeight, setCardHeight] = useState(0);
    const [countProgress, setCount] = useState(0);
    const cardWidthRef = useRef();
    let history = useHistory();

    const imagePath = card.path_image;
    const count = card.courses_count;
    let amount = count + LANG.COURSES;
    amount = count === 1 ? amount.substr(0, amount.length - 1) : amount;
    const progressStatus =
        card.completed_percentage !== undefined
            ? card.is_completed
                ? LANG.LABEL.COMPLETED
                : LANG.LABEL.IN_PROGRESS
            : "";
    /*  const progressStatus =
        card.completed_percentage !== undefined
            ? card.is_completed
                ? LANG.LABEL.COMPLETED
                : LANG.LABEL.IN_PROGRESS
            : ""; */
    const cardType =
        card.completed_percentage !== undefined
            ? card.is_completed
                ? LANG.STATUS.VIEW
                : LANG.STATUS.RESUME
            : LANG.STATUS.ENROL;
    card.formatDurationForCardLabel = (card) => {
        const totSeconds = card.duration_in_minutes * 60;
        const formatValue = new Date(totSeconds * 1000).toISOString();
        return totSeconds < 3600
            ? formatValue.substr(14, 2) + "m"
            : formatValue.substr(11, 2) + "h " + formatValue.substr(14, 2) + "m";
    };
    card.watchLesson = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (!isLogin) {
            signInConfirmDialog(
                "Please Sign Up",
                `Only registered students are allowed to enrol in a learning path. Please create an account if you are not already registered.`,
            ).then((swalStatus) => {
                if (swalStatus === "confirm") {
                    history.push({
                        pathname: PATH.createAccount,
                    });
                }
            });
        } else {
            switch (cardType) {
                case LANG.STATUS.RESUME:
                    let viewedCourse = userCourses.find(
                        (course) => course.slug === card.last_viewed_course_slug,
                    );
                    viewedCourse = viewedCourse || {};
                    if (card.last_viewed_course_slug === null || viewedCourse.is_completed) {
                        history.push({
                            pathname: PATH.learningPath + card.slug,
                            state: { isAnimate: false, slug: card.slug },
                            hash: "#ct-course-included",
                        });
                    } else {
                        let path = `${PATH.watch}/${card.slug}/${card.last_viewed_course_slug}`;
                        history.push({
                            pathname: path,
                            state: {
                                pathSlug: card.slug,
                                courseSlug: card.last_viewed_course_slug,
                                lessonSlug: card.last_viewed_lesson_slug,
                            },
                        });
                    }
                    break;
                case LANG.STATUS.ENROL:
                    let url = URL.getEnrolled.replace(new RegExp(LANG.PATH_SLUG), card.slug);
                    setCount((prevCount) => prevCount + 1);
                    const promise = http(HTTP.METHOD.POST, url);
                    promise.then(
                        (response) => {
                            const obj = {
                                slug: card.slug,
                                completed_percentage: response.data.completed_percentage,
                                is_completed: response.data.completed_percentage === 100,
                                last_viewed_lesson_slug: null,
                                last_viewed_course_slug: null,
                            };
                            let currentCourses = courses.filter((course) => {
                                let isMatch = false;
                                course.learning_paths.map((path) => {
                                    if (path.slug === card.slug) {
                                        isMatch = true;
                                    }
                                });
                                return isMatch;
                            });
                            enrolledPath(obj, currentCourses);
                            setCount((prevCount) => prevCount > 0 && prevCount - 1);
                            swal({
                                title: `Enrolled`,
                                text: `You have successfully enrolled in ${card.title}`,
                                icon: "success",
                                buttons: {
                                    confirm: {
                                        text: "OK",
                                        value: "confirm",
                                    },
                                },
                                dangerMode: true,
                            }).then((swalStatus) => {
                                if (swalStatus === "confirm") {
                                    setCount(0);
                                    history.push({
                                        pathname: PATH.learningPath + card.slug,
                                    });
                                }
                            });
                        },
                        (err) => {
                            setCount(0);
                            ErrorHandler.handle(err);
                        },
                    );
                    break;
                default:
                    history.push({
                        pathname: PATH.learningPath + card.slug,
                        state: { isAnimate: false, slug: card.slug },
                    });
            }
        }
    };
    useEffect(() => {
        function handleResize() {
            if (!cardWidthRef.current) return;
            let width = cardWidthRef.current.getBoundingClientRect().width;
            let widthToHeightRatio = 462 / 354;
            setCardHeight(window.innerWidth < 768 ? 82 : width * widthToHeightRatio);
        }
        handleResize();
        window.addEventListener("resize", handleResize);
    });

    return (
        <div className="col-xl-4 col-md-6 col-12 ct-column" key={card.slug}>
            <LoadingIndicator loaderCount={countProgress} />
            <a href={PATH.learningPath + card.slug} onClick={(e) => e.preventDefault()}>
                <div
                    className="ct-path-tile"
                    ref={cardWidthRef}
                    onClick={() => {
                        let splitUrl = history.location.pathname.split("/");
                        let urlType = splitUrl[1];
                        history.push({
                            pathname: PATH.learningPath + card.slug,
                            state: {
                                isAnimate: false,
                                slug: card.slug,
                                pathSlug:
                                    "/" + urlType + "/" === PATH.learningPath ? splitUrl[2] : null,
                            },
                        });
                    }}
                    style={{ height: cardHeight }}
                >
                    <div className="ct-img" style={{ backgroundImage: `url(${imagePath})` }}>
                        <div
                            className={`${
                                progressStatus === LANG.LABEL.IN_PROGRESS
                                    ? "ct-label-blue"
                                    : progressStatus === LANG.LABEL.COMPLETED
                                    ? "ct-label-green"
                                    : ""
                            }`}
                        >
                            <h3>
                                {progressStatus === LANG.LABEL.IN_PROGRESS ? (
                                    ""
                                ) : progressStatus === LANG.LABEL.COMPLETED ? (
                                    <FontAwesomeIcon icon={faCheck} />
                                ) : (
                                    ""
                                )}
                            </h3>
                        </div>
                    </div>
                    <div className="ct-body">
                        <div className="ct-text-content">
                            <h2>{card.title}</h2>
                            <h4>{card.short_description}</h4>
                        </div>

                        <div className="ct-info-group">
                            <div className="ct-info">
                                <h3>{amount}</h3>
                            </div>
                            <div className="ct-info">
                                <h3>{card.formatDurationForCardLabel(card)}</h3>
                            </div>
                            <div
                                className="ct-info ct-btn-path-action"
                                onClick={(event) => {
                                    card.watchLesson(event);
                                }}
                            >
                                <h3>{cardType}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="ct-mobile-desc">
                        <h3>{card.title}</h3>
                        <div>
                            <i className="fa fa-clock-o clock-icon"></i>
                            <h4>{card.formatDurationForCardLabel(card)}</h4>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        isLogin: state.rootReducer.isLogin,
        courses: state.rootReducer.courses,
        userCourses: state.rootReducer.userData.courses,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        enrolledPath: (obj, includeCourses) => {
            dispatch({ type: reducerTypes.ENROLLED_PATH, param: obj, includeCourses });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PathTile);
