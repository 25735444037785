import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import VideoList from "./VideoList";
import { useHistory } from "react-router-dom";
import LoadingIndicator from "./LoadingIndicator";
import { ErrorHandler } from "./ErrorHandler";
import { PATH } from "../constant";
import { reducerTypes } from "../reducerTypes";
import PathPage from "./placeholders/PathPage";
import { List } from "react-content-loader";

function Courses({ courses, userInfo, isLogin }) {
  const { location, push } = useHistory();
  const [course, setCourse] = useState({});
  const [user, setUser] = useState({});
  const [count, setCount] = useState(0);

  useEffect(() => {
    const courseSlug = location.pathname.split("/").pop();
    setCount(1);
    if (courses.length > 0) {
      const slugList = courses.filter((course) => course.slug === courseSlug);

      setCourse(slugList[0]);
      setCount(0);
      setUser(userInfo);
    }
  }, [courses, location.pathname]);

  const courseTimeConversion = () => {
    const timeSeconds = course.duration_in_seconds;
    if (timeSeconds !== undefined && timeSeconds !== "") {
      let durationHours = Math.floor(timeSeconds / 3600);
      durationHours = durationHours === 0 ? "" : durationHours + "h ";
      let durationMinutes = Math.floor((timeSeconds % 3600) / 60);
      durationMinutes = durationMinutes > 9 ? durationMinutes : "0" + durationMinutes;
      return durationHours + durationMinutes + "m";
    }
    return "00h 00m";
  };

  const pushDataToPlayer = (lesson) => {
    let videoSlug = lesson ? lesson.slug : course.last_viewed_lesson_slug;
    const tempPathSlug = location.state ? location.state.pathSlug : null;
    push({
      pathname: tempPathSlug === null ? PATH.watch + "/" + course.slug : PATH.watch + "/" + tempPathSlug + "/" + course.slug,
      state: {
        pathSlug: tempPathSlug,
        courseSlug: course.slug,
        lessonSlug: videoSlug !== undefined ? videoSlug : null,
      },
    });
  };
  const studentCount = () => {
    const students = course.number_of_students;
    if (students !== undefined && studentCount !== "") {
      const studentCount = students.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return studentCount;
    }
    return "0";
  };
  return (
    <>
      <LoadingIndicator loaderCount={count} />
      {course.slug ? (
        <div className='container-fluid ct-course'>
          <div className='ct-head-content-bg '>
            <div className='container ct-courses-main-container ct-container'>
              <div className='row ct-course-row'>
                <div className='ct-courses-main-text-content col-md-6'>
                  <div className='ct-courses-main-texts'>
                    <div className='ct-courses-progress-inline'>
                      <h1 className='title'>
                        {course.title}
                        {course.is_completed !== undefined ? (
                          course.last_viewed_lesson_slug === null ? null: course.completed_percentage ===100? (
                            <span className='progress-green'>Completed</span>
                          ) : (
                            <span className='progress-blue'>In Progress</span>
                          )
                        ) : null}
                      </h1>
                    </div>
                    <h4>{course.short_description}</h4>
                  </div>
                  <div className='ct-courses-sub-texts'>
                    <div className='ct-courses-main-image-mobile'>
                      <img src={course.course_image_path} alt='course-image'></img>
                    </div>
                    <p className='ct-courses-watch-info'>
                      <i className='fa fa-clock-o clock-icon'></i>
                      <span>{courseTimeConversion()}</span>
                      <i className='fa fa-user user-icon'></i>
                      <span>{studentCount()}</span>
                    </p>
                    <button className='ct-btn-blue ct-courses-main-button' onClick={() => pushDataToPlayer()}>
                      {course.is_completed !== undefined ? (
                        course.is_completed || (!course.is_completed && course.last_viewed_lesson_slug === null) ? (
                          <span> Watch </span>
                        ) : (
                          <span> Resume </span>
                        )
                      ) : (
                        <span> Watch </span>
                      )}
                    </button>
                  </div>
                </div>
                <div className='ct-courses-main-image-content col-md-6'>
                  <div className='ct-courses-main-image'>
                    <img src={course.course_image_path} alt='course-image'></img>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='ct-course-description-container'>
            <div
              className='container ct-container ct-course-description'
              dangerouslySetInnerHTML={{ __html: course.full_description }}
            ></div>
          </div>
          <div className='ct-video-list-bg'>
            <div className='container ct-container ct-video-list-container'>
              <div className='row ct-playlist-row'>
                <h4> Lessons </h4>
                {course.lessons !== undefined
                  ? course.lessons.map((lesson) => (
                      <VideoList
                        key={lesson.id}
                        lesson={lesson}
                        userInfo={user}
                        pushDataToPlayer={pushDataToPlayer}
                        isLogin={isLogin}
                        course={course}
                      />
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='container-fluid ct-container-fluid'>
          <div className='ct-head-content-bg' style={{ backgroundColor: "#fff", marginTop: 10 }}>
            <div className='container ct-container '>
              <PathPage />
            </div>
            <div className='container ct-container '>
              <List style={{ marginBottom: 120 }} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    courses: state.rootReducer.courses,
    userInfo: state.rootReducer.userData,
    isLogin: state.rootReducer.isLogin,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    enrolledPath: (obj) => {
      dispatch({ type: reducerTypes.ENROLLED_COURSE, param: obj });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
