export const LANG = {
	pathFilters: [{ title: 'All Paths', slug: '' }],
	courseFilters: [{ title: 'All Levels', slug: '' }],
	learningPaths: 'Learning Paths',
	courses: 'Courses',
	placeholder: { dropdown: 'All Technologies' },
	searchTitle: 'search',
	searchPlaceholder: 'Search languages and technologies',
	scrollY: 450,
	activityEmptyMessage:
		'You do not have any activity at the moment. Start following a learning path or a course today.',
};
