import React from 'react';
import ContentLoader from 'react-content-loader';

function PathPage() {
	const width = window.innerWidth;
	return width < 768 ? (
		<ContentLoader
			speed={2}
			backgroundColor={'#f6f6f8'}
			foregroundColor={'#ededed'}
			viewBox={`0 -120 800 850`}>
			<rect x='42%' y='0' rx='50%' ry='50%' width='100' height='100' />
			<rect x='0' y='17' rx='4' ry='4' width='40%' height='40' />
			<rect x='0' y='67' rx='4' ry='4' width='30%' height='40' />

			<rect x='0' y='167' rx='3' ry='3' width='90%' height='25' />
			<rect x='0' y='202' rx='3' ry='3' width='90%' height='25' />
			<rect x='0' y='237' rx='3' ry='3' width='60%' height='25' />

			<rect x='0' y='317' rx='50%' ry='50%' width='20' height='20' />
			<rect x='25' y='319' rx='3' ry='3' width='30' height='15' />
			<rect x='0' y='354' rx='5' ry='5' width='100%' height='335' />
			<rect x='0' y='710' rx='5' ry='5' width='100%' height='85' />
		</ContentLoader>
	) : (
		<ContentLoader
			speed={2}
			backgroundColor={'#f6f6f8'}
			foregroundColor={'#ededed'}
			viewBox={`0 -120 1200 800`}>
			<rect x='42%' y='0' rx='50%' ry='50%' width='100' height='100' />
			<rect x='0' y='17' rx='4' ry='4' width='40%' height='40' />
			<rect x='0' y='67' rx='4' ry='4' width='30%' height='40' />

			<rect x='0' y='167' rx='3' ry='3' width='50%' height='25' />
			<rect x='0' y='202' rx='3' ry='3' width='50%' height='25' />
			<rect x='0' y='237' rx='3' ry='3' width='50%' height='25' />

			<rect x='0' y='317' rx='50%' ry='50%' width='20' height='20' />
			<rect x='25' y='319' rx='3' ry='3' width='30' height='15' />
			<rect x='0' y='407' rx='3' ry='3' width='150' height='65' />

			<rect x='55%' y='0' rx='5' ry='5' width='600' height='335' />
		</ContentLoader>
	);
}
{
	/* <rect x='0' y='0' rx='10%' ry='5%' width='40%' height='70' />
    <rect x='0' y='80' rx='5' ry='5' width='40%' height='70' />
    <rect x='45%' y='30' rx='50%' ry='50%' width='100' height='100' />
    <rect x='0' y='160' rx='5' ry='5' width='30%' height='70' /> */
}

export default PathPage;
