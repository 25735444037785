import React, { useState, useEffect } from 'react';
import { PATH, HTTP, URL } from '../constant';
import http from '../http';
import setAuthorizationToken from '../setAuthorizationToken';
import { reducerTypes } from '../reducerTypes';
import { ErrorHandler } from './ErrorHandler';
import { connect } from 'react-redux';
import LoadingIndicator from './LoadingIndicator';
import { useHistory } from 'react-router-dom';
import { LANG as COMMON_LANG } from '../lang/Common';
import swal from 'sweetalert';

function SignIn({ handleLogIn, handleLogOut, refreshUserData }) {
	const [count, setCount] = useState(0);

	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');

	let history = useHistory();

	const handleLinkClick = (e) => {
		e.preventDefault();
		let url = e.target.getAttribute('href');

		history.push({
			pathname: url,
		});
	};

	const handleFormSubmit = (e, d) => {
		e.preventDefault();

		if (newPassword != newPasswordConfirmation) {
			ErrorHandler.showError(
				'Passwords Do Not Match',
				'The new password and the confirmation password do not match.',
			);
			document.getElementById('newPassword').focus();
			return;
		}

		let data = {
			current_password: currentPassword,
			password: newPassword,
			password_confirmation: newPasswordConfirmation,
		};

		setCount((prevCount) => prevCount + 1);

		http(HTTP.METHOD.POST, URL.auth.changePassword, data)
			.then((response) => {
				setCount((prevCount) => prevCount - 1);

				localStorage.clear();
				setAuthorizationToken(null);

				handleLogOut();

				const promise = http(HTTP.METHOD.GET, URL.home);
				promise.then(
					(response) => {
						try {
							refreshUserData(response.data);
						} catch (error) {
							ErrorHandler.showError(
								COMMON_LANG.error.general.title,
								error.toString(),
							);
						}
					},
					(err) => {
						ErrorHandler.handle(err);
					},
				);
				swal({
					title: `Updated`,
					text: `Your password was updated successfully. Please sign in using your new password.`,
					icon: 'success',
					buttons: {
						confirm: {
							text: 'Sign In',
							value: 'confirm',
						},
					},
					dangerMode: true,
				}).then((swalStatus) => {
					if (swalStatus === 'confirm') {
						setCount(0);
						history.push({
							pathname: PATH.login,
						});
					}
				});
			})
			.catch((err) => {
				setCount((prevCount) => prevCount - 1);
				ErrorHandler.handle(err);
			});
	};

	return (
		<>
			<LoadingIndicator loaderCount={count} />
			<div className='container-fluid ct-sign-in ct-change-password'>
				<div className='ct-change-password-container'>
					<div className='container'>
						<div
							id='login-row'
							className='row justify-content-center align-items-center'>
							<div className='col-12 col-md-5'>
								<form
									onSubmit={(e, d) => handleFormSubmit(e, d)}
									id='loginForm'
									className='form'
									action=''
									method='post'>
									<h3 className='text-center'>Change Password</h3>
									<div className='form-group'>
										<input
											autoFocus={true}
											type='password'
											name='password'
											id='password'
											placeholder='Current Password'
											className='form-control'
											value={currentPassword}
											onChange={(e) => {
												setCurrentPassword(e.target.value);
											}}
										/>
									</div>
									<div className='form-group'>
										<input
											type='password'
											name='newPassword'
											id='newPassword'
											placeholder='New Password'
											className='form-control'
											value={newPassword}
											onChange={(e) => {
												setNewPassword(e.target.value);
											}}
										/>
									</div>
									<div className='form-group'>
										<input
											type='password'
											name='passwordConfirmation'
											id='passwordConfirmation'
											placeholder='Re-enter New Password'
											className='form-control'
											value={newPasswordConfirmation}
											onChange={(e) => {
												setNewPasswordConfirmation(e.target.value);
											}}
										/>
									</div>
									<div className='form-group text-center'>
										<button type='submit' className='ct-btn-blue'>
											Change Password
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleLogIn: (data) => {
			dispatch({
				type: reducerTypes.USER_LOGIN,
				param: { data },
			});
		},
		handleLogOut: () => {
			dispatch({
				type: reducerTypes.SESSION_HANDLE,
				param: false,
			});
		},

		refreshUserData: (data) => {
			dispatch({
				type: reducerTypes.HOME_DATA,
				homeData: data,
			});
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
