import React, { useEffect, useState } from 'react';
import { YOUTUBE_LESSON_THUMBNAIL } from '../constant';
import { faHourglassHalf, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function LessonLayout({
	index,
	isLogin,
	lesson,
	lessons,
	playId,
	playSelectedLesson,
	isEnrolled,
	isUserActivated,
}) {
	const [state, setstate] = useState(lessons);
	let enableClick = (isLogin && isUserActivated) || index === 0;
	const thumbnail = YOUTUBE_LESSON_THUMBNAIL.replace(
		new RegExp('<insert-youtube-video-id-here>'),
		lesson.content,
	);
	useEffect(() => {
		setstate(lessons);
	}, [lessons]);
	return (
		<div
			className={`ct-lesson-row ${playId === lesson.content && 'ct-play-current'}`}
			onClick={() => playSelectedLesson(lesson)}
			id={lesson.slug}>
			<div
				className='ct-lesson-thumb'
				style={{
					backgroundImage: `url(${thumbnail})`,
				}}>
				{state !== undefined
					? state.completed.includes(lesson.id) && (
							<div className='ct-lesson-completed'>
								<h3>
									<i className='fa fa-check-circle-o' aria-hidden='true'></i>
								</h3>
							</div>
					  )
					: null}
				{state !== undefined
					? state.in_progress.includes(lesson.id) && (
							<div className='ct-lesson-in-progress'>
								<h3>
									<i className='fa fa-spinner icon' aria-hidden='true'></i>
								</h3>
							</div>
					  )
					: null}
				{!enableClick && (
					<div className='ct-lesson-lock'>
						<h6>&#128274;</h6>
					</div>
				)}
			</div>
			<h4>{lesson.title}</h4>
		</div>
	);
}

export default LessonLayout;
