import React, { useState, useEffect } from 'react';

function RoundedButton({
	filter,
	setFilterValue,
	isCourse,
	selectedFilterPaths,
	selectedFilterCourse,
}) {
	const [isShown, setIsShown] = useState(false);
	const [courseFilter, setCourseFilter] = useState(false);
	const [pathFilter, setPathFilter] = useState(false);

	useEffect(() => {
		isCourse
			? setCourseFilter(selectedFilterCourse === filter.slug)
			: setPathFilter(selectedFilterPaths === filter.slug);
	}, [selectedFilterCourse, selectedFilterPaths]);

	return (
		<div className='ct-btn-container' key={filter.slug}>
			<button
				type='button'
				className={`ct-btn-default ct-btn-round ${
					courseFilter || pathFilter ? 'ct-rnd-btn-active ' : ''
				}${isShown ? 'ct-rnd-btn-hover' : ''}`}
				onMouseEnter={() => setIsShown(true)}
				onMouseLeave={() => setIsShown(false)}
				onClick={() => setFilterValue(filter.slug, isCourse)}>
				<h3>{filter.title}</h3>
			</button>
		</div>
	);
}

export default RoundedButton;
