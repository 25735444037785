import React from 'react';
import { motion } from 'framer-motion';
function SVGAnimation({ isAnim }) {
	const thropyCenter = document.innerWidth;

	return isAnim ? (
		<motion.div
			className='ct-anim-thropy'
			initial={{ x: '-30vw', y: 150, scale: 1.25, position: 'absolute' }}
			animate={{
				x: 0,
				y: 0,
				scale: 1,
				zIndex: 'unset',
				position: 'relative',
			}}
			transition={{ ease: 'linear', duration: 1, delay: 2 }}>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='331.74'
				height='353.68'
				viewBox='0 0 331.74 353.68'>
				<g transform='translate(-11.548)'>
					<ellipse
						cx='202.449'
						cy='206.097'
						rx='202.449'
						ry='206.097'
						transform='translate(11.548 19.483)'
						fill='#1a2b63'
					/>
					<path
						d='M267.861,353.155V308.179a22.681,22.681,0,0,1-5.4.653H243.9a22.681,22.681,0,0,1-5.4-.653v44.976Z'
						transform='translate(-38.648 -49.657)'
						fill='#ffaf10'
					/>
					<path
						d='M251.5,353.155V308.832h-7.6a22.681,22.681,0,0,1-5.4-.653v44.976Z'
						transform='translate(-38.648 -49.657)'
						fill='#ef9614'
					/>
					<path
						d='M249.863,283.881a89.912,89.912,0,0,1-31.949-5.839v4.136a22.673,22.673,0,0,0,22.674,22.673h18.551a22.673,22.673,0,0,0,22.674-22.673v-4.136A89.915,89.915,0,0,1,249.863,283.881Z'
						transform='translate(-35.331 -44.801)'
						fill='#ffd00d'
					/>
					<path
						d='M243.9,283.68a89.736,89.736,0,0,1-25.987-5.638v4.136a22.673,22.673,0,0,0,22.674,22.673H259.14a22.765,22.765,0,0,0,3.691-.3A22.676,22.676,0,0,1,243.9,283.68Z'
						transform='translate(-35.331 -44.801)'
						fill='#ffaf10'
					/>
					<path
						d='M282.432,378.455V364.194A4.207,4.207,0,0,0,278.238,360H221.194A4.207,4.207,0,0,0,217,364.194v14.261Z'
						transform='translate(-35.184 -58.007)'
						fill='#ef8318'
					/>
					<path
						d='M242.166,378.455V364.194A4.207,4.207,0,0,1,246.36,360H221.194A4.207,4.207,0,0,0,217,364.194v14.261Z'
						transform='translate(-35.184 -58.007)'
						fill='#db722c'
					/>
					<path
						d='M320.047,452.982V384.194A4.207,4.207,0,0,0,315.852,380H165.694a4.207,4.207,0,0,0-4.194,4.194v68.787Z'
						transform='translate(-26.241 -61.229)'
						fill='#a05f33'
					/>
					<path
						d='M174.5,452.982V384.194A4.207,4.207,0,0,1,178.7,380h-13a4.207,4.207,0,0,0-4.194,4.194v68.787Z'
						transform='translate(-26.241 -61.229)'
						fill='#87502a'
					/>
					<path
						d='M301.424,437.427a4.207,4.207,0,0,1-4.194,4.194H193.172a4.207,4.207,0,0,1-4.194-4.194V402.194A4.206,4.206,0,0,1,193.172,398H297.229a4.206,4.206,0,0,1,4.194,4.194Z'
						transform='translate(-30.669 -64.129)'
						fill='#293d7c'
					/>
					<path
						d='M205.736,437.427V402.194A4.207,4.207,0,0,1,209.93,398H193.172a4.206,4.206,0,0,0-4.194,4.194v35.233a4.207,4.207,0,0,0,4.194,4.194H209.93A4.207,4.207,0,0,1,205.736,437.427Z'
						transform='translate(-30.669 -64.129)'
						fill='#1a2b63'
					/>
					<path
						d='M333.474,466H139.694a4.207,4.207,0,0,0-4.194,4.195v7.845a205.71,205.71,0,0,0,202.168,0v-7.844A4.207,4.207,0,0,0,333.474,466Z'
						transform='translate(-22.052 -75.086)'
						fill='#bf7e52'
					/>
					<path
						d='M161.086,470.194A4.207,4.207,0,0,1,165.28,466H139.694a4.207,4.207,0,0,0-4.194,4.195v7.845a204.813,204.813,0,0,0,25.586,12.223Z'
						transform='translate(-22.052 -75.086)'
						fill='#aa6b3f'
					/>
					<path
						d='M414.282,25.591h-32.51v7.054A3.359,3.359,0,0,1,378.416,36H364.339a.839.839,0,0,0-.839.839v9.723h43.973a134.207,134.207,0,0,1-43.973,92.89v13.39a90.659,90.659,0,0,1-1.139,14.319A155.393,155.393,0,0,0,428.608,39.916,14.342,14.342,0,0,0,414.282,25.591Z'
						transform='translate(-58.606 -4.123)'
						fill='#ffaf10'
					/>
					<path
						d='M135.776,152.843v-13.39A134.208,134.208,0,0,1,91.8,46.563h43.974V36.839a.839.839,0,0,0-.839-.839H120.859a3.359,3.359,0,0,1-3.355-3.355V25.591H84.993A14.342,14.342,0,0,0,70.668,39.916a155.4,155.4,0,0,0,66.246,127.246A90.663,90.663,0,0,1,135.776,152.843Z'
						transform='translate(-11.606 -4.123)'
						fill='#ffd00d'
					/>
					<path
						d='M147.282,38V154.842a91.2,91.2,0,0,0,182.4,0V38Z'
						transform='translate(-23.95 -6.123)'
						fill='#ef8318'
					/>
					<g transform='translate(123.332 31.041)'>
						<path
							d='M165.5,154.682V37H147.282V154.682a91.2,91.2,0,0,0,91.2,91.2c3.054,0,6.068-.162,9.045-.457A91.212,91.212,0,0,1,165.5,154.682Z'
							transform='translate(-147.282 -37.003)'
							fill='#db722c'
						/>
						<circle
							cx='62.076'
							cy='62.076'
							r='62.076'
							transform='translate(29.124 37.327)'
							fill='#db722c'
						/>
					</g>
					<path
						d='M197.939,143.575a62.074,62.074,0,0,1,54.107-61.55,62.077,62.077,0,1,0,0,123.1A62.073,62.073,0,0,1,197.939,143.575Z'
						transform='translate(-29.544 -13.132)'
						fill='#c95e23'
					/>
					<path
						d='M269.9,186.943a6.673,6.673,0,0,1-3.118-.826l-16.9-8.885a4.476,4.476,0,0,0-3.175,0l-16.9,8.885a6.689,6.689,0,0,1-3.118.826,5.036,5.036,0,0,1-3.922-1.788,6.017,6.017,0,0,1-1.154-4.993l3.228-18.818a4.456,4.456,0,0,0-.981-3.02L210.187,145a5.411,5.411,0,0,1,3.131-9.634l18.893-2.745a4.457,4.457,0,0,0,2.569-1.866l8.449-17.121a5.411,5.411,0,0,1,10.129,0l8.45,17.121a4.452,4.452,0,0,0,2.568,1.866l18.894,2.745A5.411,5.411,0,0,1,286.4,145l-13.673,13.328a4.451,4.451,0,0,0-.982,3.02l3.227,18.818a6.019,6.019,0,0,1-1.153,4.993A5.038,5.038,0,0,1,269.9,186.943Z'
						transform='translate(-33.761 -17.744)'
						fill='#ffd00d'
					/>
					<path
						d='M235.458,183l3.5-20.39a4.828,4.828,0,0,0-1.063-3.272l-14.815-14.441a6.224,6.224,0,0,1-2.009-6.021L213.316,140a5.411,5.411,0,0,0-3.131,9.634l13.672,13.327a4.454,4.454,0,0,1,.981,3.02L221.61,184.8a6.022,6.022,0,0,0,1.154,4.993,5.037,5.037,0,0,0,3.922,1.788,6.686,6.686,0,0,0,3.118-.826l6.267-3.3A6.9,6.9,0,0,1,235.458,183Z'
						transform='translate(-33.76 -22.376)'
						fill='#ffaf10'
					/>
					<path
						d='M344.445,28.522a4.207,4.207,0,0,1-4.194,4.194H129.694a4.207,4.207,0,0,1-4.194-4.194V4.194A4.207,4.207,0,0,1,129.694,0H340.251a4.207,4.207,0,0,1,4.194,4.194Z'
						transform='translate(-20.441 0)'
						fill='#ffd00d'
					/>
					<path
						d='M151.086,28.522V4.194A4.207,4.207,0,0,1,155.28,0H129.694A4.207,4.207,0,0,0,125.5,4.194V28.522a4.207,4.207,0,0,0,4.194,4.194H155.28A4.207,4.207,0,0,1,151.086,28.522Z'
						transform='translate(-20.441 0)'
						fill='#ffaf10'
					/>
					<motion.path
						initial={{ y: -43.022, opacity: 1 }}
						animate={{ y: '-170%', opacity: 0 }}
						transition={{ loop: Infinity, ease: 'linear', duration: 4, delay: 2.8 }}
						d='M121.844,320.688a2.516,2.516,0,0,1-2.384-1.711c-4.829-14.306-8.442-17.92-22.748-22.748a2.517,2.517,0,0,1,0-4.769c14.305-4.828,17.919-8.442,22.748-22.748a2.516,2.516,0,0,1,4.768,0c4.829,14.305,8.442,17.92,22.748,22.748a2.517,2.517,0,0,1,0,4.769c-14.305,4.828-17.919,8.442-22.748,22.748A2.514,2.514,0,0,1,121.844,320.688Z'
						transform='translate(-15.526 -43.022)'
						fill='#ffd00d'></motion.path>
					<g transform='translate(143.228 26.844)'>
						<motion.path
							initial={{ x: -199.842, y: -66.643, opacity: 1 }}
							animate={{ y: '-170%', opacity: 0 }}
							transition={{ loop: Infinity, ease: 'linear', duration: 4, delay: 3.5 }}
							d='M371.391,289.782a2.007,2.007,0,0,1-1.9-1.363c-3.847-11.4-6.728-14.28-18.127-18.127a2.006,2.006,0,0,1,0-3.8c11.4-3.847,14.28-6.728,18.127-18.127a2.006,2.006,0,0,1,3.8,0c3.847,11.4,6.728,14.279,18.127,18.127a2.006,2.006,0,0,1,0,3.8c-11.4,3.848-14.28,6.727-18.127,18.127A2.009,2.009,0,0,1,371.391,289.782Z'
							transform='translate(-199.842 -66.643)'
							fill='#fff'></motion.path>
						<path
							d='M273.493,417.389h-45.3a4.194,4.194,0,0,1,0-8.389h45.3a4.194,4.194,0,1,1,0,8.389Z'
							transform='translate(-179.54 -92.746)'
							fill='#fff'
						/>
						<path
							d='M265.361,435.389H240.194a4.194,4.194,0,1,1,0-8.389h25.166a4.194,4.194,0,1,1,0,8.389Z'
							transform='translate(-181.473 -95.646)'
							fill='#fff'
						/>
						<motion.path
							initial={{ x: -171, y: -32, opacity: 1 }}
							animate={{ y: '-170%', opacity: 0 }}
							transition={{ loop: Infinity, ease: 'linear', duration: 4, delay: 3.8 }}
							d='M191.133,72.266a1.888,1.888,0,0,1-1.788-1.283c-3.621-10.729-6.332-13.44-17.061-17.061a1.888,1.888,0,0,1,0-3.577c10.729-3.621,13.44-6.332,17.061-17.061a1.888,1.888,0,0,1,3.577,0c3.621,10.729,6.332,13.44,17.061,17.061a1.888,1.888,0,0,1,0,3.577c-10.729,3.621-13.44,6.332-17.061,17.061A1.889,1.889,0,0,1,191.133,72.266Z'
							transform='translate(-171 -32)'
							fill='#fff'></motion.path>
					</g>
				</g>
			</svg>
		</motion.div>
	) : (
		<motion.div className='ct-anim-thropy' initial={{ x: 0, y: 0, scale: 1 }}>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='331.74'
				height='353.68'
				viewBox='0 0 331.74 353.68'>
				<g transform='translate(-11.548)'>
					<ellipse
						cx='202.449'
						cy='206.097'
						rx='202.449'
						ry='206.097'
						transform='translate(11.548 19.483)'
						fill='#1a2b63'
					/>
					<path
						d='M267.861,353.155V308.179a22.681,22.681,0,0,1-5.4.653H243.9a22.681,22.681,0,0,1-5.4-.653v44.976Z'
						transform='translate(-38.648 -49.657)'
						fill='#ffaf10'
					/>
					<path
						d='M251.5,353.155V308.832h-7.6a22.681,22.681,0,0,1-5.4-.653v44.976Z'
						transform='translate(-38.648 -49.657)'
						fill='#ef9614'
					/>
					<path
						d='M249.863,283.881a89.912,89.912,0,0,1-31.949-5.839v4.136a22.673,22.673,0,0,0,22.674,22.673h18.551a22.673,22.673,0,0,0,22.674-22.673v-4.136A89.915,89.915,0,0,1,249.863,283.881Z'
						transform='translate(-35.331 -44.801)'
						fill='#ffd00d'
					/>
					<path
						d='M243.9,283.68a89.736,89.736,0,0,1-25.987-5.638v4.136a22.673,22.673,0,0,0,22.674,22.673H259.14a22.765,22.765,0,0,0,3.691-.3A22.676,22.676,0,0,1,243.9,283.68Z'
						transform='translate(-35.331 -44.801)'
						fill='#ffaf10'
					/>
					<path
						d='M282.432,378.455V364.194A4.207,4.207,0,0,0,278.238,360H221.194A4.207,4.207,0,0,0,217,364.194v14.261Z'
						transform='translate(-35.184 -58.007)'
						fill='#ef8318'
					/>
					<path
						d='M242.166,378.455V364.194A4.207,4.207,0,0,1,246.36,360H221.194A4.207,4.207,0,0,0,217,364.194v14.261Z'
						transform='translate(-35.184 -58.007)'
						fill='#db722c'
					/>
					<path
						d='M320.047,452.982V384.194A4.207,4.207,0,0,0,315.852,380H165.694a4.207,4.207,0,0,0-4.194,4.194v68.787Z'
						transform='translate(-26.241 -61.229)'
						fill='#a05f33'
					/>
					<path
						d='M174.5,452.982V384.194A4.207,4.207,0,0,1,178.7,380h-13a4.207,4.207,0,0,0-4.194,4.194v68.787Z'
						transform='translate(-26.241 -61.229)'
						fill='#87502a'
					/>
					<path
						d='M301.424,437.427a4.207,4.207,0,0,1-4.194,4.194H193.172a4.207,4.207,0,0,1-4.194-4.194V402.194A4.206,4.206,0,0,1,193.172,398H297.229a4.206,4.206,0,0,1,4.194,4.194Z'
						transform='translate(-30.669 -64.129)'
						fill='#293d7c'
					/>
					<path
						d='M205.736,437.427V402.194A4.207,4.207,0,0,1,209.93,398H193.172a4.206,4.206,0,0,0-4.194,4.194v35.233a4.207,4.207,0,0,0,4.194,4.194H209.93A4.207,4.207,0,0,1,205.736,437.427Z'
						transform='translate(-30.669 -64.129)'
						fill='#1a2b63'
					/>
					<path
						d='M333.474,466H139.694a4.207,4.207,0,0,0-4.194,4.195v7.845a205.71,205.71,0,0,0,202.168,0v-7.844A4.207,4.207,0,0,0,333.474,466Z'
						transform='translate(-22.052 -75.086)'
						fill='#bf7e52'
					/>
					<path
						d='M161.086,470.194A4.207,4.207,0,0,1,165.28,466H139.694a4.207,4.207,0,0,0-4.194,4.195v7.845a204.813,204.813,0,0,0,25.586,12.223Z'
						transform='translate(-22.052 -75.086)'
						fill='#aa6b3f'
					/>
					<path
						d='M414.282,25.591h-32.51v7.054A3.359,3.359,0,0,1,378.416,36H364.339a.839.839,0,0,0-.839.839v9.723h43.973a134.207,134.207,0,0,1-43.973,92.89v13.39a90.659,90.659,0,0,1-1.139,14.319A155.393,155.393,0,0,0,428.608,39.916,14.342,14.342,0,0,0,414.282,25.591Z'
						transform='translate(-58.606 -4.123)'
						fill='#ffaf10'
					/>
					<path
						d='M135.776,152.843v-13.39A134.208,134.208,0,0,1,91.8,46.563h43.974V36.839a.839.839,0,0,0-.839-.839H120.859a3.359,3.359,0,0,1-3.355-3.355V25.591H84.993A14.342,14.342,0,0,0,70.668,39.916a155.4,155.4,0,0,0,66.246,127.246A90.663,90.663,0,0,1,135.776,152.843Z'
						transform='translate(-11.606 -4.123)'
						fill='#ffd00d'
					/>
					<path
						d='M147.282,38V154.842a91.2,91.2,0,0,0,182.4,0V38Z'
						transform='translate(-23.95 -6.123)'
						fill='#ef8318'
					/>
					<g transform='translate(123.332 31.041)'>
						<path
							d='M165.5,154.682V37H147.282V154.682a91.2,91.2,0,0,0,91.2,91.2c3.054,0,6.068-.162,9.045-.457A91.212,91.212,0,0,1,165.5,154.682Z'
							transform='translate(-147.282 -37.003)'
							fill='#db722c'
						/>
						<circle
							cx='62.076'
							cy='62.076'
							r='62.076'
							transform='translate(29.124 37.327)'
							fill='#db722c'
						/>
					</g>
					<path
						d='M197.939,143.575a62.074,62.074,0,0,1,54.107-61.55,62.077,62.077,0,1,0,0,123.1A62.073,62.073,0,0,1,197.939,143.575Z'
						transform='translate(-29.544 -13.132)'
						fill='#c95e23'
					/>
					<path
						d='M269.9,186.943a6.673,6.673,0,0,1-3.118-.826l-16.9-8.885a4.476,4.476,0,0,0-3.175,0l-16.9,8.885a6.689,6.689,0,0,1-3.118.826,5.036,5.036,0,0,1-3.922-1.788,6.017,6.017,0,0,1-1.154-4.993l3.228-18.818a4.456,4.456,0,0,0-.981-3.02L210.187,145a5.411,5.411,0,0,1,3.131-9.634l18.893-2.745a4.457,4.457,0,0,0,2.569-1.866l8.449-17.121a5.411,5.411,0,0,1,10.129,0l8.45,17.121a4.452,4.452,0,0,0,2.568,1.866l18.894,2.745A5.411,5.411,0,0,1,286.4,145l-13.673,13.328a4.451,4.451,0,0,0-.982,3.02l3.227,18.818a6.019,6.019,0,0,1-1.153,4.993A5.038,5.038,0,0,1,269.9,186.943Z'
						transform='translate(-33.761 -17.744)'
						fill='#ffd00d'
					/>
					<path
						d='M235.458,183l3.5-20.39a4.828,4.828,0,0,0-1.063-3.272l-14.815-14.441a6.224,6.224,0,0,1-2.009-6.021L213.316,140a5.411,5.411,0,0,0-3.131,9.634l13.672,13.327a4.454,4.454,0,0,1,.981,3.02L221.61,184.8a6.022,6.022,0,0,0,1.154,4.993,5.037,5.037,0,0,0,3.922,1.788,6.686,6.686,0,0,0,3.118-.826l6.267-3.3A6.9,6.9,0,0,1,235.458,183Z'
						transform='translate(-33.76 -22.376)'
						fill='#ffaf10'
					/>
					<path
						d='M344.445,28.522a4.207,4.207,0,0,1-4.194,4.194H129.694a4.207,4.207,0,0,1-4.194-4.194V4.194A4.207,4.207,0,0,1,129.694,0H340.251a4.207,4.207,0,0,1,4.194,4.194Z'
						transform='translate(-20.441 0)'
						fill='#ffd00d'
					/>
					<path
						d='M151.086,28.522V4.194A4.207,4.207,0,0,1,155.28,0H129.694A4.207,4.207,0,0,0,125.5,4.194V28.522a4.207,4.207,0,0,0,4.194,4.194H155.28A4.207,4.207,0,0,1,151.086,28.522Z'
						transform='translate(-20.441 0)'
						fill='#ffaf10'
					/>
					<motion.path
						initial={{ y: -43.022, opacity: 1 }}
						animate={{ y: '-170%', opacity: 0 }}
						transition={{ loop: Infinity, ease: 'linear', duration: 4, delay: 0.3 }}
						d='M121.844,320.688a2.516,2.516,0,0,1-2.384-1.711c-4.829-14.306-8.442-17.92-22.748-22.748a2.517,2.517,0,0,1,0-4.769c14.305-4.828,17.919-8.442,22.748-22.748a2.516,2.516,0,0,1,4.768,0c4.829,14.305,8.442,17.92,22.748,22.748a2.517,2.517,0,0,1,0,4.769c-14.305,4.828-17.919,8.442-22.748,22.748A2.514,2.514,0,0,1,121.844,320.688Z'
						transform='translate(-15.526 -43.022)'
						fill='#ffd00d'></motion.path>
					<g transform='translate(143.228 26.844)'>
						<motion.path
							initial={{ x: -199.842, y: -66.643, opacity: 1 }}
							animate={{ y: '-170%', opacity: 0 }}
							transition={{ loop: Infinity, ease: 'linear', duration: 4, delay: 1 }}
							d='M371.391,289.782a2.007,2.007,0,0,1-1.9-1.363c-3.847-11.4-6.728-14.28-18.127-18.127a2.006,2.006,0,0,1,0-3.8c11.4-3.847,14.28-6.728,18.127-18.127a2.006,2.006,0,0,1,3.8,0c3.847,11.4,6.728,14.279,18.127,18.127a2.006,2.006,0,0,1,0,3.8c-11.4,3.848-14.28,6.727-18.127,18.127A2.009,2.009,0,0,1,371.391,289.782Z'
							transform='translate(-199.842 -66.643)'
							fill='#fff'></motion.path>
						<path
							d='M273.493,417.389h-45.3a4.194,4.194,0,0,1,0-8.389h45.3a4.194,4.194,0,1,1,0,8.389Z'
							transform='translate(-179.54 -92.746)'
							fill='#fff'
						/>
						<path
							d='M265.361,435.389H240.194a4.194,4.194,0,1,1,0-8.389h25.166a4.194,4.194,0,1,1,0,8.389Z'
							transform='translate(-181.473 -95.646)'
							fill='#fff'
						/>
						<motion.path
							initial={{ x: -171, y: -32, opacity: 1 }}
							animate={{ y: '-170%', opacity: 0 }}
							transition={{ loop: Infinity, ease: 'linear', duration: 4, delay: 1.3 }}
							d='M191.133,72.266a1.888,1.888,0,0,1-1.788-1.283c-3.621-10.729-6.332-13.44-17.061-17.061a1.888,1.888,0,0,1,0-3.577c10.729-3.621,13.44-6.332,17.061-17.061a1.888,1.888,0,0,1,3.577,0c3.621,10.729,6.332,13.44,17.061,17.061a1.888,1.888,0,0,1,0,3.577c-10.729,3.621-13.44,6.332-17.061,17.061A1.889,1.889,0,0,1,191.133,72.266Z'
							transform='translate(-171 -32)'
							fill='#fff'></motion.path>
					</g>
				</g>
			</svg>
		</motion.div>
	);
}

export default SVGAnimation;
