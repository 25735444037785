import React, { useState, useEffect, useRef } from "react";
import RoundedButton from "./RoundedButton";
import CourseTile from "./CourseTile";
import { LANG } from "../lang/LandingPage";
import { connect } from "react-redux";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cards from "./placeholders/Cards";

function CourseLayout({ courses, courseLevels, filterCourses, maxCardNum = "UNLIMIT" }) {
    const [courseFilters, setCourseFilters] = useState([]);
    const [selectedFilterCourse, setSelectedFilterCourse] = useState("");
    const [coursesList, setCoursesList] = useState([]);
    const [coursesValidList, setValidCoursesList] = useState([]);
    const [searchBy, setSearchBy] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [minHeight, setMinHeight] = useState(0);

    const rowRef = useRef();

    useEffect(() => {
        if (maxCardNum !== "UNLIMIT") {
            let limitedCourses = [];
            if (filterCourses === undefined) {
                limitedCourses = courses.filter((path, index) => index < maxCardNum);
            } else {
                limitedCourses = filterCourses.filter((path, index) => index < maxCardNum);
            }
            setCoursesList(limitedCourses);
        } else {
            setCoursesList(filterCourses === undefined ? courses : filterCourses);
        }

        setValidCoursesList(filterCourses === undefined ? courses : filterCourses);
        setCourseFilters([...LANG.courseFilters, ...courseLevels]);
        setIsShow(false);
    }, [courses]);

    const setFilterValue = (value) => {
        let filterList = coursesValidList.filter((course) => {
            return value === "" ? course.level.slug.includes(value) : course.level.slug === value;
        });

        if (searchBy !== "") {
            let filterListLanguage = filterList.filter((course) => {
                return course.programming_languages.some((language) =>
                    language.slug.trim().toLowerCase().includes(searchBy.toLowerCase()),
                );
            });
            let filterListFramework = filterList.filter((course) => {
                return course.frameworks.some((framework) =>
                    framework.slug.trim().toLowerCase().includes(searchBy.toLowerCase()),
                );
            });
            filterList = [...filterListLanguage, ...filterListFramework];
        }

        setSelectedFilterCourse(value);
        setMinHeight(rowRef.current.getBoundingClientRect().height);
        setCoursesList([]);
        setTimeout(() => {
            if (maxCardNum !== "UNLIMIT") {
                let limitedCourses = filterList.filter((path, index) => index < maxCardNum);
                setCoursesList(limitedCourses);
            } else {
                setCoursesList(filterList);
            }

            setMinHeight(0);
            setIsShow(filterList.length < 1);
        }, 100);
    };
    const searchResult = (query) => {
        let filterListLanguage = coursesValidList.filter((course) => {
            return course.programming_languages.some((language) =>
                language.slug.trim().toLowerCase().includes(query.toLowerCase()),
            );
        });
        if (selectedFilterCourse !== "") {
            filterListLanguage = filterListLanguage.filter((course) => {
                return course.level.slug === selectedFilterCourse;
            });
        }
        let filterListFramework = coursesValidList.filter((course) => {
            return course.frameworks.some((framework) =>
                framework.slug.trim().toLowerCase().includes(query.toLowerCase()),
            );
        });
        if (selectedFilterCourse !== "") {
            filterListFramework = filterListFramework.filter((course) => {
                return course.level.slug === selectedFilterCourse;
            });
        }
        setMinHeight(rowRef.current.getBoundingClientRect().height);
        setCoursesList([]);
        setTimeout(() => {
            if (maxCardNum !== "UNLIMIT") {
                let limitedCourses = [...filterListLanguage, ...filterListFramework].filter(
                    (path, index) => index < maxCardNum,
                );
                setCoursesList(limitedCourses);
            } else {
                setCoursesList([...filterListLanguage, ...filterListFramework]);
            }

            setMinHeight(0);
            setIsShow(filterListLanguage.length < 1 && filterListFramework.length < 1);
        }, 100);
        setSearchBy(query);
    };
    return (
        <div className="ct-courses" id="ct-course-main">
            <h1 className="ct-card-title">{LANG.courses}</h1>
            <div className="ct-filter-container">
                {courseFilters.length > 1
                    ? courseFilters.map((filter) => {
                          return (
                              <RoundedButton
                                  key={filter.slug}
                                  filter={filter}
                                  setFilterValue={setFilterValue}
                                  isCourse={true}
                                  selectedFilterCourse={selectedFilterCourse}
                              />
                          );
                      })
                    : [1, 2, 3, 4].map((filter, index) => {
                          return (
                              <RoundedButton
                                  key={index}
                                  filter={filter}
                                  setFilterValue={setFilterValue}
                                  isCourse={true}
                                  selectedFilterCourse={selectedFilterCourse}
                              />
                          );
                      })}

                <div className="ct-search-container">
                    <FontAwesomeIcon icon={faSearch} className="icon" />
                    <input
                        type="text"
                        name={LANG.searchTitle}
                        placeholder={LANG.searchPlaceholder}
                        value={searchBy}
                        className="ct-input"
                        onChange={(e) => searchResult(e.target.value)}
                    />
                    {searchBy !== "" && (
                        <div
                            className="ct-close"
                            onClick={() => {
                                searchResult("");
                            }}
                        >
                            x
                        </div>
                    )}
                </div>
            </div>
            <div className="row ct-row" ref={rowRef} style={{ minHeight: minHeight }}>
                {courseLevels.length === 0 ? (
                    <Cards />
                ) : (
                    <>
                        {coursesList.map((course) => {
                            return <CourseTile key={course.slug} card={course} />;
                        })}
                        <div className={isShow ? "ct-empty" : ""}>
                            {isShow && "No results found"}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        courses: state.rootReducer.courses,
        courseLevels: state.rootReducer.courseLevels,
    };
};
export default connect(mapStateToProps)(CourseLayout);
