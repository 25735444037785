import React, { useState, useEffect } from 'react';
import { PATH, HTTP, URL } from '../constant';
import http from '../http';
import setAuthorizationToken from '../setAuthorizationToken';
import { reducerTypes } from '../reducerTypes';
import { LANG as CommonLang } from '../lang/Common';
import { ErrorHandler } from './ErrorHandler';
import { connect } from 'react-redux';
import LoadingIndicator from './LoadingIndicator';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function SignIn({ handleLogIn }) {

    const [count, setCount] = useState(0);
    const [formIsValid, setFormIsValid] = useState(false);

    let history = useHistory();
    const location = useLocation();


    const handleLinkClick = (e) => {
        e.preventDefault();
        let url = e.target.getAttribute("href");

        history.push({
            pathname: url
        });
    }

    const validateForm = () => {
        let formData = new FormData(document.forms.forgotForm);
        let password = formData.get('password');
        let passwordConfirmation = formData.get('passwordConfirmation');

        let formIsValid = password.length > 0 && password == passwordConfirmation;

        setFormIsValid(formIsValid);
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        if (formIsValid == false) return;

        let formData = new FormData(document.forms.forgotForm);

        const query = new URLSearchParams(location.search);

        const data = {
            email: query.get('email'),
            token: query.get('token'),
            password: formData.get('password'),
            password_confirmation: formData.get('passwordConfirmation'),
        };

        setCount((prevCount) => prevCount + 1);
        http(HTTP.METHOD.POST, URL.auth.resetPassword, data).then(
            (response) => {
                try {
                    setCount((prevCount) => prevCount > 0 && prevCount - 1);

                    ErrorHandler.showSuccess(CommonLang.passwordReset, response.data.message).then(
                        () => {
                            history.push({
                                pathname: PATH.login
                            });
                        });

                } catch (error) {
                    setCount(0);
                    ErrorHandler.showError(CommonLang.error.general.title, error);
                }
            },
            (err) => {
                setCount(0);
                let customHandlers = {
                    [HTTP.RESPONSE.UNPROCESSABLE_ENTITY.CODE]: (error) => {

                        let errorList = Object.values(error.response.data.errors);

                        if (errorList.length == 1) {
                            ErrorHandler.showError(errorList[0][0], '');
                            return;
                        }

                        let msg = '';
                        errorList.forEach((errorItem) => {
                            msg += errorItem.join('\n') + '\n';
                        });

                        msg = msg.trim();

                        ErrorHandler.showError(CommonLang.failedToChange, msg);
                    },
                };
                ErrorHandler.handle(err, customHandlers);
            },
        );

    }

    return (
        <>
            <LoadingIndicator loaderCount={count} />
            <div className="container-fluid ct-sign-in">
                <div className="ct-sign-in-container">
                    <div className="container">
                        <div id="login-row" className="row justify-content-center align-items-center">
                            <div className="col-12 col-md-5">
                                <form onSubmit={(e, d) => handleFormSubmit(e)} id="forgotForm" className="form" action="" method="post">
                                    <h3 className="text-center">Reset Password</h3>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            placeholder="New Password"
                                            className="form-control"
                                            autoFocus={true}
                                            onChange={validateForm}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            name="passwordConfirmation"
                                            id="passwordConfirmation"
                                            placeholder="Re-enter New Password"
                                            className="form-control"
                                            onChange={validateForm}
                                        />
                                    </div>
                                    <div className="form-group text-center">
                                        <button
                                            disabled={!formIsValid}
                                            type="submit"
                                            className="ct-btn-blue"
                                        >Reset Password</button>
                                        <div className="profile-links">
                                            <a className="special-action-link" onClick={(e) => handleLinkClick(e)} href={PATH.login} >Sign In</a>
                                            <a className="special-action-link" onClick={(e) => handleLinkClick(e)} href={PATH.createAccount} >Sign Up</a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {};
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleLogIn: (data) => {
            dispatch({
                type: reducerTypes.USER_LOGIN,
                param: { data }
            });
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);