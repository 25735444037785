import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import { reducer as formReducer } from 'redux-form';
import setAutherizationToken from './setAuthorizationToken';
import { reducerTypes } from './reducerTypes';
import http from './http';
import { HTTP, URL, storage } from './constant';
import { ErrorHandler } from './components/ErrorHandler';
import { LANG as COMMON_LANG } from './lang/Common';

function saveToLocalStorage(state) {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem('state', serializedState);
	} catch (e) {
		console.log(e);
	}
}

function loadFromLocalStorage() {
	try {
		const serializedState = localStorage.getItem('state');
		if (serializedState === null) return undefined;
		return JSON.parse(serializedState);
	} catch (e) {
		console.log(e);
		return undefined;
	}
}
const getDefaultData = () => {
	const promise = http(HTTP.METHOD.GET, URL.home);
	promise.then(
		(response) => {
			try {
				store.dispatch({ type: reducerTypes.HOME_DATA, homeData: response.data });
			} catch (error) {
				ErrorHandler.showError(COMMON_LANG.error.general.title, error.toString());
			}
		},
		(err) => {
			ErrorHandler.handle(err);
			/* if (err.response == undefined) {
				ErrorHandler.showError(
					'Network Error',
					'We cannot connect to the server. There might be an issue with your internet connection or ours. Please try again few minutes later',
				);
				return;
			}

			if (err.response.status === HTTP.RESPONSE.UNAUTHORIZED.CODE) {
				ErrorHandler.showError(
					COMMON_LANG.error.unauthorized.title,
					COMMON_LANG.error.unauthorized.description,
				);
			} else if (err.response.status === HTTP.RESPONSE.INTERNAL_SERVER_ERROR.CODE) {
				ErrorHandler.showError(
					COMMON_LANG.error.internalServerError.title,
					COMMON_LANG.error.internalServerError.description,
				);
			} else {
				ErrorHandler.showError(COMMON_LANG.error.general.title, err.response.data.message);
			} */
		},
	);
};
const authUser = () => {
	if (localStorage.getItem(storage.STORAGE_TOKEN_KEY)) {
		setAutherizationToken(localStorage.getItem(storage.STORAGE_TOKEN_KEY));
		const promiseMe = http(HTTP.METHOD.GET, URL.auth.me);
		promiseMe.then(
			(responseMe) => {
				store.dispatch({
					type: reducerTypes.PAGE_REFRESH_HANDLE,
					param: { data: responseMe.data },
				});
			},
			(err) => {
				localStorage.clear();
			},
		);
	}
	getDefaultData();
};
const persistedState = loadFromLocalStorage();
//persistedState ?? authUser();
authUser();
const store = createStore(
	combineReducers({ rootReducer, form: formReducer }),
	//persistedState,
	compose(applyMiddleware(thunk)),
);

store.subscribe(() => saveToLocalStorage(store.getState()));
ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root'),
);

serviceWorker.unregister();
