import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { LANG } from '../lang/Common';
import { PATH } from '../constant';

function ProtectedRoute({ component: Component, ...rest }) {
	const isUserLog = localStorage.getItem(LANG.saveStorage.key);

	const isbool = isUserLog === null ? rest.isLogin : isUserLog;

	return (
		<Route
			{...rest}
			render={(props) => {
				if (isbool) {
					return <Component {...props} />;
				} else {
					return (
						<Redirect to={{ pathname: PATH.login, state: { from: props.location } }} />
					);
				}
			}}
		/>
	);
}

export default ProtectedRoute;
