import React, { useEffect, useState } from 'react';

function Input(params) {
    const Type = params.Type ? params.Type : 'text';
    const ID = params.ID ? params.ID : null;
    const Class = params.Class ? params.Class : 'form-control';
    const AriaDescribedBy = params.AriaDescribedBy ? params.AriaDescribedBy : null;
    const Placeholder = params.Placeholder ? params.Placeholder : null;

    return (
            <input
                type={Type}
                className={Class}
                id={ID}
                aria-describedby={AriaDescribedBy}
                placeholder={Placeholder}
                value={params.Value}
                onChange={(e) => {
                    params.Setter(e.target.value);
                }}
            />
    )
}

export default Input;